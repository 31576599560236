import DecryptDataComponent from "../components/AES/DecryptData";
var config;
if(localStorage.getItem("Token")===null){
  config=null;
}
else{
  config = {
    headers:{
      "Authorization": `Bearer ${DecryptDataComponent(localStorage.getItem("Token"))}`,
    }
  }
}
const API_EndPoint={
    //DEV API 
      // URL: 'https://PostExpressMyanmarapi.nksoftwarehouse.com/api/',
      //  URL:'https://localhost:5001/api/',
      URL:'https://deliveryapi.postexpressmyanmar.com/api/',
      //URL:'https://nksoftwarehouse-001-site2.gtempurl.com/api/',
      //URL:'http://sevendaysdeli-001-site1.itempurl.com/api/',
      Config:config
}
export default API_EndPoint;