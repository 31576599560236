import axios from "axios";
import API_EndPoint from "../../URL";

const GetDeliController = async (setTableData, setTotalNoDeli, setShowData) => {
    const businessName = localStorage.getItem("business_Name");
    await axios.get(API_EndPoint.URL + "accounting/nodelimen?" + "Business_Name=" + businessName,API_EndPoint.Config)
    .then(({data}) => {
        console.log(data);
        let total = 0;
        data = data.map((each, i) => {
            total += parseInt(each.Total_Amount || 0);
            return {...each, SrNo: parseInt(i+1)}
        });
        setTotalNoDeli(total);
        setTableData(data);
        setShowData(true);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default GetDeliController;