import axios from "axios";
import API_EndPoint from "../URL";

const GetWays = async (setWayData, businessName) => {
    const monthToShort = {January: "Jan", February: "Feb", March: "Mar", April: "Apr", May: "May", June: "Jun", July: "Jul", August: "Aug", September: "Sep", October: "Oct", November: "Nov", December: "Dec"};
    await axios.get(API_EndPoint.URL + "dashboard/way?Business_Name=" + businessName,API_EndPoint.Config)
    .then(({data}) => {
        const modifiedData = data.map(data => {
            data.Month = monthToShort[data.Month];
            // embed fake data
            // if (data.Month == "Jan" || data.Month == "Feb" || data.Month == "Mar" || data.Month == "Apr" || data.Month == "May") {
                // data.Total_Ways = (Math.random() * 1000).toFixed(0);
                // data.Assigned = (Math.random() * 300).toFixed(0);
                // data.Pending = (Math.random() * 300).toFixed(0);
                // data.Completed = (Math.random() * 300).toFixed(0);
                // data.Rejected = (Math.random() * 300).toFixed(0);
            // }
            // embed fake data
             return data
        });
        console.log(data);
        setWayData(modifiedData);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default GetWays;