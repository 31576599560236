import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Button,
  CssBaseline,
  ThemeProvider,
  TextField,
  Stack,
  IconButton,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  AddCircle,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Edit,
  Delete,
} from "@mui/icons-material";
import DataTableComponent from "../../../../components/DataTable";
import CreateFormComponent from "../../../../components/CreateForm";
import EditFormComponent from "../../../../components/EditForm";
import DataManagementStyle from "../accounting/AccountingManagementStyle";
import Get_Client_Data from "../../../../api/Data/Client/GetClientController";
import Get_City_Data from "../../../../api/Data/City/GetCityController";
import Get_Gate_Data from "../../../../api/Data/Gate/GetGateController";
import Get_Price_Data from "../../../../api/Data/Price/GetPriceController";
import Get_General_Expense_Data from "../../../../api/Data/GExp/GetGExpController";
import Get_All_Deli_Account from "../../../../api/InStock/GetAllDeliAccountController";
import Get_Other_Income from "../../../../api/Data/Other Income/GetOtherIncomeController";
import Get_Other_ToPay from "../../../../api/Data/OtherToPay/GetOtherToPayController";
import Get_Owner_Expense_Data from "../../../../api/Data/OwnerExp/GetOwnerExpController";
//Delete API
import Delete_Client from "../../../../api/Data/Client/DeleteClientController";
import Delete_City from "../../../../api/Data/City/DeleteCityController";
import Delete_Gate from "../../../../api/Data/Gate/DeleteGateController";
import Delete_Price from "../../../../api/Data/Price/DeletePriceController";
import Delete_General_Expense from "../../../../api/Data/GExp/DeleteGExpenseController";
import Delete_Other_Income from "../../../../api/Data/Other Income/DeleteOtherIncomeController";
import Delete_Other_ToPay from "../../../../api/Data/OtherToPay/DeleteOtherToPayController";
import Delete_Owner_Expense from "../../../../api/Data/OwnerExp/DeleteOwnerExpenseController";
import Theme from "../../../../theme/Theme";
import moment from "moment";
export default function DataManagePage() {
  const dataCategory = [
    { label: "Client" },
    { label: "City" },
    { label: "Price" },
    { label: "Gate" },
    { label: "General Expense" },
    { label: "Other Income" },
    { label: "Other To Pay" },
    { label: "Owner Expense" },
  ];
  const business_Name = localStorage.getItem("business_Name");
  const [allDeli, setAllDeli] = useState([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const [tableColumns, setTableColumns] = React.useState();
  const [tableData, setTableData] = React.useState();
  const [showData, setShowData] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({});
  const [showAddButton, setShowAddButton] = React.useState(false);
  const [buttonText, setButtonText] = React.useState("");
  const [showForm, setShowForm] = React.useState(false);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [totalExpense, setTotalExpense] = useState(0);
  const showButton = useRef(null);
  const hideButton = useRef(null);
  const [totalOtherIncome, setTotalOtherIncome] = useState(0);
  const [totalOtherToPay, setTotalOtherToPay] = useState(0);

  // for general expense filter
  const handleFromDateChange = (newVal) =>
    setFromDate(moment(newVal).format("YYYY-MM-DD"));
  const handleToDateChange = (newVal) =>
    setToDate(moment(newVal).format("YYYY-MM-DD"));
  const handleApplyDateFilter = () =>
    selectedCategory === "General Expense"
      ? Get_General_Expense_Data(
          setTableData,
          setShowData,
          fromDate,
          toDate,
          setTotalExpense
        )
      : Get_Owner_Expense_Data(
          setTableData,
          setShowData,
          fromDate,
          toDate,
          setTotalExpense
        );

  useEffect(() => {
    Get_City_Data(setTableData);
    Get_All_Deli_Account(setAllDeli, business_Name);
  }, []);
  var columns;
  selectedCategory === "Client"
    ? (columns = [
        "SrNo",
        "Client_Name",
        "Phone_Number",
        "Address",
        "Bank_Account",
        { name: "Bank_Account_Owner", label: "Bank_Owner" },
        "Contact_Person",
        "Create_Date",
        "Update_Date",
        {
          name: "Client_ID",
          options: {
            display: false,
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "City"
    ? (columns = [
        "SrNo",
        "C_Name",
        "Create_Date",
        "Update_Date",
        {
          name: "C_ID",
          options: {
            display: false,
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "Price"
    ? (columns = [
        "SrNo",
        "C_Name",
        "T_Name",
        "Deli_Price",
        "Expense_Fees",
        "Create_Date",
        "Update_Date",
        {
          name: "P_ID",
          options: {
            display: false,
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "Gate"
    ? (columns = [
        "SrNo",
        "Deli_Men",
        "G_Name",
        "G_Price",
        "Expense_Fees",
        "Create_Date",
        "Update_Date",
        {
          name: "G_ID",
          options: {
            display: false,
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "General Expense"
    ? (columns = [
        "SrNo",
        {
          name: "GEXP_ID",
          options: {
            display: false,
          },
        },
        {
          name: "General_Expense_Name",
          label: "Name",
        },
        {
          name: "General_Expense_Detail",
          label: "Details",
        },
        {
          name: "General_Expense_Amount",
          label: "Amount",
        },
        {
          name: "Create_Date",
          options: {
            sortDirection: "desc",
            customBodyRender: function (value, tableMeta, updateValue) {
              if (
                value === null ||
                value === "" ||
                value === "1/1/1900 12:00:00 AM"
              ) {
                return "";
              } else {
                var create_date = moment(value).format("DD-MM-YYYY");
                return create_date;
              }
            },
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "Other Income"
    ? (columns = [
        "SrNo",
        {
          name: "OI_ID",
          options: {
            display: false,
          },
        },
        {
          name: "Other_Income_Name",
          label: "Name",
        },
        {
          name: "Other_Income_Amount",
          label: "Amount",
        },
        {
          name: "Other_Income_Detail",
          label: "Detail",
        },
        {
          name: "Create_Date",
          options: {
            sortDirection: "desc",
            customBodyRender: function (value, tableMeta, updateValue) {
              if (
                value === null ||
                value === "" ||
                value === "1/1/1900 12:00:00 AM"
              ) {
                return "";
              } else {
                var create_date = moment(value).format("DD-MM-YYYY");
                return create_date;
              }
            },
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  {/* <IconButton color="primary" aria-label="edit" onClick={() =>UpdateData(dataIndex)} >
                      <Edit />
                  </IconButton> */}
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="update"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "Other To Pay"
    ? (columns = [
        "SrNo",
        {
          name: "OI_ID",
          options: {
            display: false,
          },
        },
        {
          name: "Other_ToPay_Name",
          label: "Name",
        },
        {
          name: "Other_ToPay_Amount",
          label: "Amount",
        },
        {
          name: "Other_ToPay_Detail",
          label: "Detail",
        },
        {
          name: "Create_Date",
          options: {
            sortDirection: "desc",
            customBodyRender: function (value, tableMeta, updateValue) {
              if (
                value === null ||
                value === "" ||
                value === "1/1/1900 12:00:00 AM"
              ) {
                return "";
              } else {
                var create_date = moment(value).format("DD-MM-YYYY");
                return create_date;
              }
            },
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  {/* <IconButton color="primary" aria-label="edit" onClick={() =>UpdateData(dataIndex)} >
                      <Edit />
                  </IconButton> */}
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                  <IconButton
                    color="primary"
                    aria-label="update"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : selectedCategory === "Owner Expense"
    ? (columns = [
        "SrNo",
        {
          name: "OEXP_ID",
          options: {
            display: false,
          },
        },
        {
          name: "Owner_Expense_Detail",
          label: "Details",
        },
        {
          name: "Owner_Expense_Amount",
          label: "Amount",
        },
        {
          name: "Create_Date",
          options: {
            sortDirection: "desc",
            customBodyRender: function (value, tableMeta, updateValue) {
              if (
                value === null ||
                value === "" ||
                value === "1/1/1900 12:00:00 AM"
              ) {
                return "";
              } else {
                var create_date = moment(value).format("DD-MM-YYYY");
                return create_date;
              }
            },
          },
        },
        {
          name: "\n\n\n\n\n\nACTION",
          options: {
            setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100,
              },
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101,
              },
            }),
            customBodyRenderLite: (
              dataIndex,
              value,
              tableMeta,
              updateValue
            ) => {
              return (
                <Stack direction="row" spacing={1}>
                  <IconButton
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateData(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    color="error"
                    aria-label="delete"
                    onClick={() => DeleteData(dataIndex)}
                  >
                    <Delete />
                  </IconButton>
                </Stack>
              );
            },
          },
        },
      ])
    : console.log("");

  const handleDataCategory = (category) => {
    console.log("Selected Category", selectedCategory);
    // eslint-disable-next-line no-unused-expressions
    category !== ""
      ? [setShowAddButton(true), setShowData(false)]
      : setShowAddButton(false);
    category === "Client"
      ? setButtonText("Add Client")
      : category === "City"
      ? setButtonText("Add City")
      : category === "Price"
      ? setButtonText("Add Price")
      : category === "Gate"
      ? setButtonText("Add Gate")
      : category === "Deli Price"
      ? setButtonText("Add Price")
      : category === "General Expense"
      ? setButtonText("Add Expense")
      : category === "Other Income"
      ? setButtonText("Add Other Income")
      : category === "Other To Pay"
      ? setButtonText("Add Other ToPay")
      : category === "Owner Expense"
      ? setButtonText("Add Owner Expense")
      : category === "General Expense"
      ? setShowData(true)
      : console.log("");
  };
  const UpdateData = async (dataIndex) => {
    var data;
    selectedCategory === "Client"
      ? setCurrentData({
          Client_ID: tableData[dataIndex]["Client_ID"],
          Client_Name: tableData[dataIndex]["Client_Name"],
          Phone_Number: tableData[dataIndex]["Phone_Number"],
          Address: tableData[dataIndex]["Address"],
          Bank_Account: tableData[dataIndex]["Bank_Account"],
          Bank_Account_Owner: tableData[dataIndex]["Bank_Account_Owner"],
          Contact_Person: tableData[dataIndex]["Contact_Person"],
        })
      : selectedCategory === "Other Income"
      ? setCurrentData({
          OI_ID: tableData[dataIndex]["OI_ID"],
          Other_Income_Name: tableData[dataIndex]["Other_Income_Name"],
          Other_Income_Amount: tableData[dataIndex]["Other_Income_Amount"],
          Other_Income_Detail: tableData[dataIndex]["Other_Income_Detail"],
          showButton: showButton,
          hideButton: hideButton,
          Business_Name: business_Name,
        })
      : selectedCategory === "Other To Pay"
      ? setCurrentData({
          OI_ID: tableData[dataIndex]["OI_ID"],
          Other_ToPay_Name: tableData[dataIndex]["Other_ToPay_Name"],
          Other_ToPay_Amount: tableData[dataIndex]["Other_ToPay_Amount"],
          Other_ToPay_Detail: tableData[dataIndex]["Other_ToPay_Detail"],
          showButton: showButton,
          hideButton: hideButton,
          Business_Name: business_Name,
        })
      : selectedCategory === "City"
      ? setCurrentData({
          C_ID: tableData[dataIndex]["C_ID"],
          C_Name: tableData[dataIndex]["C_Name"],
        })
      : selectedCategory === "Price"
      ? setCurrentData({
          P_ID: tableData[dataIndex]["P_ID"],
          T_Name: tableData[dataIndex]["T_Name"],
          C_Name: tableData[dataIndex]["C_Name"],
          Deli_Price: tableData[dataIndex]["Deli_Price"],
          Expense_Fees: tableData[dataIndex]["Expense_Fees"],
        })
      : selectedCategory === "General Expense"
      ? setCurrentData({
          GEXP_ID: tableData[dataIndex]["GEXP_ID"],
          General_Expense_Name: tableData[dataIndex]["General_Expense_Name"],
          General_Expense_Detail:
            tableData[dataIndex]["General_Expense_Detail"],
          General_Expense_Amount:
            tableData[dataIndex]["General_Expense_Amount"],
          Create_Date: tableData[dataIndex]["Create_Date"],
          business_Name: business_Name,
        })
      : selectedCategory === "Owner Expense"
      ? setCurrentData({
          OEXP_ID: tableData[dataIndex]["OEXP_ID"],
          Owner_Expense_Detail: tableData[dataIndex]["Owner_Expense_Detail"],
          Owner_Expense_Amount: tableData[dataIndex]["Owner_Expense_Amount"],
          business_Name: business_Name,
        })
      : selectedCategory === "Gate"
      ? setCurrentData({
          G_ID: tableData[dataIndex]["G_ID"],
          G_Name: tableData[dataIndex]["G_Name"],
          G_Price: tableData[dataIndex]["G_Price"],
          Expense_Fees: tableData[dataIndex]["Expense_Fees"],
          Deli_Men: tableData[dataIndex]["Deli_Men"],
          All_Deli_Men: allDeli,
          showBtn: showButton,
          hideBtn: hideButton,
        })
      : console.log("");
    setShowEditForm(true);
  };
  const DeleteData = (dataIndex) => {
    var id;
    selectedCategory === "Client"
      ? (id = tableData[dataIndex]["Client_ID"])
      : selectedCategory === "City"
      ? (id = tableData[dataIndex]["C_ID"])
      : selectedCategory === "Price"
      ? (id = tableData[dataIndex]["P_ID"])
      : selectedCategory === "Gate"
      ? (id = tableData[dataIndex]["G_ID"])
      : selectedCategory === "Deli Price"
      ? (id = tableData[dataIndex]["P_ID"])
      : selectedCategory === "General Expense"
      ? (id = tableData[dataIndex]["GEXP_ID"])
      : selectedCategory === "Other Income"
      ? (id = tableData[dataIndex]["OI_ID"])
      : selectedCategory === "Other To Pay"
      ? (id = tableData[dataIndex]["OI_ID"])
      : selectedCategory === "Owner Expense"
      ? (id = tableData[dataIndex]["OEXP_ID"])
      : console.log("");

    selectedCategory === "Client"
      ? Delete_Client(id)
      : selectedCategory === "City"
      ? Delete_City(id)
      : selectedCategory === "Price"
      ? Delete_Price(id)
      : selectedCategory === "Gate"
      ? Delete_Gate(id)
      : selectedCategory === "General Expense"
      ? Delete_General_Expense(id, { showButton, hideButton })
      : selectedCategory === "Other Income"
      ? Delete_Other_Income(id, { showButton, hideButton })
      : selectedCategory === "Other To Pay"
      ? Delete_Other_ToPay(id, { showButton, hideButton })
      : selectedCategory === "Owner Expense"
      ? Delete_Owner_Expense(id, { showButton, hideButton })
      : console.log("");
  };
  const handleTableData = () => {
    if (showData === false) {
      selectedCategory === "Client"
        ? Get_Client_Data(setTableData, setShowData)
        : selectedCategory === "City"
        ? Get_City_Data(setTableData, setShowData)
        : selectedCategory === "Price"
        ? Get_Price_Data(setTableData, setShowData)
        : selectedCategory === "Gate"
        ? Get_Gate_Data(setTableData, setShowData)
        : selectedCategory === "General Expense"
        ? Get_General_Expense_Data(
            setTableData,
            setShowData,
            fromDate,
            toDate,
            setTotalExpense
          )
        : selectedCategory === "Other Income"
        ? Get_Other_Income(setTableData, setShowData, setTotalOtherIncome)
        : selectedCategory === "Other To Pay"
        ? Get_Other_ToPay(setTableData, setShowData, setTotalOtherToPay)
        : selectedCategory === "Owner Expense"
        ? Get_Owner_Expense_Data(
            setTableData,
            setShowData,
            fromDate,
            toDate,
            setTotalExpense
          )
        : console.log("");
    } else {
      setShowData(false);
    }
  };
  return (
    <>
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete
              disablePortal
              id="dataCategory"
              options={dataCategory}
              fullWidth
              onChange={(event, value) => {
                const selectedValue = value || ""; // Handle the case where value is null                
                setSelectedCategory(selectedValue.label);
                handleDataCategory(selectedValue.label);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Data Category" />
              )}
            />
          </Grid>
          {showAddButton ? (
            <Grid item xs={12} sm={6} md={4}>
              <Button
                sx={{ alignSelf: "center", width: "auto", marginTop: 1 }}
                variant="contained"
                startIcon={<AddCircle />}
                onClick={() => setShowForm(true)}
              >
                {buttonText}
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Stack direction="column" mt={3}>
          {/* <Typography variant="pargraph" color="primary" sx={{fontWeight:'bold'}}>Show Data ></Typography> */}
          {showData ? (
            <>
              <Button
                sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
                endIcon={<KeyboardArrowDown />}
                ref={hideButton}
                onClick={() => handleTableData()}
              >
                Hide Data
              </Button>
              <br />
              {(selectedCategory === "General Expense" ||
                (selectedCategory === "Owner Expense" && showData)) && (
                <>
                  <Stack
                    sx={{ marginBottom: "1.5rem" }}
                    spacing={2}
                    direction={{ xs: "column", sm: "row" }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="From Date"
                        inputFormat="dd-MM-yyyy"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                      <MobileDatePicker
                        label="To Date"
                        inputFormat="dd-MM-yyyy"
                        value={toDate}
                        onChange={handleToDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    <Button
                      variant="contained"
                      sx={{ fontWeight: "bold" }}
                      onClick={handleApplyDateFilter}
                    >
                      Apply Filter
                    </Button>
                  </Stack>
                  <Grid container spacing={2} sx={{ marginBottom: "1.5rem" }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={DataManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {totalExpense} MMK
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={DataManagementStyle.subHeading}
                          >
                            Total Expense
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
              {(selectedCategory === "Other Income" ||
                selectedCategory === "Other To Pay") &&
                showData && (
                  <>
                    <Grid container spacing={2} sx={{ marginBottom: "1.5rem" }}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Box sx={DataManagementStyle.boxStyle}>
                          <Stack direction="column">
                            <Typography variant="h5">
                              {selectedCategory === "Other Income"
                                ? totalOtherIncome.toLocaleString("en-us")
                                : totalOtherToPay.toLocaleString("en-us")}{" "}
                              MMK
                            </Typography>
                            <Typography
                              variant="h8"
                              sx={DataManagementStyle.subHeading}
                            >
                              {"Total " + selectedCategory}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                )}
              <DataTableComponent
                title={
                  selectedCategory === "Other To Pay"
                    ? "Other ToPay" + " List"
                    : selectedCategory + " List"
                }
                data={tableData}
                columns={columns}
              />
            </>
          ) : (
            <Button
              sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
              endIcon={<KeyboardArrowRight />}
              ref={showButton}
              onClick={() => handleTableData()}
            >
              Show Data
            </Button>
          )}
        </Stack>
        {showForm && selectedCategory === "Client" ? (
          <CreateFormComponent
            headerText="Client Form 👩🏻"
            formType="Client"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "City" ? (
          <CreateFormComponent
            headerText="City Form ⛪"
            formType="City"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "Price" ? (
          <CreateFormComponent
            headerText="Price Form 💒"
            formType="Price"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "Gate" ? (
          <CreateFormComponent
            headerText="Gate Form 🚍"
            formType="Gate"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "General Expense" ? (
          <CreateFormComponent
            headerText="General Expense Form &#128176;"
            formType="General Expense"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "Other Income" ? (
          <CreateFormComponent
            headerText="Other Income &#128178;"
            formType="Other Income"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "Other To Pay" ? (
          <CreateFormComponent
            headerText="Other  ToPay&#128178;"
            formType="Other ToPay"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : showForm && selectedCategory === "Owner Expense" ? (
          <CreateFormComponent
            headerText="Owner Expense&#128178;"
            formType="Owner Expense"
            actionType="createAccount"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : (
          <></>
        )}
        {/* Update Form */}
        {showEditForm && selectedCategory === "Client" ? (
          <EditFormComponent
            headerText="Edit Client ✏️"
            formType="Client"
            actionType="editClient"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "City" ? (
          <EditFormComponent
            headerText="Edit City ✏️"
            formType="City"
            actionType="editCity"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "Price" ? (
          <EditFormComponent
            headerText="Edit Price ✏️"
            formType="Price"
            actionType="editPrice"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "Gate" ? (
          <EditFormComponent
            headerText="Edit Gate ✏️"
            formType="Gate"
            actionType="editGate"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "General Expense" ? (
          <EditFormComponent
            headerText="Edit Expense &#128176;"
            formType="General Expense"
            actionType="editGeneralExpense"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "Other Income" ? (
          <EditFormComponent
            headerText="Edit Other Income"
            formType="Other Income"
            actionType="editOtherIncome"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "Other To Pay" ? (
          <EditFormComponent
            headerText="Edit Other ToPay"
            formType="Other ToPay"
            actionType="editOtherToPay"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : showEditForm && selectedCategory === "Owner Expense" ? (
          <EditFormComponent
            headerText="Edit Owner Expense"
            formType="Owner Expense"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : (
          <></>
        )}
      </ThemeProvider>
    </>
  );
}
