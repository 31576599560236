import React from "react";
import MUIDataTable from "mui-datatables";
export default function DataTableComponent({ title, data, columns }) {
  console.log("Table Title",title);
  const account_collect_assign_deli_ways_data_management_options = {
    filterType: "dropdown",
    selectableRows: false,
    rowsPerPage: [300],
    rowsPerPageOptions: [300, 500, 1000],
    //responsive: 'horizontal',
    // jumpToPage: true,
  };
  console.log(columns, data);
  const client_order_debit_profit_instock_options = {
    filterType: "dropdown",
    selectableRows: "none",
    rowsPerPage: [200],
    rowsPerPageOptions: [200, 400, 1000],
    //responsive: 'horizontal',
    // jumpToPage: true,
  };
  // const debit_profit_instock_options = {
  //   filterType: "dropdown",
  //   selectableRows: "none",
  //   rowsPerPage: 100,
  //   rowsPerPageOptions: [100, 200, 1000]
  // };
  const deli_men_options={
    filterType: "dropdown",
    selectableRows: false,
    responsive: 'horizontal'
  }
  return (
    <>
      {title === "Account List" ||title === "Collect&Assign Order List" || title === "Deli Ways" || title === "Client List" || title === "City List" || title === "Price List" || title === "Gate List" || title === "General Expense List" || title==="Other Income List" || title==="Other ToPay List" || title==="Owner Expense List" || title === "Expense List" || title === "Way Check" || title === "No DeliMen" || title === "OS Pending" || title==="OS Report" ? (
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={account_collect_assign_deli_ways_data_management_options}
        />
      ) : title === "Order List" ? (
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={client_order_debit_profit_instock_options}
        />
      ) : (title==="Deli Ways" && title==="No DeliMen")?(
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={deli_men_options}
        />
      ) : (title==="Debit List" || title==="Return Item List" || title==="Profit List" || title==="InStock List" || title==="OS Pending") ? (
        <MUIDataTable
          title={title}
          data={data}
          columns={columns}
          options={client_order_debit_profit_instock_options}
        />
      ):
      <></>}
    </>
  );
}
