import React, { useEffect, useState } from "react";
import API_EndPoint from "../api/URL";
import axios from "axios";
import moment from "moment";
import {
  ThemeProvider,
  CssBaseline,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Container,
  Autocomplete,
  Stack,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Theme from "../theme/Theme";
import AutocompleteComponent from "./AutoComplete";
import { MobileDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Get_DeliExpense_Fees from "../api/Data/Price/GetDeliExpenseFeesController";
import Get_Deli_Account from "../api/Account/GetDeliAccountController";
import Get_Gate_Price_By_Gate_Name from "../api/Data/Gate/GetGatePriceByGateNameController";
import Update_Client from "../api/Data/Client/UpdateClientController";
import Update_City from "../api/Data/City/UpdateCityController";
import Update_Price from "../api/Data/Price/UpdatePriceController";
import Update_Gate from "../api/Data/Gate/UpdateGateController";
import Update_Client_Order from "../api/Order/ClientOrder/UpdateClientOrderController";
import Update_Deli_Ways from "../api/DeliMen/UpdateDeliWayController";
import Update_Account from "../api/Account/UpdateAccountController";
import Update_CollectAssign_Order from "../api/Order/CollectAssignOrder/UpdateCollectAssignOrderController";
import Update_Deli_Men_Ways from "../api/DeliMen/UpdateDeliWayController";
import Update_Way from "../api/Way/UpdateWayController";
import Get_All_Deli_Account from "../api/InStock/GetAllDeliAccountController";
import Update_Deli_Men_Ways_Stock from "../api/InStock/UpdateDeliWayController";
import Update_General_Expense from "../api/Data/GExp/UpdateGExpenseController";
import Update_Owner_Expense from "../api/Data/OwnerExp/UpdateOwnerExpenseController";
import Update_Debit_ToPay from "../api/Accounting/Debit/UpdateDebitToPayController";
import Update_Otherincome from "../api/Data/Other Income/UpdateOtherIncomeController";
import Update_Othertopay from "../api/Data/OtherToPay/UpdateOtherToPayController";
import Update_OsPending from "../api/Accounting/OsPending/UpdateOsPendingController";
// testing
// import ApiWrap from "../api/ApiWrap";
// import updateAccountModified from "../api/Account/UpdateAccountModified";
// end

export default function EditFormComponent({
  headerText,
  formType,
  actionType,
  buttonText,
  setShowEditForm,
  currentData,
  setTableData,
  tableData,
}) {
  // const townshipData = [
  //   {
  //     label: "Pabedan",
  //   },
  //   {
  //     label: "Kyauktada",
  //   },
  //   {
  //     label: "Lanmadaw",
  //   },
  // ];
  var api_endpoint = API_EndPoint.URL;
  var business_name = localStorage.getItem("business_Name");
  //Account Form
  const [userName, setUserName] = React.useState(currentData["Client"]);
  const [phoneNumber, setPhoneNumber] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState("");
  const [selectedTownship, setSelectedTownship] = React.useState("");
  const [selectedWayTownship, setSelectedWayTownship] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [selectedRadio, setSelectedRadio] = React.useState("Delivery");
  //Client
  const [address, setAddress] = React.useState("");
  const [bankAccount, setBankAccount] = React.useState("");
  const [bankAccountOwner, setBankAccountOwner] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  //Township
  const [cityData, setCityData] = React.useState([]);
  const [townshipData, setTownshipData] = React.useState([]);
  const [townshipName, setTownshipName] = React.useState("");
  //Gate
  const [gateName, setGateName] = React.useState("");
  const [gatePrice, setGatePrice] = React.useState(0);
  //Deli Price
  const [deliPrice, setDeliPrice] = React.useState(0);
  const [expenseFees, setExpenseFees] = React.useState(0);
  const [clientData, setClientData] = React.useState([]);
  const [deliUserName, setDeliUserName] = React.useState([]);
  //Client Order
  const [selectedClient, setSelectedClient] = React.useState("");
  const [noWays, setNoWays] = React.useState();
  const [pickupDate, setPickupDate] = React.useState();
  const [assignDate, setAssignDate] = React.useState();
  const [orderTotalAmount, setOrderTotalAmount] = React.useState();
  const [clientPayType, setClientPayType] = React.useState();
  const [paymentStatus, setPaymentStatus] = React.useState();
  const [remark, setRemark] = React.useState("");
  const [gateAmount, setGateAmount] = React.useState();
  const [allPaidAmount, setAllPaidAmount] = React.useState();
  const [checkStatus, setCheckStatus] = useState("");
  const checkStatusList = ["Uncheck", "Check"];
  //Collect&Assign Order
  const [receiverName, setReceiverName] = React.useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [itemName, setItemName] = useState("");
  const [expenseStatus, setExpenseStatus] = React.useState("");
  const [orderItemPrice, setOrderItemPrice] = React.useState(0);
  const [deliType, setDeliType] = React.useState("");
  const [deliStatus, setDeliStatus] = React.useState("");
  const [gateList, setGateList] = React.useState([]);
  const [deliFees, setDeliFees] = React.useState(0);
  const [orderItemAmount, setOrderItemAmount] = React.useState(0);
  const [selectedDeliUsername, setSelectedDeliUsername] = React.useState("");
  const [receiverPayment, setReceiverPayment] = React.useState("");
  const [orderRemark, setOrderRemark] = React.useState("");
  const [selectedGate, setSelectedGate] = React.useState("");
  //Deli Ways
  const [deliMenRemark, setDeliMenRemark] = React.useState("");
  const [assignRemark, setAssignRemark] = React.useState("");
  const [halfPaidAmount, setHalfPaidAmount] = React.useState(0);
  // In Stock
  const [deliMen, setDeliMen] = useState([]);
  //Deli Men
  const [returnOrHalfAmount, setReturnOrHalfAmount] = React.useState(0);
  const receiverPayType = [
    { label: "UnPaid" },
    { label: "OS Paid" },
    { label: "Only Deli" },
    { label: "Deli Free" },
  ];
  const [deliStatusOptions, setDeliStatusOptions] = useState([]);
  // Other income
  const [otherIncomeAmount, setOtherIncomeAmount] = useState(0);
  const [otherIncomeName, setOtherIncomeName] = useState("");
  const [otherIncomeDetail, setOtherIncomeDetail] = useState("");
  // Other topay
  const [otherToPayAmount, setOtherToPayAmount] = useState(0);
  const [otherToPayName, setOtherToPayName] = useState("");
  const [otherToPayDetail, setOtherToPayDetail] = useState("");
  // const businessName = getItem("business_Name");
  const [showTanSarPrice, setShowTanSarPrice] = React.useState(false);
  const [tanSarPrice, setTanSarPrice] = React.useState(0);
  // General Expense
  const [expenseName, setExpenseName] = useState("");
  const [expenseDetails, setExpenseDetails] = useState("");
  const [expenseAmount, setExpenseAmount] = useState(0);
  const [expenseDate, setExpenseDate] = useState("");
  const [toPayAmount, setToPayAmount] = useState(0);
  const handleReceiverPayType = (newValue) => {
    setReceiverPayment(newValue);
    var total_amount = 0;
    if (newValue === "UnPaid") {
      total_amount = parseInt(orderItemAmount) + parseInt(deliFees);
      setOrderTotalAmount(total_amount);
    } else if (newValue === "Only Deli") {
      total_amount = deliFees;
      setOrderTotalAmount(total_amount);
    } else if (newValue === "Deli Free") {
      total_amount = orderItemAmount;
      setOrderTotalAmount(total_amount);
    } else {
      setOrderTotalAmount(total_amount);
    }
  };
  const handlePickupDateChange = (newValue) => {
    setPickupDate(newValue);
  };
  const handleAssignDateChange = (newValue) => {
    console.log("New Assign DAte",moment(newValue).format("YYYY-MM-DD"))
    setAssignDate(moment(newValue).format("YYYY-MM-DD"));
  };
  useEffect(async () => {
    if (formType === "Account") {
      //getting client list for account management
      await axios
        .get(
          api_endpoint + "data/client?Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ Client_Name }) => {
              setClientData((clientData) => [...clientData, Client_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      setUserName(currentData.User_Name);
      setPhoneNumber(currentData.Phone_Number);
      setPassword(currentData.Password);
      setSelectedRadio(currentData.UserRole);
      setSelectedCity(currentData.City);
      setSelectedWayTownship((selectedWayTownship) => [
        ...selectedWayTownship,
        currentData.Township,
      ]);
      setEmail(currentData.Email);
    } else if (formType === "Client") {
      setUserName(currentData.Client_Name);
      setAddress(currentData.Address);
      setPhoneNumber(currentData.Phone_Number);
      setBankAccount(currentData.Bank_Account);
      setBankAccountOwner(currentData.Bank_Account_Owner);
      setContactPerson(currentData.Contact_Person);
    } else if (formType === "City") {
      setSelectedCity(currentData.C_Name);
    } else if (formType === "Price") {
      setSelectedCity(currentData.C_Name);
      setTownshipName(currentData.T_Name);
      setDeliPrice(currentData.Deli_Price);
      setExpenseFees(currentData.Expense_Fees);
    } else if (formType === "Gate") {
      setGateName(currentData.G_Name);
      setGatePrice(currentData.G_Price);
      setExpenseFees(currentData.Expense_Fees);
      setSelectedDeliUsername(currentData.Deli_Men);
      setDeliUserName(currentData.All_Deli_Men);
    } else if (formType === "Client Order") {
      setSelectedClient(currentData.Client_Name);
      setNoWays(currentData.No_Ways);
      setPickupDate(currentData.Pickup_Date);
      setOrderTotalAmount(currentData.Total_Amount || "");
      setClientPayType(currentData.Client_Pay_Type);
      setPaymentStatus(currentData.Payment_Status);
      setRemark(currentData.Remark);
      setCheckStatus(currentData.Check_Status);
      setGateAmount(currentData.Gate_Amount);
      setAllPaidAmount(currentData.All_Paid_Amount)
    } else if (formType === "Debit ToPay") {
      setToPayAmount(currentData.amount);
    } else if (formType === "Collect&Assign Order") {
      setReceiverName(currentData.Receiver_Name);
      setReceiverPhoneNumber(currentData.Receiver_Phone_Number);
      setReceiverAddress(currentData.Receiver_Address);
      setItemName(currentData.Item_Name);
      if (currentData.Assign_Date === "01-01-1900") {
        setAssignDate(moment(new Date()).format("DD-MM-YYYY"));
      } else {
        setAssignDate(currentData.Assign_Date);
      }
      setAssignDate(currentData.Assign_Date);
      setExpenseStatus(currentData.Expense_Status);
      setSelectedDeliUsername(currentData.Deli_Men);
      setSelectedCity(currentData.C_Name);
      setSelectedTownship(currentData.T_Name);
      setOrderItemAmount(currentData.Item_Price);
      setDeliPrice(currentData.Deli_Price);
      setExpenseFees(currentData.Expense_Fees);
      setSelectedGate(currentData.G_Name);
      setGatePrice(currentData.G_Price);
      setReceiverPayment(currentData.Deli_Type);
      setOrderTotalAmount(currentData.Total_Amount);
      setDeliStatus(currentData.Deli_Status);
      setPaymentStatus(currentData.Payment_Status);
      setRemark(currentData.Assign_Remark);
    } else if (formType === "Deli Ways") {
      console.log(
        "Current Data Half Paid Amount",
        currentData.Half_Paid_Amount
      );
      setSelectedDeliUsername(currentData.Deli_Men);
      setReceiverName(currentData.Receiver_Name);
      setSelectedCity(currentData.C_Name);
      setSelectedTownship(currentData.T_Name);
      setTanSarPrice(currentData.Tan_Sar_Price);
      setDeliPrice(currentData.Expense_Fees); //Way mrr pya htrr tae deli price soe trr expense fees nae atuu tuu bae.
      setDeliType(currentData.Deli_Type);
      setDeliStatus(currentData.Deli_Status);
      setDeliMenRemark(currentData.Deli_Men_Remark);
      setAssignRemark(currentData.Assign_Remark);
      setGateName(currentData.G_Name);
      setGatePrice(currentData.G_Price);
      setExpenseStatus(currentData.Expense_Status);
      setReturnOrHalfAmount(currentData.Half_Paid_Amount);
    } else if (formType === "Other Income") {
      setOtherIncomeName(currentData.Other_Income_Name);
      setOtherIncomeAmount(currentData.Other_Income_Amount);
      setOtherIncomeDetail(currentData.Other_Income_Detail);
    } else if (formType === "Other ToPay") {
      setOtherToPayName(currentData.Other_ToPay_Name);
      setOtherToPayAmount(currentData.Other_ToPay_Amount);
      setOtherToPayDetail(currentData.Other_ToPay_Detail);
    } else if (formType === "Deli Men") {
      if (currentData.Deli_Status === "Assigned") {
        setDeliStatusOptions(["Completed", "OnWay"]);
        setDeliType("Cash");
        setDeliStatus("Completed");
      } else if (currentData.Deli_Status === "OnWay") {
        setDeliStatusOptions(["Assigned"]);
        setDeliStatus("Assigned");
      }
    } else if (formType === "In Stock") {
      await Get_All_Deli_Account(setDeliMen, business_name);
      setDeliStatus("Assigned");
      setSelectedDeliUsername(currentData.Deli_Men);
    } else if (formType === "General Expense") {
      setExpenseName(currentData.General_Expense_Name);
      setExpenseAmount(currentData.General_Expense_Amount);
      setExpenseDetails(currentData.General_Expense_Detail);
      setExpenseDate(moment(currentData.Create_Date).format("YYYY-MM-DD"));
    } else if (formType === "Owner Expense") {
      setExpenseAmount(currentData.Owner_Expense_Amount);
      setExpenseDetails(currentData.Owner_Expense_Detail);
    } else if (formType === "OS Pending") {
      await Get_All_Deli_Account(setDeliMen, business_name);
      setDeliStatusOptions(["Assigned", "OnWay", "Accepted"]);
      setSelectedDeliUsername(currentData.Deli_Men);
      setDeliStatus(currentData.Deli_Status);
    }
    //fetching city data
    if (
      formType === "Township" ||
      formType === "Deli Price" ||
      formType === "Account" ||
      formType === "Collect&Assign Order" ||
      formType === "Deli Ways"
    ) {
      await axios
        .get(
          api_endpoint + "data/city?Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ C_Name }) => {
              setCityData((cityData) => [...cityData, C_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      //getting township list
      await axios
        .get(
          api_endpoint + "data/price?Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ T_Name }) => {
              if (T_Name !== "") {
                setTownshipData((townshipData) => [...townshipData, T_Name]);
              }
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      //getting client name if it is order form
    }
    if (
      formType === "Client Order" ||
      formType === "Collect&Assign Order" ||
      formType === "Deli Ways"
    ) {
      await axios
        .get(
          api_endpoint + "data/client?Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ Client_Name }) => {
              setClientData((clientData) => [...clientData, Client_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      await axios
        //getting all delivery men name
        .get(
          api_endpoint + "account/deli?T_Name=&Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ User_Name }) => {
              setDeliUserName((deliUserName) => [...deliUserName, User_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      if (formType != "Deli Ways") {
        await axios
          //getting specific deli name by Township
          .get(
            api_endpoint +
              "account/deli?T_Name=" +
              selectedTownship +
              "&Business_Name=" +
              business_name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.map(({ User_Name }) => {
                setSelectedDeliUsername(User_Name);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
      }
      //get c_name list from Township Table
      await axios
        .get(
          api_endpoint + "data/city/price?Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            setCityData([]);
            res.data.map(({ C_Name }) => {
              setCityData((cityData) => [...cityData, C_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      await axios
        .get(
          api_endpoint + "data/gate?Business_Name=" + business_name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ G_Name }) => {
              setGateList((gateList) => [...gateList, G_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
    }
  }, []);
  const handleSubmitForm = () => {
    var updateData;
    if (formType === "Account") {
      updateData = {
        User_ID: currentData.User_ID,
        User_Name: userName,
        Phone_Number: phoneNumber,
        Email: email,
        User_Role: selectedRadio,
        Client_Name: selectedClient,
        City: selectedCity,
        Township: selectedWayTownship,
        Password: password,
        Business_Name: localStorage.getItem("business_Name"),
      };
      Update_Account(updateData, setLoading);
      // testing
      // ApiWrap(["account", "register"], updateData, updateAccountModified, {setLoading}, "put");
      // end
    } else if (formType === "Client") {
      updateData = {
        Client_ID: currentData.Client_ID,
        Client_Name: userName,
        Phone_Number: phoneNumber,
        Address: address,
        Bank_Account: bankAccount,
        Bank_Account_Owner: bankAccountOwner,
        Contact_Person: contactPerson,
        Business_Name: localStorage.getItem("business_Name"),
      };
      Update_Client(updateData, setLoading);
    } else if (formType === "City") {
      updateData = {
        C_ID: currentData.C_ID,
        C_Name: selectedCity,
        Business_Name: localStorage.getItem("business_Name"),
      };
      Update_City(updateData, setLoading);
    } else if (formType === "Price") {
      updateData = {
        P_ID: currentData.P_ID,
        T_Name: townshipName,
        C_Name: selectedCity,
        Deli_Price: deliPrice,
        Expense_Fees: expenseFees,
        Business_Name: localStorage.getItem("business_Name"),
      };
      Update_Price(updateData, setLoading);
    } else if (formType === "Gate") {
      updateData = {
        G_ID: currentData.G_ID,
        G_Name: gateName,
        G_Price: gatePrice,
        Expense_Fees: expenseFees,
        Business_Name: localStorage.getItem("business_Name"),
        Deli_Men: selectedDeliUsername,
      };
      const showBtn = currentData.showBtn;
      const hideBtn = currentData.hideBtn;
      Update_Gate(updateData, setLoading, setShowEditForm, showBtn, hideBtn);
    } else if (formType === "Client Order") {
      updateData = {
        // "P_ID":currentData.P_ID,
        // "Deli_Price":deliPrice,
        // "Expense_Fees":expenseFees,
        // "C_Name":selectedCity,
        // "T_Name":selectedTownship,
        // "Business_Name":localStorage.getItem('business_Name')
        CO_ID: currentData.CO_ID,
        Client_Name: selectedClient,
        Item_Quantity: noWays,
        Pickup_Date: pickupDate,
        Total_Amount: orderTotalAmount.toString(),
        Gate_Amount: gateAmount,
        All_Paid_Amount: allPaidAmount,
        Client_Pay_Type: clientPayType,
        Payment_Status: paymentStatus,
        Remark: remark,
        Business_Name: localStorage.getItem("business_Name"),
        Order_Check_Status: checkStatus,
      };
      Update_Client_Order(updateData, setLoading, setShowEditForm);
    }
    //Debit ToPay
    else if (formType === "Debit ToPay") {
      if (toPayAmount) {
        updateData = {
          CO_ID: currentData.id,
          To_Pay_Paid_Amount: toPayAmount,
          Business_Name: localStorage.getItem("business_Name"),
          setShowData: currentData.setShowData,
        };
        Update_Debit_ToPay(updateData, setLoading, setShowEditForm);
      } else {
        alert("Please fill to pay amount.");
      }
    }
    // otherincome
    else if (formType === "Other Income") {
      if (otherIncomeName && otherIncomeAmount) {
        updateData = {
          OI_ID: currentData.OI_ID,
          Other_Income_Name: otherIncomeName,
          Other_Income_Amount: otherIncomeAmount,
          Other_Income_Detail: otherIncomeDetail,
          Business_Name: currentData.Business_Name,
        };
        Update_Otherincome(
          updateData,
          setShowEditForm,
          currentData.showButton,
          currentData.hideButton
        );
      } else {
        alert(!otherIncomeName ? "Name is required" : "Amount is required");
      }
    }
    // otherincome
    else if (formType === "Other ToPay") {
      if (otherToPayName && otherToPayAmount) {
        updateData = {
          OI_ID: currentData.OI_ID,
          Other_ToPay_Name: otherToPayName,
          Other_ToPay_Amount: otherToPayAmount,
          Other_ToPay_Detail: otherToPayDetail,
          Business_Name: currentData.Business_Name,
        };
        Update_Othertopay(
          updateData,
          setShowEditForm,
          currentData.showButton,
          currentData.hideButton
        );
      } else {
        alert(!otherToPayName ? "Name is required" : "Amount is required");
      }
    }
    //Collect&Assign Order
    else if (formType === "Collect&Assign Order") {
      updateData = {
        CAO_ID: currentData.CAO_ID,
        Expense_Status: expenseStatus,
        Receiver_Name: receiverName,
        Receiver_Phone_Number: receiverPhoneNumber,
        Receiver_Address: receiverAddress,
        Item_Name: itemName,
        Assign_Date: assignDate,
        Deli_Men: selectedDeliUsername,
        C_Name: selectedCity,
        T_Name: selectedTownship,
        Item_Price: orderItemAmount,
        Deli_Price: deliPrice,
        Expense_Fees: expenseFees,
        G_Name: selectedGate,
        G_Price: gatePrice,
        Deli_Type: receiverPayment,
        Total_Amount: orderTotalAmount,
        Deli_Status: deliStatus,
        Payment_Status: paymentStatus,
        Assign_Date: assignDate,
        Assign_Remark: remark,
        Business_Name: localStorage.getItem("business_Name"),
      };
      Update_CollectAssign_Order(updateData, setLoading);
    } else if (formType === "Deli Ways") {
      updateData = {
        CAO_ID: currentData.CAO_ID,
        Deli_Men: selectedDeliUsername,
        Deli_Status: deliStatus,
        Receiver_Name: receiverName,
        C_Name: selectedCity,
        T_Name: selectedTownship,
        Tan_Sar_Price:
          tanSarPrice === null || tanSarPrice === "" ? 0 : tanSarPrice,
        G_Name: gateName,
        G_Price: gatePrice,
        Expense_Status: expenseStatus,
        Expense_Fees: deliPrice,
        Deli_Type: deliType,
        Half_Paid_Amount: parseInt(halfPaidAmount || 0),
        Return_Item_Amount: parseInt(returnOrHalfAmount || 0),
        Deli_Men_Remark: deliMenRemark,
        Assign_Remark: assignRemark,
        Business_Name: localStorage.getItem("business_Name"),
      };
      //if updating deli status is onway then remark must write
      deliStatus === "OnWay" && deliMenRemark === ""
        ? alert("Please write remark!")
        : deliStatus === "Rejected" && deliMenRemark === ""
        ? alert("Please add delimen remark!")
        : console.log("Update Way", updateData);
      Update_Way(
        updateData,
        setLoading,
        setShowEditForm,
        currentData.filterBtn,
        currentData.Show_Btn,
        currentData.Hide_Btn
      ).then(() => {
        const indexToRemove = tableData.findIndex(
          (item) => item.CAO_ID === currentData.CAO_ID
        );

        if (indexToRemove !== -1) {
          // Create a new array without the object to remove
          const updatedData = [
            ...tableData.slice(0, indexToRemove),
            ...tableData.slice(indexToRemove + 1),
          ];

          // Update the state with the new array
          setTableData(updatedData);
        }
      });
    } else if (formType === "Deli Men") {
      if (
        deliStatus === "OnWay" ||
        ((deliType === "Transfer" || deliType === "Return Item") &&
          returnOrHalfAmount) ||
        (deliType === "Tan Sar" && tanSarPrice) ||
        (deliType !== "Transfer" &&
          deliType !== "Tan Sar" &&
          deliType !== "Return Item")
      ) {
        updateData = {
          CAO_ID: currentData.CAO_ID,
          Deli_Status: deliStatus,
          Deli_Type: deliType,
          Return_Or_Half_Amount: returnOrHalfAmount,
          Tan_Sar_Price: tanSarPrice,
          Deli_Men_Remark: remark,
          Business_Name: localStorage.getItem("business_Name"),
        };

        if (deliStatus === "OnWay" || deliType === "Return Item") {
          if (remark === "") {
            alert("remark is required");
          } else {
            Update_Deli_Men_Ways(
              updateData,
              setLoading,
              setShowEditForm,
              currentData.Show_Btn,
              currentData.Hide_Btn
            );
          }
        } else {
          Update_Deli_Men_Ways(
            updateData,
            setLoading,
            setShowEditForm,
            currentData.Show_Btn,
            currentData.Hide_Btn
          );
        }
      } else {
        alert("Please Fill *Amount");
      }
    } else if (formType === "In Stock") {
      updateData = {
        ...currentData,
        Deli_Men:
          deliStatus === "Rejected"
            ? currentData.Deli_Men
            : selectedDeliUsername,
        Deli_Status: deliStatus,
      };
      Update_Deli_Men_Ways_Stock(
        updateData,
        setLoading,
        setShowEditForm,
        currentData.Deli_Men,
        currentData.Business_Name
      );
    } else if (formType === "General Expense") {
      updateData = {
        ...currentData,
        General_Expense_Name: expenseName,
        General_Expense_Amount: expenseAmount,
        General_Expense_Detail: expenseDetails,
        Create_Date: expenseDate,
      };
      Update_General_Expense(updateData, setLoading, setShowEditForm);
    } else if (formType === "Owner Expense") {
      updateData = {
        ...currentData,
        Owner_Expense_Amount: expenseAmount,
        Owner_Expense_Detail: expenseDetails,
      };
      Update_Owner_Expense(updateData, setLoading, setShowEditForm);
    } else if (formType === "OS Pending") {
      updateData = {
        ...currentData,
        Deli_Men: selectedDeliUsername,
        Deli_Status: deliStatus,
        Business_Name: localStorage.getItem("business_Name"),
      };
      Update_OsPending(updateData, setLoading, setShowEditForm);
    }
  };
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Dialog
        open={true}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? setShowEditForm(false) : ""
        }
      >
        <DialogTitle>{headerText}</DialogTitle>
        <DialogContent>
          <br />
          {/* Account Create Form */}
          {formType == "Account" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                margin="dense"
                id="phoneNumber"
                value={phoneNumber}
                label="Phone Number"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              {/* <TextField
                margin="dense"
                id="oldPassword"
                value={password}
                label="Old Password"
                type="password"
                fullWidth
                variant="standard"
                onChange={(event) => setUserName(event.target.value)}
              /> */}
              <TextField
                margin="dense"
                id="newPassword"
                value={password}
                label="Password"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setPassword(event.target.value)}
              />
              <RadioGroup
                aria-labelledby="userRole"
                name="radio-buttons-group"
                defaultValue={currentData.UserRole}
                style={{ display: "felx", flex: 1, flexDirection: "row" }}
                onChange={(event) => setSelectedRadio(event.target.value)}
              >
                <FormControlLabel
                  value="Delivery"
                  control={<Radio />}
                  label="Delivery"
                />
                <FormControlLabel
                  value="Admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel value="OS" control={<Radio />} label="OS" />
              </RadioGroup>
              {selectedRadio === "OS" ? (
                <Autocomplete
                  disablePortal
                  id="dataCategory"
                  value={currentData.ClientName}
                  options={clientData}
                  fullWidth
                  onSelect={(event) => setSelectedClient(event.target.value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Client" />
                  )}
                />
              ) : (
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(event) => setUserName(event.target.value)}
                />
              )}
              {selectedRadio === "Admin" ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  value={email}
                  label="Email"
                  type="email"
                  fullWidth
                  variant="standard"
                  onChange={(event) => [setEmail(event.target.value)]}
                />
              ) : selectedRadio === "Delivery" ? (
                [
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={selectedCity}
                    options={cityData}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField {...params} label="City" />
                    )}
                    onChange={(event, value) => [setSelectedCity(value)]}
                  />,
                  <br />,
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    value={selectedWayTownship}
                    options={townshipData}
                    onChange={(event, value) => setSelectedWayTownship(value)}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Way Township"
                        placeholder=""
                      />
                    )}
                  />,
                ]
              ) : (
                <></>
              )}
            </Stack>
          ) : (
            <></>
          )}
          {/* Other Income */}
          {formType === "Other Income" ? (
            <>
              <TextField
                margin="dense"
                id="otherIncomeName"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={otherIncomeName}
                required
                onChange={(event) => setOtherIncomeName(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                margin="dense"
                id="otherIncomeAmount"
                label="Amount"
                type="number"
                fullWidth
                variant="standard"
                value={otherIncomeAmount}
                required
                onChange={(event) => setOtherIncomeAmount(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
              <TextField
                margin="dense"
                id="otherIncomeDetail"
                label="Detail"
                type="text"
                fullWidth
                variant="standard"
                value={otherIncomeDetail}
                required
                onChange={(event) => setOtherIncomeDetail(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
            </>
          ) : (
            <></>
          )}
          {/* Other Income */}
          {formType === "Other ToPay" ? (
            <>
              <TextField
                margin="dense"
                id="otherToPayName"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={otherToPayName}
                required
                onChange={(event) => setOtherToPayName(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                margin="dense"
                id="otherToPayAmount"
                label="Amount"
                type="number"
                fullWidth
                variant="standard"
                value={otherToPayAmount}
                required
                onChange={(event) => setOtherToPayAmount(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
              <TextField
                margin="dense"
                id="otherToPayDetail"
                label="Detail"
                type="text"
                fullWidth
                variant="standard"
                value={otherToPayDetail}
                required
                onChange={(event) => setOtherToPayDetail(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
            </>
          ) : formType === "Owner Expense" ? (
            <>
              <TextField
                margin="dense"
                id="expenseDetails"
                label="Details"
                type="text"
                fullWidth
                variant="standard"
                value={expenseDetails}
                required
                onChange={(event) => setExpenseDetails(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseAmount"
                label="Price"
                type="number"
                fullWidth
                variant="standard"
                value={expenseAmount}
                required
                onChange={(event) => setExpenseAmount(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </>
          ) : (
            <></>
          )}
          {/* Client Create Form */}
          {formType === "Client" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                autoFocus
                margin="dense"
                value={userName}
                id="name"
                label="Username"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setUserName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="phone"
                value={phoneNumber}
                label="Phone No"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <TextField
                margin="dense"
                id="address"
                value={address}
                label="Address"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setAddress(event.target.value)}
              />
              <TextField
                margin="dense"
                id="bankAccount"
                value={bankAccount}
                label="Bank Account"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setBankAccount(event.target.value)}
              />
              <TextField
                margin="dense"
                id="bankAccountOwner"
                value={bankAccountOwner}
                label="Bank Account Owner"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setBankAccountOwner(event.target.value)}
              />
              <TextField
                margin="dense"
                id="contactPerson"
                value={contactPerson}
                label="Contact Person"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setContactPerson(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* City Form */}
          {formType === "City" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                autoFocus
                margin="dense"
                id="cityName"
                value={selectedCity}
                label="City Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setSelectedCity(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Township Form */}
          {formType === "Price" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                disablePortal
                id="city-combo-box"
                value={selectedCity}
                options={cityData}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => [setSelectedCity(value)]}
              />
              <TextField
                required={true}
                autoFocus
                margin="dense"
                id="townshipName"
                value={townshipName}
                label="Township Name"
                type="text"
                variant="standard"
                onChange={(event, value) => setTownshipName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="deliPrice"
                value={deliPrice}
                label="Deli Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliPrice(event.target.value)}
              />
              <TextField
                margin="dense"
                id="expenseFees"
                value={expenseFees}
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Gate Form */}
          {formType === "Gate" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                autoFocus
                margin="dense"
                id="gate"
                value={gateName}
                label="Gate Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setGateName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="gate"
                value={gatePrice}
                label="Gate Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setGatePrice(event.target.value)}
              />
              <TextField
                margin="dense"
                id="expense_fees"
                value={expenseFees}
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
              />
              <Autocomplete
                disablePortal
                fullWidth
                value={selectedDeliUsername}
                options={deliUserName}
                onSelect={(e) => setSelectedDeliUsername(e.target.value)}
                renderInput={(params) => <TextField {...params} label="Deli" />}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Deli Price Form */}
          {formType === "Deli Price" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                disablePortal
                id="city-combo-box"
                options={cityData}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => setSelectedCity(value)}
              />
              <Autocomplete
                disablePortal
                id="townshipName"
                value={selectedTownship}
                options={townshipData}
                renderInput={(params) => (
                  <TextField {...params} label="Township" />
                )}
                onChange={(event, value) => setSelectedTownship(value)}
              />
              <TextField
                margin="dense"
                id="deliPrice"
                value={deliPrice}
                label="Deli Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliPrice(event.target.value)}
              />
              <TextField
                margin="dense"
                id="expenseFees"
                value={expenseFees}
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Client Order Form */}
          {formType === "Client Order" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                disablePortal
                id="clientName"
                value={selectedClient}
                options={clientData}
                renderInput={(params) => (
                  <TextField {...params} label="Client" />
                )}
                onChange={(event, value) => setSelectedClient(value)}
              />
              <TextField
                margin="dense"
                id="itemQuantity"
                value={noWays}
                label="Item Quantity"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setNoWays(event.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Pickup Date"
                  inputFormat="dd/MM/yyyy"
                  value={pickupDate}
                  onChange={handlePickupDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <TextField
                margin="dense"
                id="orderTotalAmount"
                value={orderTotalAmount}
                label="Order Total Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderTotalAmount(event.target.value)}
              />
              <TextField
                margin="dense"
                id="gateAmount"
                value={gateAmount}
                label="Gate Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setGateAmount(event.target.value)}
              />
              <TextField
                margin="dense"
                id="allPaidAmount"
                value={allPaidAmount}
                label="All Paid Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setAllPaidAmount(event.target.value)}
              />
              <Autocomplete
                disablePortal
                id="clientPayType"
                value={clientPayType}
                options={[{ label: "Prepaid" }, { label: "Debit" }]}
                renderInput={(params) => (
                  <TextField {...params} label="Client Pay Type" />
                )}
                onChange={(event, value) => setClientPayType(value.label)}
              />
              <Autocomplete
                disablePortal
                id="checkStatus"
                value={checkStatus}
                options={checkStatusList}
                renderInput={(params) => (
                  <TextField {...params} label="Check Status" />
                )}
                onChange={(event, value) => {
                  setCheckStatus(value || "");
                }}
              />

              {/* <Autocomplete
                disablePortal
                id="paymentStatus"
                value={paymentStatus}
                options={[{ label: "Pending" }, { label: "Done" }]}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Status" />
                )}
                onChange={(event, value) => setPaymentStatus(value.label)}
              /> */}
              <TextField
                margin="dense"
                id="remark"
                value={remark}
                label="Order Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setRemark(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Collect&Assign Order Form */}
          {formType === "Collect&Assign Order" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                margin="dense"
                id="receiverName"
                value={receiverName}
                label="Receiver Name"
                type="text"
                variant="standard"
                onChange={(event) => setReceiverName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="receiverPhoneNumber"
                value={receiverPhoneNumber}
                label="Receiver Phone Number"
                type="text"
                variant="standard"
                onChange={(event) => setReceiverPhoneNumber(event.target.value)}
              />
              <TextField
                margin="dense"
                id="receiverAddress"
                value={receiverAddress}
                label="Receiver Address"
                type="text"
                variant="standard"
                onChange={(event) => setReceiverAddress(event.target.value)}
              />
              <TextField
                margin="dense"
                id="itemName"
                value={itemName}
                label="Item Name"
                type="text"
                variant="standard"
                onChange={(event) => setItemName(event.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Assign Date"
                  inputFormat="dd/MM/yyyy"
                  value={assignDate}
                  onChange={handleAssignDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Autocomplete
                disablePortal
                id="city-combo-box"
                value={selectedCity}
                options={cityData}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => [
                  setSelectedCity(value),
                  Get_DeliExpense_Fees(
                    value,
                    null,
                    setDeliFees,
                    setExpenseFees
                  ),
                  setOrderTotalAmount(
                    parseInt(orderItemAmount) + parseInt(deliFees)
                  ),
                  //Get_Deli_Account(value,null,localStorage.getItem("business_Name"),setDeliUserName,setSelectedDeliUsername)
                ]}
              />
              <Autocomplete
                disablePortal
                id="townshipName"
                value={selectedTownship}
                options={townshipData}
                renderInput={(params) => (
                  <TextField {...params} label="Township" />
                )}
                onChange={(event, value) => [
                  setSelectedTownship(value),
                  Get_DeliExpense_Fees(
                    selectedCity,
                    value,
                    setDeliFees,
                    setExpenseFees,
                    setOrderTotalAmount,
                    orderItemAmount
                  ),
                  //Get_Deli_Account(null,value,localStorage.getItem("business_Name"),setDeliUserName,setSelectedDeliUsername)
                ]}
              />
              <Autocomplete
                disablePortal
                id="gateName"
                value={selectedGate}
                options={gateList}
                renderInput={(params) => <TextField {...params} label="Gate" />}
                onChange={(event, value) => [
                  setSelectedGate(value),
                  Get_Gate_Price_By_Gate_Name(
                    value,
                    setGatePrice,
                    setExpenseFees
                  ),
                ]}
              />
              {selectedGate != null && selectedGate != "" ? (
                <TextField
                  margin="dense"
                  id="gatePrice"
                  value={gatePrice}
                  label="Gate Price"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) => setGatePrice(event.target.value)}
                />
              ) : (
                <></>
              )}
              <Autocomplete
                disablePortal
                id="receiverPayType"
                value={receiverPayment}
                options={receiverPayType}
                renderInput={(params) => (
                  <TextField {...params} label="Receiver Pay Type" />
                )}
                onChange={(event, value) => handleReceiverPayType(value.label)}
              />
              {receiverPayment === "UnPaid" ||
              receiverPayment === "Deli Free" ? (
                <TextField
                  margin="dense"
                  id="itemPrice"
                  value={orderItemAmount}
                  label="Item Price"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) => [
                    setOrderItemAmount(event.target.value),
                    setOrderTotalAmount(
                      parseInt(event.target.value) + parseInt(deliPrice)
                    ),
                  ]}
                />
              ) : (
                <></>
              )}
              <TextField
                margin="dense"
                id="deliPrice"
                value={deliPrice}
                label="Deli Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliPrice(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseFees"
                value={expenseFees}
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <Autocomplete
                disablePortal
                id="deliName"
                value={selectedDeliUsername}
                options={deliUserName}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Name" />
                )}
                onChange={(event, value) => setSelectedDeliUsername(value)}
              />
              {receiverPayment !== "" && receiverPayment != null ? (
                <>
                  <TextField
                    margin="dense"
                    id="totalAmount"
                    value={orderTotalAmount}
                    label="Total Amount"
                    type="number"
                    fullWidth
                    variant="standard"
                    onChange={(event) =>
                      setOrderTotalAmount(event.target.value)
                    }
                  />
                </>
              ) : (
                <></>
              )}
              <TextField
                margin="dense"
                id="orderRemark"
                value={remark}
                label="Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setRemark(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <Autocomplete
                disablePortal
                id="expenseStatus"
                value={expenseStatus}
                options={[{ label: "UnPaid" }, { label: "Paid" }]}
                renderInput={(params) => (
                  <TextField {...params} label="Expense Status" />
                )}
                onChange={(event, value) => setExpenseStatus(value.label)}
              />
              <Autocomplete
                disablePortal
                id="expenseStatus"
                value={paymentStatus}
                options={[{ label: "Pending" }, { label: "Done" }]}
                renderInput={(params) => (
                  <TextField {...params} label="Payment Status" />
                )}
                onChange={(event, value) => setPaymentStatus(value.label)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Deli Ways Form */}
          {formType === "Deli Ways" ? (
            <Stack direction="column" spacing={2}>
              <Autocomplete
                margin="dense"
                disablePortal
                value={selectedDeliUsername}
                id="deliUsername"
                options={deliUserName}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Men" />
                )}
                onChange={(event, value) => setSelectedDeliUsername(value)}
              />
              <Autocomplete
                margin="dense"
                disablePortal
                valeu={deliStatus}
                id="deliStatus"
                value={deliStatus}
                options={[
                  { label: "Pending" },
                  { label: "OnWay" },
                  { label: "Assigned" },
                  { label: "Completed" },
                  { label: "Accepted" },
                  { label: "Instock" },
                  { label: "Rejected" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Status" />
                )}
                onChange={(event, value) => setDeliStatus(value.label)}
              />
              <TextField
                margin="dense"
                id="receiverName"
                value={receiverName}
                label="Receiver Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setReceiverName(event.target.value)}
              />
              <Autocomplete
                margin="dense"
                disablePortal
                id="city"
                value={selectedCity}
                options={cityData}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => setSelectedCity(value)}
              />
              <Autocomplete
                margin="dense"
                disablePortal
                id="city"
                value={selectedTownship}
                options={townshipData}
                renderInput={(params) => (
                  <TextField {...params} label="Township" />
                )}
                onChange={(event, value) => setSelectedTownship(value)}
              />
              <Autocomplete
                margin="dense"
                disablePortal
                id="gate"
                value={gateName}
                options={gateList}
                renderInput={(params) => <TextField {...params} label="Gate" />}
                onChange={(event, value) => [
                  setGateName(value),
                  Get_Gate_Price_By_Gate_Name(
                    value,
                    setGatePrice,
                    setDeliPrice
                  ),
                ]}
              />
              <TextField
                margin="dense"
                id="gatePrice"
                //value={gatePrice}
                label="Gate Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setGatePrice(event.target.value)}
              />
              {/* <TextField
                margin="dense"
                id="orderItemPrice"
                value={orderItemPrice}
                label="Item Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderItemPrice(event.target.value)}
              /> */}
              <TextField
                margin="dense"
                id="deliPrice"
                value={deliPrice}
                label="Deli Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliPrice(event.target.value)}
              />
              <Autocomplete
                margin="dense"
                disablePortal
                id="deliType"
                value={deliType}
                options={[
                  { label: "All Paid" },
                  { label: "Item Paid" },
                  { label: "No Deli" },
                  { label: "Return Item" },
                  // { label: "Half Paid" },
                  { label: "Transfer" },
                  { label: "Tan Sar" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Type" />
                )}
                onChange={(event, value) => setDeliType(value.label)}
              />
              {deliType === "Return Item" ? (
                <TextField
                  margin="dense"
                  id="returnOrHalfAmount"
                  value={returnOrHalfAmount}
                  label="Return Amount"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) =>
                    setReturnOrHalfAmount(event.target.value)
                  }
                />
              ) : (
                <></>
              )}
              {/* <TextField
                margin="dense"
                id="orderTotalAmount"
                value={orderTotalAmount}
                label="Total Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderTotalAmount(event.target.value)}
              /> */}
              {/* <Autocomplete
                margin="dense"
                disablePortal
                id="expenseStatus"
                value={expenseStatus}
                options={[
                  { label: "UnPaid" },
                  { label: "Paid" },
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Expense Status" />
                )}
                onChange={(event, value) => setExpenseStatus(value.label)}
              /> */}
              {deliType === "Tan Sar" ? (
                <TextField
                  margin="dense"
                  id="tanSarPrice"
                  value={tanSarPrice}
                  label="Tan Sar Price"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) => setTanSarPrice(event.target.value)}
                />
              ) : (
                <></>
              )}
              {deliType === "Transfer" ? (
                <TextField
                  margin="dense"
                  id="halfPaidAmount"
                  // value={halfPaidAmount}
                  label="Transfer Amount"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) => setHalfPaidAmount(event.target.value)}
                />
              ) : (
                <></>
              )}
              <TextField
                margin="dense"
                id="deliMenRemark"
                value={deliMenRemark}
                label="Deli Men Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliMenRemark(event.target.value)}
              />
              <TextField
                margin="dense"
                id="assignRemark"
                value={assignRemark}
                label="Assign Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setAssignRemark(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Deli Men Form */}
          {formType === "Deli Men" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                margin="dense"
                disablePortal
                id="deliStatus"
                value={deliStatus}
                options={deliStatusOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Status" />
                )}
                onChange={(event, value) => [
                  setDeliStatus(value),
                  value.label === "OnWay"
                    ? setDeliType(currentData.Deli_Type)
                    : "",
                ]}
              />
              {deliStatus === "Completed" ? (
                <Autocomplete
                  margin="dense"
                  disablePortal
                  id="deliType"
                  // value={deliType==="UnPaid"?["Cash",setDeliType("Cash")]:deliType}
                  value={deliType}
                  options={[
                    { label: "Cash" },
                    { label: "Tan Sar" },
                    { label: "All Paid" },
                    // { label: "Item Paid" },
                    { label: "No Deli" },
                    { label: "Return Item" },
                    // { label: "Half Paid" },
                    { label: "Transfer" },
                  ]}
                  renderInput={(params) => (
                    <TextField {...params} label="Deli Type" />
                  )}
                  onChange={(event, value) => [
                    setDeliType(value.label),
                    value.label === "Tan Sar"
                      ? setShowTanSarPrice(true)
                      : setShowTanSarPrice(false),
                  ]}
                />
              ) : (
                <></>
              )}
              {deliType === "Return Item" ||
              (deliType === "Transfer" && deliStatus === "Completed") ? (
                <TextField
                  margin="dense"
                  id="returnOrHalfAmount"
                  value={returnOrHalfAmount || ""}
                  label="Amount"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) =>
                    setReturnOrHalfAmount(parseInt(event.target.value))
                  }
                />
              ) : (
                <></>
              )}
              {showTanSarPrice && deliStatus === "Completed" && (
                <TextField
                  margin="dense"
                  id="tanSarPrice"
                  label="Tan Sar Price"
                  type="number"
                  value={tanSarPrice || ""}
                  fullWidth
                  variant="standard"
                  onChange={(event) =>
                    setTanSarPrice(parseInt(event.target.value))
                  }
                />
              )}
              {currentData.Deli_Status !== "OnWay" && (
                <TextField
                  margin="dense"
                  id="remark"
                  value={remark}
                  label="Remark"
                  type="text"
                  fullWidth
                  required
                  variant="standard"
                  onChange={(event) => setRemark(event.target.value)}
                />
              )}
            </Stack>
          ) : formType === "In Stock" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                margin="dense"
                disablePortal
                id="deliStatus"
                value={deliStatus}
                options={[{ label: "Assigned" }, { label: "Rejected" }]}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Status" />
                )}
                onChange={(event, value) => [
                  setDeliStatus(value.label),
                  value.label === "OnWay"
                    ? setDeliType(currentData.Deli_Type)
                    : "",
                ]}
              />
              {deliStatus === "Assigned" ? (
                <Autocomplete
                  margin="dense"
                  disablePortal
                  id="deliMen"
                  // value={deliType==="UnPaid"?["Cash",setDeliType("Cash")]:deliType}
                  value={selectedDeliUsername}
                  options={deliMen}
                  renderInput={(params) => (
                    <TextField {...params} label="Deli Man" />
                  )}
                  onSelect={(e) => setSelectedDeliUsername(e.target.value)}
                />
              ) : (
                <></>
              )}
              {/* <TextField
               margin="dense"
               id="remark"
               value={remark}
               label="Remark"
               type="text"
               fullWidth
               variant="standard"
               onChange={(event) => setRemark(event.target.value)}
             /> */}
            </Stack>
          ) : formType === "General Expense" ? (
            <>
              <TextField
                margin="dense"
                id="expenseName"
                label="General Expense Name"
                type="text"
                fullWidth
                variant="standard"
                value={expenseName}
                required
                onChange={(event) => setExpenseName(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseDetails"
                label="Details"
                type="text"
                fullWidth
                variant="standard"
                value={expenseDetails}
                required
                onChange={(event) => setExpenseDetails(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseAmount"
                label="Price"
                type="number"
                fullWidth
                variant="standard"
                value={expenseAmount}
                required
                onChange={(event) => setExpenseAmount(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseDate"
                label="Expense Date"
                type="text"
                fullWidth
                variant="standard"
                value={expenseDate}
                required
                onChange={(event) => setExpenseDate(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </>
          ) : formType === "OS Pending" ? (
            <>
              <Autocomplete
                margin="dense"
                disablePortal
                id="deliMen"
                onSelect={(event) =>
                  setSelectedDeliUsername(event.target.value)
                }
                // value={deliType==="UnPaid"?["Cash",setDeliType("Cash")]:deliType}
                value={selectedDeliUsername}
                options={deliMen}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Man" />
                )}
              />
              <br />
              <Autocomplete
                margin="dense"
                disablePortal
                id="deliStatus"
                onSelect={(event) => setDeliStatus(event.target.value)}
                // value={deliType==="UnPaid"?["Cash",setDeliType("Cash")]:deliType}
                value={deliStatus}
                options={deliStatusOptions}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Status" />
                )}
              />
            </>
          ) : formType === "Debit ToPay" ? (
            <TextField
              margin="dense"
              id="expenseAmount"
              label="Paid Amount"
              type="number"
              fullWidth
              variant="standard"
              // value={toPayAmount}
              required
              onChange={(event) => setToPayAmount(event.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <br />
          <br />
          <Button onClick={() => setShowEditForm(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingIndicator="Updating..."
            onClick={() => [handleSubmitForm()]}
          >
            {buttonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
