import { makeStyles } from "@material-ui/styles";
import Theme from "../../../../theme/Theme";
const AccountingManagementStyle={
  boxStyle: {
    boxShadow: 3,
    bgcolor: "background.paper",
    mx: "auto",
    display: "flex",
    flex: 1,
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 1,
    width:"auto",
    height: "7rem",
  },
  subHeading: {
    color: Theme.palette.primary.main,
    fontWeight: "bold",
  },
}
export default AccountingManagementStyle;