import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  CssBaseline,
  ThemeProvider,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AccountingManagementStyle from "../accounting/AccountingManagementStyle";
import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import Theme from "../../../../theme/Theme";
import DataTableComponent from "../../../../components/DataTable";
import EditFormComponent from "../../../../components/EditForm";
import Get_Ways from "../../../../api/Way/GetWayController";
import Delete_Ways from "../../../../api/Way/DeleteWayController";
import Get_Deli_Account from "../../../../api/Account/GetDeliAccountController";
import Accepted_Way_By_Deli_Men from "../../../../api/Way/ApprovedWayByDeliMenController";
import Assigned_Way_By_Deli_Men from "../../../../api/Way/AssignedWayByDeliMenController";
import Get_NoDeli_Data from "../../../../api/Way/NoDeliMen/GetNoDeliController";
import Get_CityTownship_Data from "../../../../api/Way/CityTownship/GetCityTownshipController";
import All_Complete_Ways_By_Admin from "../../../../api/Way/All_Complete_Ways_By_AdminController";
import Get_AllPaid_Transfer from "../../../../api/Way/GetAllPaidTransfer";
import ToastContainerComponent from "../../../../components/ToastContainer";

export default function WayManagePage() {
  const [tableData, setTableData] = React.useState([]);
  // const[showEditForm,setShowEditForm]=React.useState(false);
  const [showData, setShowData] = React.useState(false);
  // const[currentData,setCurrentData]=React.useState({});
  const [selectedCategory, setSelectedCategory] = React.useState("Assigned");
  const [selectedDeliType, setSelectedDeliType] = React.useState("All");
  const [citySelectedCategory, setCitySelectedCategory] = useState("Pending");
  const [townshipSelectedCategory, setTownshipSelectedCategory] =
    useState("Assigned");
  //const [deliUserName, setDeliUserName] = React.useState(["All", "NoDeli", "Township", "City"]);
  const [deliUserName, setDeliUserName] = React.useState([
    "All",
    "Township",
    "City",
  ]);
  const [selectedDeliUsername, setSelectedDeliUsername] = React.useState("");
  const [totalItemPrice, setTotalItemPrice] = React.useState(0);
  const [totalReturnItemAmount, setTotalReturnItemAmount] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [totalExpenseFees, setTotalExpenseFees] = React.useState(0);
  const [totalDeli, setTotalDeli] = React.useState(0);
  const [currentData, setCurrentData] = React.useState({});
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [assignFomDate, setAssignFromDate] = React.useState(null);
  const [assignToDate, setAssignToDate] = React.useState(null);
  const [totalAllPaidTransfer, setTotalAllPaidTransfer] = React.useState(0);
  const [edited, setEdited] = useState(false);
  const filterBtn = useRef(null);
  const [totalWays, setTotalWays] = useState(0);
  const [noDeliTotal, setNoDeliTotal] = useState(0);
  const showButton = useRef(null);
  const hideButton = useRef(null);
  const columns =
    selectedDeliUsername === "NoDeli"
      ? [
          {
            name: "SrNo",
          },
          {
            name: "CAO_ID",
            options: {
              display: false,
            },
          },
          "Client_Name",
          "Receiver_Name",
          "T_Name",
          "Total_Amount",
          {
            name: "Create_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                if (value === "1900-01-01T00:00:00" || value === null) {
                  return "";
                } else {
                  return moment(value).format("DD-MM-YYYY");
                }
              },
            },
          },
        ]
      : selectedDeliUsername === "City" || selectedDeliUsername === "Township"
      ? ["SrNo", "Deli_Men", "Total_Ways", "Item_Price"]
      : (selectedCategory == "Accepted" || selectedCategory == "Completed") &&
        selectedDeliUsername === "All"
      ? ["SrNo", "Deli_Men", { name: "Total_Amount", label: "Pay_Amount" }]
      : [
          {
            name: "CAO_ID",
            options: {
              display: false,
            },
          },
          {
            name: "SrNo",
            options: {
              display: false,
            },
          },
          {
            name: "Deli_Men",
            options: {
              display: deliUserName === "All" ? true : false,
            },
          },
          {
            name: "Client_Name",
            label: "Client",
          },
          {
            name: "Receiver_Name",
            label: "Receiver",
            options: {
              display:
                selectedDeliUsername !== "City" &&
                selectedDeliUsername != "Township",
            },
          },
          {
            name: "C_Name",
            label: "City",
            options: { display: selectedDeliUsername !== "Township" },
          },
          {
            name: "T_Name",
            label: "Township",
            options: { display: selectedDeliUsername !== "City" },
          },
          {
            name: "G_Name",
            label: "Gate",
            options: {
              display: true,
            },
          },
          {
            name: "G_Price",
            label: "Gate_Price",
            options: {
              display: false,
            },
          },
          {
            name: "Expense_Fees",
            label: "Deli_Fees",
            options: {
              display: false,
              customBodyRender: function (value, tableMeta, updateValue) {
                return value;
              },
            },
          },
          "Tan_Sar_Price",
          {
            name: "Deli_Type",
            options: {
              display:
                selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township",
            },
          },
          "Deli_Price",
          {
            name: "Return_Item_Amount",
            label: "Return Amount",
            options: {
              display: selectedDeliType === "Return Item" ? true : false,
            },
          },
          {
            name: "Total_Amount",
            label: "Total",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                return value;
              },
            },
          },
          {
            name: "Deli_Status",
            options: {
              display: selectedCategory === "All",
            },
          },
          {
            name: "Expense_Status",
            options: {
              display: false,
            },
          },
          {
            name: "Deli_Men_Remark",
            label: "Remark",
            options: {
              display:
                selectedDeliUsername !== "Township" &&
                selectedDeliUsername !== "City",
            },
          },
          {
            name: "Create_Date",
            label: "Assign_Date",
            options: {
              display:
                selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township" &&
                selectedCategory !== "Pending",
              customBodyRender: function (value, tableMeta, updateValue) {
                if (
                  value === null ||
                  value === "" ||
                  value === "1/1/1900 12:00:00 AM"
                ) {
                  return "";
                } else {
                  var assign_date = moment(value).format("DD-MM-YYYY");
                  return assign_date;
                }
              },
            },
          },
          {
            name: "Pending_Date",
            label: "Pending_Date",
            options: {
              display:
                selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township",
              customBodyRender: function (value, tableMeta, updateValue) {
                if (
                  value === null ||
                  value === "" ||
                  value === "1/1/1900 12:00:00 AM"
                ) {
                  return "";
                } else {
                  var assign_date = moment(value).format("DD-MM-YYYY");
                  return assign_date;
                }
              },
            },
          },
          {
            name: "Assign_Remark",
            options: {
              display: true,
            },
          },
          {
            name: "Accept_Date",
            options: {
              display:
                selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township",
              customBodyRender: function (value, tableMeta, updateValue) {
                if (
                  value === null ||
                  value === "" ||
                  value === "1/1/1900 12:00:00 AM"
                ) {
                  return "";
                } else {
                  var accept_date = moment(value).format("DD-MM-YYYY");
                  return accept_date;
                }
              },
            },
          },
          {
            name: "Complete_Date",
            options: {
              display:
                selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township",
              customBodyRender: function (value, tableMeta, updateValue) {
                if (
                  value === null ||
                  value === "" ||
                  value === "1/1/1900 12:00:00 AM"
                ) {
                  return "";
                } else {
                  const parsedDate = moment(value, "M/D/YYYY h:mm:ssA");
                  const formattedDate = parsedDate.format("DD-MM-YYYY");
                  return formattedDate;
                }
              },
            },
          },

          { name: "Item_Price", options: { display: false } },
          {
            name: "Update_Date",
            options: {
              display: false,
              customBodyRender: function (value, tableMeta, updateValue) {
                return moment(value).format("DD-MM-YYYY");
              },
            },
          },
          {
            name: "\n\n\n\n\n\nACTION",
            options: {
              display: selectedCategory !== "Accepted",
              setCellProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  right: "0",
                  background: "white",
                  zIndex: 100,
                },
              }),
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  right: 0,
                  background: "white",
                  zIndex: 101,
                },
              }),
              customBodyRenderLite: (
                dataIndex,
                value,
                tableMeta,
                updateValue
              ) => {
                return (
                  <Stack direction="row" spacing={0}>
                    <Tooltip title="Edit">
                      <IconButton
                        tooltip="Edit"
                        color="primary"
                        aria-label="edit"
                        disabled={
                          tableData[dataIndex].Deli_Status == "Accepted"
                        }
                        onClick={() => UpdateWay(dataIndex)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <IconButton
                        tooltip="Delete"
                        color="error"
                        aria-label="delete"
                        disabled={
                          tableData[dataIndex].Deli_Status == "Accepted"
                        }
                        onClick={() => DeleteWay(dataIndex)}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                );
              },
            },
          },
        ];
  const handleAssignFromDateChange = (newValue) => {
    setAssignFromDate(newValue);
  };
  const handleAssignToDateChange = (newValue) => {
    setAssignToDate(newValue);
  };
  const handleFromDateChange = (newValue) => {
    setFromDate(newValue);
  };
  const handleToDateChange = (newValue) => {
    setToDate(newValue);
  };
  // const handleTableData = () => {
  //   if (showData === false) {
  //     if (selectedCategory !== "") {
  //       setShowData(true);
  //       setShowData(true);
  //       Get_Ways(selectedCategory, setTableData);
  //     } else {
  //       alert("Please Select Deli Status");
  //     }
  //   } else {
  //     setShowData(false);
  //   }
  // };
  const handleApplyFilter = () => {
    if (selectedDeliUsername === "NoDeli") {
      Get_NoDeli_Data(setTableData, setNoDeliTotal, setShowData);
    } else if (
      selectedDeliUsername === "Township" ||
      selectedDeliUsername === "City"
    ) {
      var from_date = null,
        to_date = null;
      //(townshipSelectedCategory==="Accepted" || citySelectedCategory==="Accepted")?[,to_date=moment(toDate).format("YYYY-MM-DD")]:console.log("");
      if (
        townshipSelectedCategory === "Accepted" ||
        citySelectedCategory === "Accepted"
      ) {
        from_date = moment(fromDate).format("YYYY-MM-DD");
        to_date = moment(toDate).format("YYYY-MM-DD");
      }
      Get_CityTownship_Data(
        selectedDeliUsername,
        selectedDeliUsername === "Township"
          ? townshipSelectedCategory
          : citySelectedCategory,
        setTableData,
        setShowData,
        setTotalWays,
        setTotalExpenseFees,
        setTotalItemPrice,
        setTotalAmount,
        from_date,
        to_date
      );
    } else {
      if (selectedDeliUsername !== "" && selectedCategory !== "") {
        setShowData(false);
        var from_date, to_date;
        if (selectedCategory === "Accepted") {
          from_date = moment(fromDate).format("YYYY-MM-DD");
          to_date = moment(toDate).format("YYYY-MM-DD");
        } else if (
          selectedCategory === "Assigned" &&
          assignFomDate !== null &&
          assignToDate !== null
        ) {
          from_date = moment(assignFomDate).format("YYYY-MM-DD");
          to_date = moment(assignToDate).format("YYYY-MM-DD");
        } else {
          from_date = null;
          to_date = null;
        }
        //if selected deli status is completed then we have to call GetAllPaidTransfer API to show the value at Transfer Amount Card
        if (selectedCategory === "Completed") {
          console.log("Calling GetAllPaidTransferApi");
          Get_AllPaid_Transfer(selectedDeliUsername, setTotalAllPaidTransfer);
        }
        Get_Ways(
          setTotalWays,
          selectedDeliUsername,
          selectedCategory,
          selectedDeliType,
          setTableData,
          setTotalReturnItemAmount,
          setTotalItemPrice,
          setTotalAmount,
          setTotalExpenseFees,
          setTotalDeli,
          setShowData,
          tableData,
          from_date,
          to_date
        );
      } else {
        alert("Please choose deli men and deli status");
      }
    }
  };
  const handleAcceptWays = () => {
    Accepted_Way_By_Deli_Men(selectedDeliUsername);
  };
  const handleCompleteWays = () => {
    All_Complete_Ways_By_Admin(selectedDeliUsername, {
      showButton,
      hideButton,
    });
  };
  const handleAssignWays = () => {
    Assigned_Way_By_Deli_Men(selectedDeliUsername);
  };
  const UpdateWay = (dataIndex) => {
    console.log("Return Amount", tableData[dataIndex]["Return_Item_Amount"]);
    console.log("Return Amount", tableData[dataIndex]["Return Amount"]);
    setCurrentData({
      CAO_ID: tableData[dataIndex]["CAO_ID"],
      Deli_Men: tableData[dataIndex]["Deli_Men"],
      Receiver_Name: tableData[dataIndex]["Receiver_Name"],
      C_Name: tableData[dataIndex]["C_Name"],
      T_Name: tableData[dataIndex]["T_Name"],
      Tan_Sar_Price: tableData[dataIndex]["Tan_Sar_Price"],
      G_Name: tableData[dataIndex]["G_Name"],
      G_Price: tableData[dataIndex]["G_Price"],
      Item_Price: tableData[dataIndex]["Item_Price"],
      Deli_Price: tableData[dataIndex]["Deli_Price"],
      Expense_Fees: tableData[dataIndex]["Expense_Fees"],
      Deli_Type: tableData[dataIndex]["Deli_Type"],
      Deli_Status: tableData[dataIndex]["Deli_Status"],
      Total_Amount: tableData[dataIndex]["Total_Amount"],
      Expense_Status: tableData[dataIndex]["Expense_Status"],
      Deli_Men_Remark: tableData[dataIndex]["Deli_Men_Remark"],
      Assign_Remark: tableData[dataIndex]["Assign_Remark"],
      Half_Paid_Amount: tableData[dataIndex]["Return_Item_Amount"],
      filterBtn: filterBtn.current,
      Show_Btn: showButton,
      Hide_Btn: hideButton,
    });
    setShowEditForm(true);
  };
  const DeleteWay = (dataIndex) => {
    var cao_id = tableData[dataIndex]["CAO_ID"];
    Delete_Ways(cao_id);
  };
  useEffect(() => {
    //getting list of deli men account name
    Get_Deli_Account(
      null,
      null,
      localStorage.getItem("business_Name"),
      setDeliUserName,
      deliUserName,
      setSelectedDeliUsername
    );
  }, []);
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Stack direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <Autocomplete
              disablePortal
              id="deliMen"
              fullWidth
              options={deliUserName}
              onChange={(event, value) => {
                setShowData(false);
                setSelectedDeliUsername(value);
                console.log("Deli Username", value);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Deli Men" />
              )}
            />
          </Grid>

          {selectedDeliUsername === "Township" && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  disablePortal
                  id="dataCategory"
                  fullWidth
                  options={[
                    { label: "Pending" },
                    { label: "Assigned" },
                    { label: "OnWay" },
                  ]}
                  value={townshipSelectedCategory}
                  onChange={(event, value) => {
                    setTownshipSelectedCategory(value?.label || "");
                    setShowData(false);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Deli Status" />
                  )}
                />
              </Grid>

              {townshipSelectedCategory === "Accepted" ? (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="From Date"
                        inputFormat="dd-MM-yyyy"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="To Date"
                        inputFormat="dd-MM-yyyy"
                        value={toDate}
                        onChange={handleToDateChange}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {selectedDeliUsername === "City" && (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <Autocomplete
                  disablePortal
                  id="dataCategory"
                  fullWidth
                  options={[
                    { label: "Pending" },
                    { label: "Assigned" },
                    { label: "OnWay" },
                  ]}
                  value={citySelectedCategory}
                  onChange={(event, value) => {
                    setCitySelectedCategory(value?.label || "");
                    setShowData(false);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Deli Status" />
                  )}
                />
              </Grid>

              {citySelectedCategory === "Accepted" ? (
                <>
                  <Grid item xs={12} sm={6} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="From Date"
                        inputFormat="dd-MM-yyyy"
                        value={fromDate}
                        onChange={handleFromDateChange}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <MobileDatePicker
                        label="To Date"
                        inputFormat="dd-MM-yyyy"
                        value={toDate}
                        onChange={handleToDateChange}
                        renderInput={(params) => (
                          <TextField fullWidth {...params} />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {selectedDeliUsername !== "" &&
            selectedDeliUsername !== "NoDeli" &&
            selectedDeliUsername !== "Township" &&
            selectedDeliUsername !== "City" && (
              <>
                <Grid item xs={12} sm={6} md={3}>
                  <Autocomplete
                    disablePortal
                    id="dataCategory"
                    fullWidth
                    options={[
                      { label: "All" },
                      { label: "Pending" },
                      { label: "Assigned" },
                      { label: "Completed" },
                      { label: "Accepted" },
                      { label: "OnWay" },
                      { label: "Rejected" },
                    ]}
                    value={selectedCategory}
                    onChange={(event, value) => {
                      setSelectedCategory(value?.label || "");
                      setShowData(false);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Deli Status" />
                    )}
                  />
                </Grid>

                {selectedCategory === "Assigned" ? (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="From Date"
                          inputFormat="dd-MM-yyyy"
                          value={assignFomDate}
                          onChange={handleAssignFromDateChange}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="To Date"
                          inputFormat="dd-MM-yyyy"
                          value={assignToDate}
                          onChange={handleAssignToDateChange}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
                {selectedCategory === "All" && (
                  <Grid item xs={12} sm={6} md={3}>
                    <Autocomplete
                      disablePortal
                      id="deliTypeCategory"
                      fullWidth
                      options={[{ label: "Return Item" }, { label: "All" }]}
                      value={selectedDeliType}
                      onChange={(event, value) => {
                        setSelectedDeliType(value?.label || "");
                        setShowData(false);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Deli Type" />
                      )}
                    />
                  </Grid>
                )}
                {selectedCategory === "Accepted" ? (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="From Date"
                          inputFormat="dd-MM-yyyy"
                          value={fromDate}
                          onChange={handleFromDateChange}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          label="To Date"
                          inputFormat="dd-MM-yyyy"
                          value={toDate}
                          onChange={handleToDateChange}
                          renderInput={(params) => (
                            <TextField fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          <Grid item xs={12} sm={6} md={3} sx={{ mt: 1.5 }}>
            {selectedDeliUsername !== "" && selectedCategory !== "" ? (
              <Button
                variant="contained"
                ref={filterBtn}
                onClick={() => handleApplyFilter()}
              >
                Apply Filter
              </Button>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <br />
        {showEditForm ? (
          <EditFormComponent
            headerText="Edit Deli Ways ✏️"
            formType="Deli Ways"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
            setTableData={setTableData}
            tableData={tableData}
          />
        ) : (
          <></>
        )}
      </Stack>
      <br />
      {showData ? (
        <Stack direction="column" spacing={2}>
          <Button
            sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
            endIcon={<KeyboardArrowDown />}
            ref={hideButton}
            onClick={() => setShowData(false)}
          >
            Hide Data
          </Button>
          {showData && selectedDeliUsername !== "NoDeli" ? (
            <Stack direction="row">
              <Grid container spacing={2}>
                {/* Total Expense Fees */}
                {selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township" ? (
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {totalExpenseFees.toLocaleString()}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Total Expense
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
                {/* Total Amount */}
                {selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township" ? (
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {totalAmount.toLocaleString()}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Total Amount
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
                {/* To Pay Amount (Total Amount-Total Deli Fees) */}
                {selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township" ? (
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {(totalAmount - totalExpenseFees).toLocaleString()}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          To Pay Amount
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
                {/* All Paid TransferCard */}
                {selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township" ? (
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {totalAllPaidTransfer.toLocaleString()}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Transfer Amount
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
                {/* Total Item Amount */}
                <Grid item xs={6} md={3}>
                  <Box sx={AccountingManagementStyle.boxStyle}>
                    <Stack direction="column">
                      <Typography variant="h5">
                        {totalReturnItemAmount.toLocaleString()}
                      </Typography>
                      <Typography
                        variant="h8"
                        sx={AccountingManagementStyle.subHeading}
                      >
                        Return Amount
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box sx={AccountingManagementStyle.boxStyle}>
                    <Stack direction="column">
                      <Typography variant="h5">
                        {totalItemPrice.toLocaleString()}
                      </Typography>
                      <Typography
                        variant="h8"
                        sx={AccountingManagementStyle.subHeading}
                      >
                        Total Item Amount
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                {selectedDeliUsername !== "City" &&
                selectedDeliUsername !== "Township" ? (
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">{totalDeli}</Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Total Deli
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                ) : (
                  <></>
                )}
                <Grid item xs={6} md={3}>
                  <Box sx={AccountingManagementStyle.boxStyle}>
                    <Stack direction="column">
                      <Typography variant="h5">{totalWays}</Typography>
                      <Typography
                        variant="h8"
                        sx={AccountingManagementStyle.subHeading}
                      >
                        Ways
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                {/* Completed Ways Button */}
                {/* {(showData && selectedCategory==="Assigned" && localStorage.getItem("business_Name")==="ShweDelivery" || localStorage.getItem("business_Name")==="CKDelivery" || localStorage.getItem("business_Name")==="PostExpressMyanmar") ? (
              <Grid item xs={12} sm={6} md={3} sx={{mt:5,mb:5}}>
              <Button
                variant="contained"
                onClick={() => handleCompleteWays()}
              >
                Completed Ways
              </Button>
              </Grid>
            ) : (
              <></>
            )} */}
                {/* Accpet Ways Button */}
                {showData && selectedCategory === "Completed" ? (
                  <Grid item xs={12} sm={6} md={3} sx={{ mt: 5 }}>
                    <Button
                      variant="contained"
                      onClick={() => handleAcceptWays()}
                    >
                      Accept Ways
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
                {/* Assign Ways Button */}
                {showData && selectedCategory === "Pending" ? (
                  <Grid item xs={12} sm={6} md={3} sx={{ mt: 5 }}>
                    <Button
                      variant="contained"
                      onClick={() => handleAssignWays()}
                    >
                      Assign Ways
                    </Button>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </Stack>
          ) : (
            <></>
          )}
          {selectedDeliUsername == "NoDeli" && showData == true && (
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="h5">
                    {noDeliTotal.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Total Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          )}
          <DataTableComponent
            title={
              selectedDeliUsername === "NoDeli" ? "No DeliMen" : "Deli Ways"
            }
            data={tableData}
            columns={columns}
          />
        </Stack>
      ) : (
        <Button
          sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
          endIcon={<KeyboardArrowRight />}
          ref={showButton}
          onClick={() => handleApplyFilter()}
        >
          Show Data
        </Button>
      )}
    </ThemeProvider>
  );
}
