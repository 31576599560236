import axios from "axios";
import API_EndPoint from "../URL";
const Update_Way=async(updateData,setLoading,setShowEditForm, filterBtn,showBtn,hideBtn)=>{
    setLoading(true);
var api_endpoint=API_EndPoint.URL;
console.log(updateData);
await axios.put(api_endpoint+'manage/way',updateData,API_EndPoint.Config).then((res)=>{
    if(res.status===202){   
        setLoading(false);
        setShowEditForm(false);
        // hideBtn.current.click();
        // showBtn.current.click();
        alert("Way Updated");
        console.log(updateData);

        // refresh when assigned is changed to pending to show latest data for UX
        if (updateData.Deli_Status === "Pending") {
            filterBtn.click();
          }
    }
}).catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
    setLoading(false);
})
}
export default Update_Way;