import axios from "axios";
import API_EndPoint from "../../URL";
const Get_Gate_Price_By_Gate_Name=async(gate_name,setGatePrice,setExpenseFees)=>{
    console.log("Gate Name",gate_name);
    //handling first if gate name is null or ""
    if(gate_name===null || gate_name===""){
        setGatePrice(0);
    }
    else{
        var api_endpoint=API_EndPoint.URL;
        var business_name=localStorage.getItem("business_Name");
        await axios.get(api_endpoint+'data/gateprice?G_Name='+gate_name+'&Business_Name='+business_name,API_EndPoint.Config).then((res)=>{
            console.log("Gate Data",res.data);
            if(res.status===200){
                res.data.map(({G_Price,Expense_Fees})=>{
                    setGatePrice(G_Price);
                    Expense_Fees===null?setExpenseFees(0):setExpenseFees(Expense_Fees);
                })
            }
        }).catch((err)=>{
            if(err.response.status===401){
                // Toastify("warning","Session Expired",setShowToast);
                alert("Session Expired");
                localStorage.setItem("Login_Status",false);
                window.location.replace("/");
            }
            else{
            alert(err.response.data);
            }
        })
    }
}
export default Get_Gate_Price_By_Gate_Name;