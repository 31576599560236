import axios from "axios"
import API_EndPoint from "../../URL"

const Delete_Other_Income = async (id, {showButton, hideButton}) => {
    const confirm = window.confirm("Are you sure to delete?");
    if (confirm) {
        const business_Name = localStorage.getItem("business_Name");
        await axios.delete(API_EndPoint.URL + "manage/data/othertopay?OI_ID=" + id + "&Business_Name=" + business_Name,API_EndPoint.Config)
        .then(({data}) => {
            hideButton.current.click();
            setTimeout(() => {
                showButton.current.click();
            }, 100);
        })
        .catch((err)=>{
            if(err.response.status===401){
                // Toastify("warning","Session Expired",setShowToast);
                alert("Session Expired");
                localStorage.setItem("Login_Status",false);
                window.location.replace("/");
            }
            else{
                alert(err.response.data);
            }
        })
    }
}

export default Delete_Other_Income;