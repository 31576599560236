import React, { useEffect, useState, createRef } from "react";
import {
  Button,
  CssBaseline,
  ThemeProvider,
  Stack,
  IconButton,
  Tooltip,
  Autocomplete,
  TextField,
  Box,
  Typography,
  Grid,
  Skeleton,
  Card,
  Avatar,
  CardContent,
  CardActions,
  Divider,
} from "@mui/material";
import {
  Edit,
  Delete,
  PlaylistAdd,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  Payments,
  CheckCircle,
  Cancel,
} from "@mui/icons-material";
import { LocalizationProvider, MobileDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import axios from "axios";
import moment from "moment";
import Switch from "../../../../components/Switch";
import API_EndPoint from "../../../../api/URL";
import Theme from "../../../../theme/Theme";
import AccountingManagementStyle from "./AccountingManagementStyle";
import CreateFormComponent from "../../../../components/CreateForm";
import DataTableComponent from "../../../../components/DataTable";
import Get_Debit_Data from "../../../../api/Accounting/Debit/GetDebitListController";
import Check_Order_And_Collect_Amount from "../../../../api/Order/ClientOrder/GetTotalCollectOrderAmountController";
import ShowCheckFormComponent from "../../../../components/ShowCheckForm";
import EditFormComponent from "../../../../components/EditForm";
import Delete_Client_Order from "../../../../api/Order/ClientOrder/DeleteClientOrderController";
import Get_Total_Debit_Data from "../../../../api/Accounting/Debit/GetTotalDebitListController";
import logo from "../../../../assets/sevendaylogo.jpg";
import cklogo from "../../../../assets/cklogo.png";
import Get_All_Deli_Account from "../../../../api/InStock/GetAllDeliAccountController";
import Get_All_Clients from "../../../../api/Accounting/Debit/GetAllClientsController";
import Get_Expense_Data from "../../../../api/Accounting/Expense/GetExpenseListController";
import Get_Overall_Data from "../../../../api/Accounting/Debit/GetTotalOverallController";
import Get_Profit_Data from "../../../../api/Accounting/Profit/GetProfitController";
import Get_Rejected_Data from "../../../../api/Accounting/Debit/GetRejectedController";
import Get_WayCheck_Data from "../../../../api/Accounting/WayCheck/GetWayCheckController";
import Get_OsPending_Data from "../../../../api/Accounting/OsPending/GetOsPendingController";
import Get_Owner_Expense from "../../../../api/Accounting/Profit/GetOwnerExpenseController";
import GetOsReport from "../../../../api/Accounting/OS Report/GetOSReportController";
import Get_Return_Item_List from ".././../../../api/Accounting/ReturnList/GetReturnItemListController";
import Get_Return_Data from "../../../../api/Accounting/Debit/GetRetunListController";

export default function AccountingManagePage() {
  const [showForm, setShowForm] = React.useState(false);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showCheckForm, setShowCheckForm] = React.useState(false);
  const [orderTotalAmount, setOrderTotalAmount] = React.useState(0);
  const [collectTotalAmount, setCollectTotalAmount] = React.useState(0);
  const [showData, setShowData] = React.useState(false);
  const [tableData, setTableData] = React.useState([]);
  const [currentData, setCurrentData] = React.useState({});
  const [categories, setCategories] = useState([
    "Debit List",
    "Return Item List",
    "Profit List",
    "Way Check",
    "OS Pending",
    "OS Report",
  ]);
  const [selectedCategory, setSelectedCategory] = React.useState("Debit List");
  const [selectedSubCategory, setSelectedSubCategory] = React.useState("");
  const [showOverall, setShowOverall] = React.useState(false);
  const [showTotalGetCard, setShowTotalGetCard] = React.useState(false);
  const [clientToGetAmount, setClientToGetAmount] = React.useState(0);
  const [clientToPayAmount, setClientToPayAmount] = React.useState(0);
  const [totalGateAmount, setTotalGateAmount] = React.useState(0);
  const [totalAllPaidAmount, setTotalAllPaidAmount] = React.useState(0);
  const [selectedDebitType, setSelectedDebitType] = React.useState("");
  const [paymentStatus, setPaymentStatus] = React.useState("Pending");
  const [clientData, setClientData] = React.useState(["All"]);
  const [clientName, setClientName] = React.useState("");
  const [totalClientToGetAmount, setTotalClientToGetAmount] = React.useState(0);
  const [totalItemPaidAmount, setTotalItemPaidAmount] = React.useState(0);
  const [totalNoDeliAmount, setTotalNoDeliAmount] = React.useState(0);
  const [totalReturnItemAmount, setTotalReturnItemAmount] = React.useState(0);
  const [totalHalfPaidAmount, setTotalHalfPaidAmount] = React.useState(0);
  const [showClientName, setShowClientName] = React.useState(false);
  const [showPaymentDate, setShowPaymentDate] = React.useState(false);
  const date = moment(new Date()).format("DD-MM-YYYY");
  const [deliMen, setDeliMen] = useState(["All"]);
  const [selectedDeli, setSelectedDeli] = useState("All");
  const [totalExpense, setTotalExpense] = useState(0);
  const [totalOverAll, setTotalOverAll] = useState({});
  const [current, setCurrent] = useState({ checked: false, disabled: false });
  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const businessNameLogo =
    localStorage.getItem("business_Name") == "PostExpressMyanmar"
      ? "7Days Delivery"
      : "CK Delivery";
  const [netProfit, setNetProfit] = useState(0);
  const [deliPrice, setDeliPrice] = useState(0);
  const [deliExpense, setDeliExpense] = useState(0);
  const [generalExpense, setGeneralExpense] = useState(0);
  const [deliItemAmount, setDeliItemAmount] = useState(0);
  const [collectItemAmount, setCollectItemAmount] = useState(0);
  const [pendingItemAmount, setPendingItemAmount] = useState(0);
  const [assignItemAmount, setAssignItemAmount] = useState(0);
  const [completeItemAmount, setCompleteItemAmount] = useState(0);
  const [onWayItemAmount, setOnWayItemAmount] = useState(0);
  const [acceptItemAmount, setAcceptItemAmount] = useState(0);
  const [instockItemAmount, setInstockItemAmount] = useState(0);
  const [rejectItemAmount, setRejectItemAmount] = useState(0);
  const [totalExpenseListCount, setTotalExpenseListCount] = useState(0);
  const [totalRejectedItemPrice, setTotalRejectedItemPrice] = useState(0);
  const [osPendingTotals, setOsPendingTotals] = useState({});
  const debitTypes = [
    { label: "Transfer" },
    { label: "Return Item" },
    { label: "To Pay" },
    { label: "Overall" },
    { label: "Rejected" },
  ];
  const [ways, setWays] = useState(0);
  const [ownerExpense, setOwnerExpense] = React.useState(0);

  // date handlers
  const handleFromDateChange = (newVal) =>
    setFromDate(moment(newVal).format("YYYY-MM-DD"));
  const handleToDateChange = (newVal) =>
    setToDate(moment(newVal).format("YYYY-MM-DD"));
  const handleEditClick = (id, amount) => {
    amount = amount || 0;
    setCurrentData({ id, amount, setShowData });
    setShowEditForm(true);
  };

  const UpdateOsPending = (index) => [
    setCurrentData(tableData[index]),
    setShowEditForm(true),
  ];

  const columns =
    selectedCategory === "Debit List"
      ? selectedDebitType === "Transfer"
        ? [
            {
              name: "CO_ID",
              options: {
                display: false,
              },
            },
            {
              name: "CAO_ID",
              options: {
                display: false,
              },
            },
            "SrNo",
            { name: "Client_Name", options: { display: showClientName } },
            { name: "Receiver_Name", label: "Receiver" },
            { name: "Deli_Men", options: { display: false } },
            { name: "C_Name", label: "City" },
            { name: "T_Name", label: "Township" },
            {
              name: "Tan_Sar_Price",
              options: {
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (value === null || value === "") {
                    return 0;
                  } else {
                    return value;
                  }
                },
              },
            },
            {
              name: "Total_Amount",
              options: {
                customBodyRender: function (value, tableMeta, updateValue) {
                  return value;
                },
              },
            },
            "Deli_Type",
            {
              name: "Create_Date",
              label: "Assign_Date",
              options: {
                customBodyRender: function (value, tableMeta, updateValue) {
                  var assign_date = moment(value).format("DD-MM-YYYY");
                  if (assign_date == "01-01-1900") {
                    return "";
                  } else {
                    return moment(value).format("DD-MM-YYYY");
                  }
                },
              },
            },
            {
              name: "Payment_Date",
              options: {
                display: showPaymentDate,
                customBodyRender: function (value, tableMeta, updateValue) {
                  return moment(value).format("DD-MM-YYYY");
                },
              },
            },
            {
              name: "Complete_Date",
              options: {
                display: true,
                customBodyRender: function (value, tableMeta, updateValue) {
                  return moment(value).format("DD-MM-YYYY");
                },
              },
            },
            {
              name: "\n\n\n\n\n\nACTION",
              options: {
                display: paymentStatus === "Pending",
                setCellProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: "0",
                    background: "white",
                    zIndex: 100,
                  },
                }),
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: 0,
                    background: "white",
                    zIndex: 101,
                  },
                }),
                customBodyRenderLite: (
                  dataIndex,
                  value,
                  tableMeta,
                  updateValue
                ) => {
                  return (
                    <Stack direction="row" spacing={0}>
                      <Tooltip title="Payment Status">
                        <Switch
                          caoId={tableData[dataIndex]["CAO_ID"]}
                          clientDeliName={clientName}
                          paymentStatus={paymentStatus}
                          debitType="Transfer"
                        />
                      </Tooltip>
                    </Stack>
                  );
                },
              },
            },
          ]
        : selectedDebitType === "Return Item"
        ? [
            {
              name: "CO_ID",
              options: {
                display: false,
              },
            },
            {
              name: "CAO_ID",
              options: {
                display: false,
              },
            },
            "SrNo",
            { name: "Client_Name", options: { display: showClientName } },
            { name: "Receiver_Name", label: "Receiver" },
            { name: "Deli_Men", options: { display: false } },
            { name: "C_Name", label: "City" },
            { name: "T_Name", label: "Township" },
            {
              name: "Return_Item_Amount",
              label: "Return_Amount",
              options: {
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (value === null || value === "") {
                    return 0;
                  } else {
                    return value;
                  }
                },
              },
            },
            {
              name: "Total_Amount",
              options: {
                customBodyRender: function (value, tableMeta, updateValue) {
                  return value;
                },
              },
            },
            "Deli_Type",
            {
              name: "Create_Date",
              label: "Assign_Date",
              options: {
                customBodyRender: function (value, tableMeta, updateValue) {
                  var assign_date = moment(value).format("DD-MM-YYYY");
                  if (assign_date == "01-01-1900") {
                    return "";
                  } else {
                    return moment(value).format("DD-MM-YYYY");
                  }
                },
              },
            },
            {
              name: "Payment_Date",
              options: {
                display: showPaymentDate,
                customBodyRender: function (value, tableMeta, updateValue) {
                  return moment(value).format("DD-MM-YYYY");
                },
              },
            },
            {
              name: "Complete_Date",
              options: {
                display: true,
                customBodyRender: function (value, tableMeta, updateValue) {
                  return moment(value).format("DD-MM-YYYY");
                },
              },
            },
            {
              name: "\n\n\n\n\n\nACTION",
              options: {
                display: paymentStatus === "Pending",
                setCellProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: "0",
                    background: "white",
                    zIndex: 100,
                  },
                }),
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: 0,
                    background: "white",
                    zIndex: 101,
                  },
                }),
                customBodyRenderLite: (
                  dataIndex,
                  value,
                  tableMeta,
                  updateValue
                ) => {
                  return (
                    <Stack direction="row" spacing={0}>
                      <Tooltip title="Payment Status">
                        <Switch
                          caoId={tableData[dataIndex]["CAO_ID"]}
                          clientDeliName={clientName}
                          paymentStatus={paymentStatus}
                          debitType="Return Item"
                        />
                      </Tooltip>
                    </Stack>
                  );
                },
              },
            },
          ]
        : selectedDebitType === "To Pay"
        ? [
            "SrNo",
            {
              name: "CO_ID",
              options: {
                display: false,
              },
            },
            {
              name: "Client_Name",
              options: {
                display: clientName === "All" ? true : false,
              },
            },
            "Total_Amount",
            { name: "Gate_Amount", label: "Gate" },
            { name: "All_Paid_Amount", label: "All_Paid" },
            {
              name: "To_Pay_Paid_Amount",
              label: "Paid Amount",
              options: {
                customBodyRender: (val) => {
                  return val ? val : 0;
                },
              },
            },
            "Net_Amount",
            {
              name: "Create_Date",
              label: "Assign_Date",
              options: {
                // display:paymentStatus==="Pending"?false:true,
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (
                    value === null ||
                    value === "" ||
                    value === "1/1/1900 12:00:00 AM"
                  ) {
                    return "";
                  } else {
                    return moment(value).format("DD-MM-YYYY");
                  }
                },
              },
            },
            "Collect_Items",
            {
              name: "Payment_Date",
              options: {
                display: paymentStatus === "Pending" ? false : true,
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (value === null || value === "") {
                    return "";
                  } else {
                    value = value.slice(0, 11);
                    //console.log("Test Date",test);
                    return moment(value).format("DD-MM-YYYY");
                  }
                },
              },
            },
            {
              name: "Order_Check_Status",
              label: "Check_Status",
              options: {
                customBodyRender: (value) => {
                  if (value === "Check") {
                    return <CheckCircle color="success" />;
                  } else {
                    return <Cancel color="warning" />;
                  }
                },
              },
            },
            {
              name: "\n\n\n\n\n\nACTION",
              options: {
                display: paymentStatus === "Pending",
                setCellProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: "0",
                    background: "white",
                    zIndex: 100,
                  },
                }),
                setCellHeaderProps: () => ({
                  style: {
                    whiteSpace: "nowrap",
                    position: "sticky",
                    right: 0,
                    background: "white",
                    zIndex: 101,
                  },
                }),
                customBodyRenderLite: (
                  dataIndex,
                  value,
                  tableMeta,
                  updateValue
                ) => {
                  return (
                    <Stack
                      direction="row"
                      spacing={0}
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                      }}
                    >
                      <Tooltip title="Payment Status">
                        <Switch
                          caoId={tableData[dataIndex]["CO_ID"]}
                          clientDeliName={clientName}
                          paymentStatus={paymentStatus}
                          debitType="To Pay"
                        />
                      </Tooltip>
                      <Tooltip title="Edit">
                        <Edit
                          color="primary"
                          onClick={() =>
                            handleEditClick(
                              tableData[dataIndex]["CO_ID"],
                              tableData[dataIndex]["To_Pay_Paid_Amount"]
                            )
                          }
                        />
                      </Tooltip>
                    </Stack>
                  );
                },
              },
            },
          ]
        : selectedDebitType === "Rejected"
        ? [
            {
              name: "CAO_ID",
              options: {
                display: false,
              },
            },
            {
              name: "SrNo",
            },
            {
              name: "Deli_Men",
              label: "Deli",
            },
            {
              name: "Client_Name",
              label: "Client",
              options: {
                display: clientName === "All",
              },
            },
            {
              name: "Receiver_Name",
              label: "Receiver",
            },
            {
              name: "C_Name",
            },
            {
              name: "T_Name",
            },
            { name: "Deli_Type", options: { display: false } },
            {
              name: "Item_Price",
              options: {
                display: true,
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (value === null || value === "" || value === undefined) {
                    return 0;
                  } else {
                    return value;
                  }
                },
              },
            },
            { name: "G_Name", label: "Gate", options: { display: false } },
            {
              name: "G_Price",
              label: "Gate_Price",
              options: { display: false },
            },
            {
              name: "Expense_Fees",
              options: {
                display: false,
              },
            },
            {
              name: "Tan_Sar_Price",
              options: {
                display: false,
                customBodyRender: function (value, tableMeta, updateValue) {
                  // return value.toLocaleString();
                  if (value === null) {
                    return 0;
                  } else {
                    return value;
                  }
                },
              },
            },
            {
              name: "Return_Item_Amount",
              label: "Return_Amount",
              options: {
                display: true,
                customBodyRender: function (value, tableMeta, updateValue) {
                  // return value.toLocaleString();
                  if (value === null || value === "" || value === undefined) {
                    return 0;
                  } else {
                    return value;
                  }
                },
              },
            },
            {
              name: "Half_Paid_Amount",
              options: {
                display: false,
                customBodyRender: function (value, tableMeta, updateValue) {
                  // return value.toLocaleString();
                  if (value === null || value === "") {
                    return 0;
                  } else {
                    return value;
                  }
                },
              },
            },
            {
              name: "Total_Amount",
              label: "Total",
              options: {
                display: false,
                customBodyRender: function (value, tableMeta, updateValue) {
                  // return value.toLocaleString();
                  return value;
                },
              },
            },
            {
              name: "Deli_Men_Remark",
              label: "Remark",
            },
            {
              name: "Instock_Date",
              options: {
                sortDirection: "desc",
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (
                    value === "1900-01-01T00:00:00" ||
                    value === null ||
                    value === undefined
                  ) {
                    return "";
                  } else {
                    return moment(value).format("DD-MM-YYYY");
                  }
                },
              },
            },
            {
              name: "Rejected_Complete_Date",
              label: "Rejected_Date",
              options: {
                display: "none",
                customBodyRender: function (value, tableMeta, updateValue) {
                  if (value === "1900-01-01T00:00:00" || value === null) {
                    return "";
                  } else {
                    return moment(value).format("DD-MM-YYYY");
                  }
                },
              },
            },
            {
              name: "\n\n\n\n\n\nACTION",
              options: {
                display: paymentStatus === "Pending",
                customBodyRenderLite: (
                  dataIndex,
                  value,
                  tableMeta,
                  updateValue
                ) => {
                  return (
                    <Stack direction="row" spacing={0}>
                      <Tooltip title="Payment Status">
                        <Switch
                          caoId={tableData[dataIndex]["CAO_ID"]}
                          clientDeliName={deliMen}
                          paymentStatus={paymentStatus}
                          debitType="Rejected"
                        />
                      </Tooltip>
                    </Stack>
                  );
                },
              },
            },
          ]
        : []
      : selectedCategory === "Return Item List"
      ? [
          { name: "CO_ID", label: "CO_ID", options: { display: "none" } },
          { name: "CAO_ID", label: "CAO_ID", options: { display: "none" } },
          "Client_Name",
          { name: "Receiver_Name", label: "Receiver" },
          "C_Name",
          "T_Name",
          "Deli_Price",
          "Expense_Fees",
          { name: "Return_Item_Amount", label: "Return_Amount" },
          {
            name: "Deli_Men_Remark",
            label: "Remark",
          },
          "Assign_Remark",
          {
            name: "Pending_Date",
            label: "Pending_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                var assign_date = moment(value).format("DD-MM-YYYY");
                if (assign_date == "01-01-1900" || value == undefined) {
                  return "";
                } else {
                  return moment(value).format("DD-MM-YYYY");
                }
              },
            },
          },
          {
            name: "Create_Date",
            label: "Assign_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                var assign_date = moment(value).format("DD-MM-YYYY");
                if (assign_date == "01-01-1900") {
                  return "";
                } else {
                  return moment(value).format("DD-MM-YYYY");
                }
              },
            },
          },
          {
            name: "Complete_Date",
            label: "Complete_Date",
            options: { display: "none" },
          },
        ]
      : selectedCategory === "Profit List"
      ? ["SrNo", "Deli_Men", "Total_Deli", "Deli_Expense", "Net_Profit", "Ways"]
      : selectedCategory === "Way Check"
      ? [
          "SrNo",
          {
            name: "Deli_Men",
            options: {
              sortDirection: "asc",
              display: selectedDeli === "All",
            },
          },
          "Total_Ways",
          { name: "Total_Item_Amount", label: "Item_Amount" },
        ]
      : selectedCategory === "OS Pending"
      ? [
          {
            name: "CAO_ID",
            options: {
              display: false,
            },
          },
          "SrNo",
          {
            name: "Client_Name",
            label: "Client",
          },
          { name: "Receiver_Name", label: "Receiver" },
          "Deli_Men",
          { name: "C_Name", label: "City" },
          { name: "T_Name", label: "Township" },
          { name: "G_Name", label: "Gate" },
          "Deli_Status",
          "Deli_Type",
          {
            name: "Half_Paid_Amount",
            label: "Transfer",
          },
          "Deli_Price",
          "Item_Price",
          "Total",
          {
            name: "Deli_Men_Remark",
            label: "Deli_Remark",
          },
          {
            name: "Assign_Remark",
            //label: "Assign_Remark",
          },
          {
            name: "Create_Date",
            label: "Assign_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                var assign_date = moment(value).format("DD-MM-YYYY");
                if (assign_date == "01-01-1900") {
                  return "";
                } else {
                  return moment(value).format("DD-MM-YYYY");
                }
              },
            },
          },
          {
            name: "Pending_Date",
            label: "Pending_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                var assign_date = moment(value).format("DD-MM-YYYY");
                if (assign_date == "01-01-1900" || value == undefined) {
                  return "";
                } else {
                  return moment(value).format("DD-MM-YYYY");
                }
              },
            },
          },
          {
            name: "\n\n\n\n\n\nACTION",
            options: {
              display: true,
              setCellProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  right: "0",
                  background: "white",
                  zIndex: 100,
                },
              }),
              setCellHeaderProps: () => ({
                style: {
                  whiteSpace: "nowrap",
                  position: "sticky",
                  right: 0,
                  background: "white",
                  zIndex: 101,
                },
              }),
              customBodyRenderLite: (
                dataIndex,
                value,
                tableMeta,
                updateValue
              ) => {
                const status = tableData[dataIndex]["Deli_Status"];
                return status === "Assigned" ||
                  status === "OnWay" ||
                  status === "Accepted" ? (
                  <Stack direction="row" spacing={0}>
                    <Tooltip title="Edit">
                      <IconButton
                        tooltip="Edit"
                        color="primary"
                        aria-label="edit"
                        disabled={
                          value.Deli_Status === "Assigned" ||
                          value.Deli_Status === "OnWay"
                        }
                        onClick={() => UpdateOsPending(dataIndex)}
                      >
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                ) : (
                  ""
                );
              },
            },
          },
        ]
      : selectedCategory === "OS Report"
      ? [
          {
            name: "CO_ID",
            options: {
              display: false,
            },
          },
          {
            name: "CAO_ID",
            options: {
              display: false,
            },
          },
          {
            name: "SrNo",
            options: {
              sortDirection: "desc",
              // customSort: (data, colIndex, order) => {
              //   return data.sort((a, b) => {
              //     return (
              //       (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
              //       (order === "desc" ? 1 : -1)
              //     );
              //   });
              // }
            },
          },
          { name: "Receiver_Name", label: "Receiver" },
          "Deli_Men",
          { name: "C_Name", label: "City" },
          { name: "T_Name", label: "Township" },
          {
            name: "Item_Price",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                return value;
              },
            },
          },
          "Deli_Price",
          "Expense_Fees",
          { name: "G_Name", label: "Gate" },
          { name: "G_Price", label: "Gate_Price" },
          "Deli_Type",
          {
            name: "Total_Amount",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                return value;
              },
            },
          },
          {
            name: "Deli_Status",
            options: {
              customBodyRender: (value) => {
                if (value === "Assigned") {
                  return (
                    <Typography sx={{ color: "blue" }}>{value}</Typography>
                  );
                } else if (value === "Pending") {
                  return (
                    <Typography sx={{ color: "#424242" }}>{value}</Typography>
                  );
                } else if (value === "OnWay") {
                  return (
                    <Typography sx={{ color: "#ffc107" }}>{value}</Typography>
                  );
                } else if (value === "Completed") {
                  return (
                    <Typography sx={{ color: "#009688" }}>{value}</Typography>
                  );
                } else if (value === "Accepted") {
                  return (
                    <Typography sx={{ color: "#4caf50" }}>{value}</Typography>
                  );
                } else {
                  return <Typography sx={{ color: "red" }}>{value}</Typography>;
                }
              },
            },
          },
          { name: "Expense_Status", options: { display: false } },
          { name: "Payment_Status", options: { display: false } },
          { name: "Assign_Remark", options: { display: false } },
          {
            name: "Create_Date",
            label: "Assign_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                if (value === null || value === "") {
                  return "";
                } else {
                  var assign_date = moment(value).format("DD-MM-YYYY");
                  return assign_date;
                }
              },
            },
          },
          {
            name: "Accept_Date",
            options: {
              customBodyRender: function (value, tableMeta, updateValue) {
                if (value === null || value === "") {
                  return "";
                } else {
                  var accept_date = moment(value).format("DD-MM-YYYY");
                  return accept_date;
                }
              },
            },
          },
        ]
      : [];
  console.log("Columns", columns);
  // console.log(clientName);

  const UpdateAccount = (dataIndex) => {
    setCurrentData({
      CO_ID: tableData[dataIndex]["CO_ID"],
      Client_Name: tableData[dataIndex]["Client_Name"],
      No_Ways: tableData[dataIndex]["No_Ways"],
      Pickup_Date: tableData[dataIndex]["Pickup_Date"],
      Total_Amount: tableData[dataIndex]["Total_Amount"],
      Client_Pay_Type: tableData[dataIndex]["Client_Pay_Type"],
      Payment_Status: tableData[dataIndex]["Payment_Status"],
      Remark: tableData[dataIndex]["Remark"],
    });
    setShowEditForm(true);
  };
  const DeleteAccount = (dataIndex) => {
    Delete_Client_Order(tableData[dataIndex]["CO_ID"]);
  };
  const CheckAmount = (dataIndex) => {
    var co_id = tableData[dataIndex]["CO_ID"];
    setOrderTotalAmount(tableData[dataIndex]["Total_Amount"]);
    Check_Order_And_Collect_Amount(
      co_id,
      setCollectTotalAmount,
      setShowCheckForm
    );
  };
  const CollAssignOrder = (dataIndex) => {
    var co_id = tableData[dataIndex]["CO_ID"];
    window.location.href = "/admin/manage/order/collectassign?CO_ID=" + co_id;
  };
  // const handleTableData = () => {
  //   if (showData === false) {
  //     if (selectedCategory==="Debit List"){
  //       selectedDebitType === "To Get" ?
  //       Get_Debit_Data(clientName,selectedDebitType,paymentStatus,setTableData,setClientToGetAmount, setShowData,setShowOverall, setTotalClientToGetAmount,setTotalItemPaidAmount,setTotalNoDeliAmount,setTotalReturnItemAmount,setTotalHalfPaidAmount,setTotalGateAmount,setTotalAllPaidAmount) :
  //       selectedDebitType === "To Pay" ?
  //       Get_Debit_Data(clientName,selectedDebitType,paymentStatus,setTableData,setClientToPayAmount, setShowData, setShowOverall) :
  //       selectedDebitType === "Rejected" ?
  //       Get_Rejected_Data(setTableData, setShowData, selectedDeli, paymentStatus, setShowOverall, setTotalRejectedItemPrice) :
  //       Get_Overall_Data(setTotalOverAll, clientName, setShowData, setShowOverall);
  //     }
  //     else if (selectedCategory==="Deli Expense"){
  //       Get_Expense_Data(setTotalExpenseListCount, selectedDeli, setShowData, setTotalExpense, fromDate, toDate);
  //     }
  //     else if (selectedCategory === "Profit List"){
  //      Get_Profit_Data(fromDate, toDate, setTableData, setShowData, setNetProfit, setDeliPrice, setDeliExpense, setGeneralExpense, setWays);
  //     }
  // else if (selectedCategory === "Way Check"){
  //   Get_WayCheck_Data(setDeliItemAmount, setCollectItemAmount, selectedDeli, fromDate, toDate);
  // }
  //   } else {
  //     setShowData(false);
  //   }
  // };
  const handleApplyFilter = () => {
    setShowData(false);
    setShowOverall(false);
    if (selectedCategory === "Debit List") {
      paymentStatus === "Pending"
        ? setShowPaymentDate(false)
        : setShowPaymentDate(true);
      //when submitting will also call detail & total data
      console.log("Selected Debit Type",selectedDebitType.label)
      selectedDebitType === "Transfer"
        ? Get_Debit_Data(
            clientName,
            "To Get",
            paymentStatus,
            fromDate,
            toDate,
            setTableData,
            setClientToGetAmount,
            setShowData,
            setShowOverall,
            setWays,
            setTotalClientToGetAmount,
            setTotalItemPaidAmount,
            setTotalNoDeliAmount,
            setTotalReturnItemAmount,
            setTotalHalfPaidAmount,
            setTotalGateAmount,
            setTotalAllPaidAmount
          )
        : selectedDebitType === "To Pay"
        ? Get_Debit_Data(
            clientName,
            selectedDebitType,
            paymentStatus,
            fromDate,
            toDate,
            setTableData,
            setClientToPayAmount,
            setShowData,
            setShowOverall,
            setWays
          )
        : selectedDebitType === "Rejected"
        ? Get_Rejected_Data(
            setTableData,
            setShowData,
            clientName,
            fromDate,
            toDate,
            paymentStatus,
            setShowOverall,
            setTotalRejectedItemPrice,
            setWays,
            tableData
          )
        : selectedDebitType === "Return Item"
        ? Get_Return_Data(
            clientName,
            selectedDebitType.label,
            paymentStatus,
            fromDate,
            toDate,
            setTableData,
            setTotalReturnItemAmount,
            setShowData,
            setShowOverall,
            setWays
          )
        : Get_Overall_Data(
            setTotalOverAll,
            clientName,
            setShowData,
            setShowOverall,
            setWays
          );
      return;
    } else if (selectedCategory === "Return Item List") {
      Get_Return_Item_List(
        clientName,
        setTableData,
        setShowData,
        setWays,
        setTotalReturnItemAmount
      );
    } else if (selectedCategory === "Profit List") {
      Get_Profit_Data(
        fromDate,
        toDate,
        setTableData,
        setShowData,
        setNetProfit,
        setDeliPrice,
        setDeliExpense,
        setGeneralExpense,
        setWays
      );
      Get_Owner_Expense(setOwnerExpense, fromDate, toDate);
    } else if (selectedCategory === "Way Check") {
      Get_WayCheck_Data(
        setDeliItemAmount,
        setTableData,
        setCollectItemAmount,
        selectedDeli,
        fromDate,
        toDate,
        setShowData
      );
    } else if (selectedCategory === "OS Pending") {
      Get_OsPending_Data(
        clientName,
        setTableData,
        setOsPendingTotals,
        setShowData,
        tableData
      );
    } else if (selectedCategory === "Expense List") {
      Get_Expense_Data(
        setTotalExpenseListCount,
        selectedDeli,
        setShowData,
        setTotalExpense,
        fromDate,
        toDate
      );
    } else {
      GetOsReport(
        clientName,
        fromDate,
        toDate,
        setTableData,
        setCollectItemAmount,
        setPendingItemAmount,
        setAssignItemAmount,
        setOnWayItemAmount,
        setCompleteItemAmount,
        setAcceptItemAmount,
        setInstockItemAmount,
        setRejectItemAmount,
        setShowData,
        setWays
      );
    }
  };
  useEffect(() => {
    const business_Name = localStorage.getItem("business_Name");
    Get_All_Clients(setClientData, business_Name);
    Get_All_Deli_Account(setDeliMen, business_Name);
  }, []);

  return (
    <>
      {/* <SideDrawerComponent headerText="Account Management"/> */}
      <ThemeProvider theme={Theme}>
        <CssBaseline />
        <Grid container spacing={1}>
          {/* Account Category */}
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              disablePortal
              id="accountingCategory"
              value={selectedCategory}
              options={categories}
              sx={{ width: "auto" }}
              autoFocus
              onChange={(event, value) => {
                setSelectedCategory(value);
                setShowData(false);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Accounting Category" />
              )}
            />
          </Grid>

          {selectedCategory === "Debit List" ||
          selectedCategory === "Return Item List" ||
          selectedCategory === "Deli Expense" ||
          selectedCategory === "Way Check" ||
          selectedCategory === "OS Pending" ||
          selectedCategory === "OS Report" ? (
            // Client Name
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Autocomplete
                disablePortal
                id={
                  selectedCategory === "Debit List" ||
                  selectedCategory === "Return Item List" ||
                  selectedCategory === "OS Pending" ||
                  selectedCategory === "OS Report"
                    ? "clientName"
                    : "deliName"
                }
                options={
                  selectedCategory === "Debit List" ||
                  selectedCategory === "Return Item List" ||
                  selectedDebitType == "Rejected" ||
                  selectedCategory === "OS Pending" ||
                  selectedCategory === "OS Report"
                    ? clientData
                    : deliMen
                }
                value={
                  selectedCategory === "Debit List" ||
                  selectedCategory === "Return Item List" ||
                  selectedDebitType == "Rejected" ||
                  selectedCategory === "OS Pending" ||
                  selectedCategory === "OS Report"
                    ? clientName
                    : selectedDeli
                }
                onChange={(event, value) => {
                  if (
                    selectedCategory === "Debit List" ||
                    selectedCategory === "Return Item List" ||
                    selectedDebitType == "Rejected" ||
                    selectedCategory === "OS Pending" ||
                    selectedCategory === "OS Report"
                  ) {
                    setClientName(value);
                    setShowClientName(value === "All" ? true : false);
                  } else {
                    setSelectedDeli(value);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={
                      selectedCategory === "Debit List" ||
                      selectedCategory === "Return Item List" ||
                      selectedDebitType == "Rejected" ||
                      selectedCategory === "OS Pending" ||
                      selectedCategory === "OS Report"
                        ? "Client"
                        : "DeliMan"
                    }
                  />
                )}
              />
            </Grid>
          ) : (
            <></>
          )}
          {selectedCategory === "Return Item List" && clientName !== "" && (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={2}
              style={{ display: "flex", flex: 1 }}
            >
              <Button
                fullWidth
                sx={{ margin: "auto" }}
                variant="contained"
                onClick={() => handleApplyFilter()}
              >
                Apply Filter
              </Button>
            </Grid>
          )}
          {(selectedCategory === "Deli Expense" ||
            selectedCategory === "Way Check" ||
            selectedCategory === "OS Report" ||
            selectedCategory === "Profit List") && (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <MobileDatePicker
                  label="From Date"
                  inputFormat="dd-MM-yyyy"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <MobileDatePicker
                  label="To Date"
                  inputFormat="dd-MM-yyyy"
                  value={toDate}
                  onChange={handleToDateChange}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </LocalizationProvider>
          )}
          {clientName != "" && selectedCategory === "Debit List" ? (
            <>
              {/* Debit Type */}
              <Grid item xs={12} sm={6} md={4} lg={2}>
                <Autocomplete
                  disablePortal
                  id="debitType"
                  value={selectedDebitType}
                  options={debitTypes}
                  autoFocus
                  onChange={(event, value) => { 
                    console.log("Select Debit Type",value.label)                                     
                    setSelectedDebitType(value.label);
                    setShowData(false);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Debit Type" />
                  )}
                />
              </Grid>
            </>
          ) : (
            <></>
          )}
          {clientName != "" &&
          clientName != undefined &&
          selectedCategory === "Debit List" ? (
            <>
              {selectedDebitType !== "Overall" && (
                <>
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <Autocomplete
                      disablePortal
                      id="paymentStatus"
                      value={paymentStatus}
                      options={[{ label: "Pending" }, { label: "Completed" }]}
                      autoFocus
                      onChange={(event, value) => {
                        if (selectedCategory === "Debit List") {
                          const selectedValue = value?.label; // Access the label from the selected option
                          setPaymentStatus(selectedValue);
                          setShowData(false);
                          setShowPaymentDate(selectedValue === "Completed");
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            selectedDebitType === "Rejected"
                              ? "Rejected Status"
                              : "Payment Status"
                          }
                        />
                      )}
                    />
                  </Grid>

                  {/* date filter */}
                  {selectedDebitType !== "Overall" &&
                    paymentStatus === "Completed" && (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <MobileDatePicker
                            label="From Date"
                            inputFormat="dd-MM-yyyy"
                            value={fromDate}
                            onChange={handleFromDateChange}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4} lg={2}>
                          <MobileDatePicker
                            label="To Date"
                            inputFormat="dd-MM-yyyy"
                            value={toDate}
                            onChange={handleToDateChange}
                            renderInput={(params) => (
                              <TextField fullWidth {...params} />
                            )}
                          />
                        </Grid>
                      </LocalizationProvider>
                    )}
                </>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
                style={{ display: "flex", flex: 1 }}
              >
                <Button
                  fullWidth
                  sx={{ margin: "auto" }}
                  variant="contained"
                  onClick={() => handleApplyFilter()}
                >
                  Apply Filter
                </Button>
              </Grid>
            </>
          ) : (
            (selectedCategory === "Profit List" ||
              ((selectedCategory === "Deli Expense" ||
                selectedCategory === "Way Check") &&
                selectedDeli !== "") ||
              selectedCategory === "No DeliMen" ||
              selectedCategory === "OS Pending" ||
              (selectedCategory === "OS Report" && clientName)) && (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
                style={{ display: "flex", flex: 1 }}
              >
                <Button
                  fullWidth
                  sx={{ margin: "auto" }}
                  variant="contained"
                  onClick={() => handleApplyFilter()}
                >
                  Apply Filter
                </Button>
              </Grid>
            )
          )}
        </Grid>
        <br />

        <>
          {selectedCategory === "OS Report" && showData && (
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {ways.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Ways
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {collectItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Total Collect
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {pendingItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Pending
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {assignItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Assign
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {onWayItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      OnWay
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {completeItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Complete
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {acceptItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Accept
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {instockItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Instock
                    </Typography>
                  </Stack>
                </Box>
                <br />
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={1}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {rejectItemAmount.toLocaleString("en-US")}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Reject
                    </Typography>
                  </Stack>
                </Box>
                <br />
              </Grid>
            </Grid>
          )}
          {selectedDebitType === "Overall" &&
          showData &&
          showOverall &&
          selectedCategory === "Debit List" ? (
            // voucher ui
            <Grid contaner spacing={2}>
              <Grid element xs={12} md={6} lg={5} sx={{ margin: "auto" }}>
                <Card>
                  <CardActions
                    sx={{
                      textAlign: "right",
                      display: "flex",
                      justifyContent: "end",
                      margin: 0,
                    }}
                  >
                    <i>
                      <small>{date}</small>
                    </i>
                  </CardActions>
                  <CardContent
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    {/* grid box */}
                    <Grid
                      container
                      sx={{ width: "auto", alignItems: "center" }}
                    >
                      {/* logo and text */}
                      <Grid item sx={{ marginRight: ".5rem" }}>
                        <Avatar
                          alt={businessNameLogo}
                          src={
                            businessNameLogo === "7Days Delivery"
                              ? logo
                              : cklogo
                          }
                        />
                      </Grid>
                      <Grid item sx={{ flexGrow: 1 }}>
                        {businessNameLogo}
                      </Grid>
                    </Grid>

                    {/* line break */}
                    <br />

                    {/* client to pay */}
                    <Typography variant="body1" component="h5">
                      Client To Pay -{" "}
                      <span
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "bold",
                          fontSize: "larger",
                        }}
                      >
                        {" "}
                        {totalOverAll.toPay.toLocaleString("en-us")}{" "}
                      </span>
                      MMK
                    </Typography>

                    {/* client to get                      */}
                    <Typography variant="body1" component="h5">
                      Transfer -{" "}
                      <span
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "bold",
                          fontSize: "larger",
                        }}
                      >
                        {" "}
                        {totalOverAll.toGet.toLocaleString("en-us")}{" "}
                      </span>
                      MMK
                    </Typography>

                    {/* rejected                    */}
                    <Typography variant="body1" component="h5">
                      Rejected Amount -{" "}
                      <span
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "bold",
                          fontSize: "larger",
                        }}
                      >
                        {" "}
                        {totalOverAll.rejectedAmount.toLocaleString(
                          "en-us"
                        )}{" "}
                      </span>
                      MMK
                    </Typography>

                    {/* horizontal rule */}
                    <Divider sx={{ width: "100%", margin: "1rem 0" }} />

                    {/* net amount */}
                    <Typography variant="body1" component="h5">
                      Net Amount -{" "}
                      <span
                        style={{
                          marginLeft: "1rem",
                          fontWeight: "bold",
                          fontSize: "larger",
                        }}
                      >
                        {totalOverAll.overall.toLocaleString("en-us")}{" "}
                      </span>
                      MMK
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          ) : selectedDebitType !== "Overall" && showData ? (
            <>
              <Button
                sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
                endIcon={<KeyboardArrowDown />}
                onClick={() => setShowData(false)}
              >
                Hide Data
              </Button>
              <br />
              <br />
              {(showOverall &&
                showData &&
                (selectedDebitType === "Transfer" ||
                  selectedDebitType === "Return Item" ||
                  selectedDebitType === "To Pay" ||
                  selectedDebitType === "Rejected") &&
                selectedCategory === "Debit List") ||
              (showData &&
                (selectedCategory === "Deli Expense" ||
                  selectedCategory === "Way Check" ||
                  selectedCategory === "No DeliMen")) ? (
                <Stack direction="row">
                  <Grid container spacing={2}>
                    <>
                      {paymentStatus === "Pending" &&
                      selectedCategory !== "Way Check" &&
                      selectedCategory !== "No DeliMen" ? (
                        <>
                          <Grid item xs={6} sm={6} md={2}>
                            <Box sx={AccountingManagementStyle.boxStyle}>
                              <Stack direction="column">
                                <Typography variant="h5">
                                  {selectedCategory === "Debit List"
                                    ? selectedDebitType === "Transfer"
                                      ? clientToGetAmount.toLocaleString(
                                          "en-US"
                                        )
                                      : selectedDebitType === "Return Item"
                                      ? totalReturnItemAmount.toLocaleString(
                                          "en-US"
                                        )
                                      : selectedDebitType === "To Pay"
                                      ? clientToPayAmount.toLocaleString(
                                          "en-US"
                                        )
                                      : totalRejectedItemPrice.toLocaleString(
                                          "en-US"
                                        )
                                    : totalExpense.toLocaleString("en-US")}
                                </Typography>
                                <Typography
                                  variant="h8"
                                  sx={AccountingManagementStyle.subHeading}
                                >
                                  {selectedCategory === "Debit List"
                                    ? selectedDebitType === "Transfer"
                                      ? "Transfer"
                                      : selectedDebitType === "To Pay"
                                      ? "Client To Pay"
                                      : selectedDebitType === "Return Item"
                                      ? "Return Amount"
                                      : "Rejected Amount"
                                    : "Total Expense"}
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} md={2}>
                            <Box sx={AccountingManagementStyle.boxStyle}>
                              <Stack direction="column">
                                <Typography variant="h5">{ways}</Typography>
                                <Typography
                                  variant="h8"
                                  sx={AccountingManagementStyle.subHeading}
                                >
                                  Ways
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                        </>
                      ) : (
                        <></>
                      )}
                      {selectedCategory === "Deli Expense" && (
                        <Grid item xs={6} sm={6} md={2}>
                          <Box sx={AccountingManagementStyle.boxStyle}>
                            <Stack direction="column">
                              <Typography variant="h5">
                                {totalExpenseListCount}
                              </Typography>
                              <Typography
                                variant="h8"
                                sx={AccountingManagementStyle.subHeading}
                              >
                                Ways
                              </Typography>
                            </Stack>
                          </Box>
                        </Grid>
                      )}
                      {selectedCategory === "Way Check" && (
                        <>
                          <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Box sx={AccountingManagementStyle.boxStyle}>
                              <Stack direction="column">
                                <Typography variant="h5">
                                  {deliItemAmount.toLocaleString("en-US")}
                                </Typography>
                                <Typography
                                  variant="h8"
                                  sx={AccountingManagementStyle.subHeading}
                                >
                                  Deli Item Amount
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Box sx={AccountingManagementStyle.boxStyle}>
                              <Stack direction="column">
                                <Typography variant="h5">
                                  {collectItemAmount.toLocaleString("en-US")}
                                </Typography>
                                <Typography
                                  variant="h8"
                                  sx={AccountingManagementStyle.subHeading}
                                >
                                  Collect Item Amount
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} md={4} lg={2}>
                            <Box sx={AccountingManagementStyle.boxStyle}>
                              <Stack direction="column">
                                <Typography variant="h5">
                                  {(
                                    collectItemAmount - deliItemAmount
                                  ).toLocaleString("en-US")}
                                </Typography>
                                <Typography
                                  variant="h8"
                                  sx={AccountingManagementStyle.subHeading}
                                >
                                  Difference
                                </Typography>
                              </Stack>
                            </Box>
                          </Grid>
                        </>
                      )}
                    </>
                  </Grid>
                </Stack>
              ) : (
                <></>
              )}
              <br />
              {showData && selectedCategory === "Return Item List" && (
                <Grid container spacing={2} sx={{ marginBottom: "1.5rem" }}>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">{ways} </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Ways
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {totalReturnItemAmount}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Item Amount
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {selectedCategory === "Profit List" && (
                <Grid container spacing={2} sx={{ marginBottom: "1.5rem" }}>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {(netProfit - ownerExpense).toLocaleString("en-us")}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Net Profit
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {deliPrice.toLocaleString("en-us")}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Total Deli
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {deliExpense.toLocaleString("en-us")}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Deli Expense
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {generalExpense.toLocaleString("en-us")}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          General Expense
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">{ways} </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Ways
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">{ownerExpense}</Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Owner Expense
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {selectedCategory === "OS Pending" && (
                <Grid container spacing={2} sx={{ marginBottom: "1.5rem" }}>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {osPendingTotals["OnWay"]
                            ? osPendingTotals["OnWay"].toLocaleString("en-us")
                            : 0}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          OnWay
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {osPendingTotals["Assigned"]
                            ? osPendingTotals["Assigned"].toLocaleString(
                                "en-us"
                              )
                            : 0}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Assigned
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {osPendingTotals["Rejected"]
                            ? osPendingTotals["Rejected"].toLocaleString(
                                "en-us"
                              )
                            : 0}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Rejected
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid> */}
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {osPendingTotals["Instock"]
                            ? osPendingTotals["Instock"].toLocaleString("en-us")
                            : 0}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          InStock
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {osPendingTotals["Transfer"]
                            ? osPendingTotals["Transfer"].toLocaleString(
                                "en-us"
                              )
                            : 0}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Transfer
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {osPendingTotals["Pending"]
                            ? osPendingTotals["Pending"].toLocaleString("en-us")
                            : 0}{" "}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Pending
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <Box sx={AccountingManagementStyle.boxStyle}>
                      <Stack direction="column">
                        <Typography variant="h5">
                          {/* {Object.values(osPendingTotals)
                            .reduce((next, total) => (total += next || 0), 0)
                            .toLocaleString("en-US")}{" "} */}
                          {(
                            parseInt(osPendingTotals["OnWay"] || 0) +
                            parseInt(osPendingTotals["Assigned"] || 0) +
                            parseInt(osPendingTotals["Instock"] || 0) +
                            parseInt(osPendingTotals["Transfer"] || 0) +
                            parseInt(osPendingTotals["Pending"] || 0)
                          ).toLocaleString()}
                        </Typography>
                        <Typography
                          variant="h8"
                          sx={AccountingManagementStyle.subHeading}
                        >
                          Total
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {selectedCategory === "Debit List" &&
              clientName !== "" &&
              paymentStatus === "Completed" ? (
                selectedDebitType === "Transfer" ? (
                  <Stack direction="row" spacing={2}>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Box sx={AccountingManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {clientToGetAmount.toLocaleString("en-US")}
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={AccountingManagementStyle.subHeading}
                          >
                            Transfer
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Box sx={AccountingManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {ways.toLocaleString("en-US")}
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={AccountingManagementStyle.subHeading}
                          >
                            Ways
                          </Typography>
                        </Stack>
                      </Box>
                      <br />
                    </Grid>
                  </Stack>
                ) : selectedDebitType === "To Pay" ? (
                  <Stack direction="row" spacing={2}>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Box sx={AccountingManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {clientToPayAmount.toLocaleString("en-US")}
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={AccountingManagementStyle.subHeading}
                          >
                            Client To Pay
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Box sx={AccountingManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {ways.toLocaleString("en-US")}
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={AccountingManagementStyle.subHeading}
                          >
                            Ways
                          </Typography>
                        </Stack>
                      </Box>
                      <br />
                    </Grid>
                  </Stack>
                ) : selectedDebitType === "Rejected" ? (
                  <Stack direction="row" spacing={2}>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Box sx={AccountingManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {totalRejectedItemPrice.toLocaleString("en-US")}
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={AccountingManagementStyle.subHeading}
                          >
                            Rejected Amount
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={4} lg={2}>
                      <Box sx={AccountingManagementStyle.boxStyle}>
                        <Stack direction="column">
                          <Typography variant="h5">
                            {ways.toLocaleString("en-US")}
                          </Typography>
                          <Typography
                            variant="h8"
                            sx={AccountingManagementStyle.subHeading}
                          >
                            Ways
                          </Typography>
                        </Stack>
                      </Box>
                      <br />
                    </Grid>
                  </Stack>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {selectedCategory !== "Deli Expense" && (
                <DataTableComponent
                  title={selectedCategory}
                  data={tableData}
                  columns={columns}
                />
              )}
              {showEditForm && (
                <EditFormComponent
                  headerText={
                    selectedDebitType === "To Pay"
                      ? "Edit ToPay Amount"
                      : selectedCategory === "OS Pending"
                      ? "OS Pending"
                      : ""
                  }
                  formType={
                    selectedDebitType === "To Pay"
                      ? "Debit ToPay"
                      : selectedCategory === "OS Pending"
                      ? "OS Pending"
                      : ""
                  }
                  buttonText="Update"
                  currentData={currentData}
                  setShowEditForm={setShowEditForm}
                />
              )}
            </>
          ) : (
            <Button
              sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
              endIcon={<KeyboardArrowRight />}
              onClick={() => handleApplyFilter()}
            >
              Show Data
            </Button>
          )}
        </>
      </ThemeProvider>
    </>
  );
}
