import axios from "axios";
import API_EndPoint from "../../URL";
const Create_Price_Controller=async(postData,setLoading,setShowForm)=>{
    setLoading(true);
var api_endpoint=API_EndPoint.URL;
    await axios.post(api_endpoint+'data/price',postData,API_EndPoint.Config).then((res)=>{
        if(res.status===201){
            alert("Price Created");
            setLoading(false);
            setShowForm(false);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
        setLoading(false);
    })
}
export default Create_Price_Controller;