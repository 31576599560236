import axios from "axios";
import API_EndPoint from "../../URL";
const Update_City=async(updateData,setLoading)=>{
    setLoading(true);
var api_endpoint=API_EndPoint.URL;
await axios.put(api_endpoint+'data/city',updateData,API_EndPoint.Config).then((res)=>{
    if(res.status===202){
        alert("City Updated");
        setLoading(false);
    }
}).catch((err)=>{
    setLoading(false);
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
})
}
export default Update_City;