import axios from "axios";
import API_EndPoint from "../../URL";
import moment from "moment";
const Get_Owner_Expense = async (setOwnerExpense, fromDate, toDate) => {
    const business_Name = localStorage.getItem("business_Name");
    await axios.get(API_EndPoint.URL + "data/oexp?From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + business_Name,API_EndPoint.Config)
    .then(({data}) => {
        let totalExpense = 0;
        data.map(data => totalExpense += parseInt(data.Owner_Expense_Amount || 0));
        setOwnerExpense(totalExpense);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default Get_Owner_Expense;