import React, { useEffect } from "react";
import API_EndPoint from "../api/URL";
import axios from "axios";
import moment from "moment";
import {
  ThemeProvider,
  CssBaseline,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Divider
} from "@mui/material";
import Theme from "../theme/Theme";
import theme from "../theme/Theme";
export default function ShowAmountByWay(
    {
        formType,
        headerText,
        collectTotalAmount,
        pendingTotalAmount,
        assignTotalAmount,
        onWayTotalAmount,
        completeTotalAmount,
        acceptTotalAmount,
        instockTotalAmount,
        rejectTotalAmount,
        transferTotalAmount,
        setShowAmountByWay,
        orderCreateDate
    }
){
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Dialog open={true} onClose={() => setShowAmountByWay(false)}>
        <DialogTitle>{headerText}</DialogTitle>
        <DialogContent>
        {formType==="ShowAmountByWay"?
        <Stack spacing={2} direction="column">
                      <Typography
              variant="p"
              // style={{ color:theme.palette.primary.main }}
            >
              Order Date : {moment(orderCreateDate).format("DD-MM-YYYY")}
            </Typography>
        <Typography variant="h6" style={{color:'#0288d1'}}>Collect  Amount : {collectTotalAmount} </Typography>
        <Divider/>
        <Typography variant="h6">Pending :{pendingTotalAmount}</Typography>
        <Typography variant="h6">Assigned : {assignTotalAmount} </Typography>
        <Typography variant="h6">OnWay : {onWayTotalAmount} </Typography>
        <Typography variant="h6">Complete :{completeTotalAmount} </Typography>
        <Typography variant="h6">Accept :{acceptTotalAmount} </Typography>
        <Typography variant="h6">Instock : {instockTotalAmount} </Typography>
        <Typography variant="h6">Reject : {rejectTotalAmount} </Typography>                
        <Divider/>
        <Typography variant="h6">Transfer : {transferTotalAmount} </Typography>                
        </Stack>
        :<></>}
        </DialogContent>
        <DialogActions>
          <br />
          <br />
          <Button onClick={() => setShowAmountByWay(false)}>OK</Button>
        </DialogActions>
        </Dialog>
    </ThemeProvider>
  );
};
