import axios from "axios";
import API_EndPoint from "../URL";
import moment from "moment";
const Get_Deli_Ways=async(deliStatus,deliType,setTableData,setShowData,setOrderTotalAmount,setTotalExpenseFees,setOrderItemAmount,fromDate,toDate,setWays)=>{
var api_endpoint=API_EndPoint.URL;
var business_name=localStorage.getItem("business_Name");
var deli_men=localStorage.getItem("user_Name");
var pathUrl;
console.log("API From Date",fromDate);
console.log("API To Date",toDate);

if(deliStatus==="Completed" || deliStatus==="OnWay"){
    fromDate=null;
    toDate=null;
}
if(fromDate===null && toDate===null){
    fromDate=moment().format("YYYY-MM-DD");
    toDate=moment().format("YYYY-MM-DD");
    pathUrl='delimen?Deli_Men='+deli_men+'&Deli_Status='+deliStatus+'&Deli_Type='+deliType+'&Business_Name='+business_name+'&From_Date='+fromDate+'&To_Date='+toDate;
}
else{
    pathUrl='delimen?Deli_Men='+deli_men+'&Deli_Status='+deliStatus+'&Business_Name='+business_name+'&From_Date='+fromDate+'&To_Date='+toDate;
}
var total_amount=0;
await axios.get(api_endpoint+pathUrl,API_EndPoint.Config).then((res)=>{
    if(res.status===200){
        setTableData([]);
        setTableData(res.data);
        setWays(res.data.length);
        //console.log("length",);
        var total_expense_fees=0;
        var total_item_amount=0;
        //if deli staus is completed then we need to detect deli type and calculate total amount according to that.
           res.data.map(({Total_Amount,Deli_Type})=>{
               //Deli_Type!=="Transfer"?
                total_amount+=parseInt(Total_Amount)
                //console.log("");
            })
        res.data.map(({Expense_Fees})=>{
            total_expense_fees+=parseInt(Expense_Fees);
        })
        res.data.map(({Item_Price})=>{
            total_item_amount+=parseInt(Item_Price);
        })
        //showing order total amount
        //if deli status is completed then we need to detect deli type and calculate total amount according to that.
        // if(deliStatus==="Completed"){
        //     var complete_total_amount=0;
        //     res.data.map(({Total_Amount})=>{
        //         complete_total_amount+=parseInt(Total_Amount);
        //     })
        //     // var local_total_amount=localStorage.getItem("total_Amount");
        //     res.data.map(({Deli_Type,Item_Amount,Expense_Fees,Half_Paid_Amount,Return_Item_Amount,Total_Amount})=>{
        //         //for os paid 
        //         alert(complete_total_amount);
        //         if(Deli_Type==="OS Paid"){
        //             alert(complete_total_amount);
        //             alert("Total_Amount",Total_Amount);
        //             complete_total_amount=parseInt(complete_total_amount)-parseInt(Total_Amount);
        //             alert("Complete_Total_Amount",complete_total_amount);
        //         }
        //         else if(Deli_Type==="Item Paid"){
        //             complete_total_amount=parseInt(complete_total_amount)-parseInt(Expense_Fees);
        //         }
        //         else if(Deli_Type==="No Deli"){
        //             complete_total_amount=parseInt(complete_total_amount)-parseInt(Total_Amount);
        //         }
        //         else if(Deli_Type==="Half Paid"){
        //             total_amount=parseInt(total_amount)-parseInt(Half_Paid_Amount);
        //         }
        //         else{
        //             complete_total_amount=parseInt(complete_total_amount)-parseInt(Return_Item_Amount);
        //         }
        //     })
        //     setOrderTotalAmount(complete_total_amount);
        // }
        // else{
        //     setOrderTotalAmount(total_amount);
        // }
        setOrderTotalAmount(total_amount);
        setTotalExpenseFees(total_expense_fees);
        setOrderItemAmount(total_item_amount);
        setShowData(true);
    }
}).catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
})
}
export default Get_Deli_Ways;