import axios from "axios";
import API_EndPoint from "../../URL";
const Get_Collect_Assign_Order_Data = async (
  setTableData,
  setTotalItemAmount,
  setDeliTotalAmount,
  setTotalGateDeliAmount,
  setTotalAllPaidAmount,
  orderTotalAmount,
  queryGateAmount,
  queryAllPaidAmount,
  setDiffAmount,
  setClientNetPay,
  setTotalDeliAmount,
  setShowData
) => {
  var api_endpoint = API_EndPoint.URL;
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  var co_id = urlParams.get("CO_ID");
  var business_name = localStorage.getItem("business_Name");
  await axios
    .get(
      api_endpoint +
        "order/collectassign?CO_ID=" +
        co_id +
        "&Business_Name=" +
        business_name,
        API_EndPoint.Config
    )
    .then((res) => {
      if (res.status === 200) {
        setTableData(res.data);
        var collect_amount = 0;
        var total_gate_deli_amount = 0;
        var total_all_paid_amount = 0;
        var total_deli_price = 0;
        console.log(res.data);
        //getting total all paid amount
        res.data.map(({ Deli_Price, Deli_Type, G_Price, Item_Price }) => {
          if (Deli_Type === "All Paid" || Deli_Type==="OS Paid") {
            total_all_paid_amount += parseInt(Deli_Price);
          }

          total_gate_deli_amount += parseInt(G_Price || 0);
          total_deli_price += parseInt(Deli_Price || 0);
        });
        //getting total gate deli amount
        setTotalGateDeliAmount(total_gate_deli_amount);
        // set total deli amount
        setDeliTotalAmount(total_deli_price);
        //to calculate Net Pay.
        setTotalAllPaidAmount(total_all_paid_amount);
        setTotalDeliAmount(total_deli_price);

        //For showing Diff Amount and Client Net Pay Card
        //alert(queryGateAmount);
        //alert(orderTotalAmount);
        // var diff_amount =
        //   parseInt(collect_amount) -
        //   (parseInt(orderTotalAmount) -
        //     (parseInt(queryGateAmount) + parseInt(queryAllPaidAmount)));
        // var client_net_pay =
        //   parseInt(orderTotalAmount) -
        //   (parseInt(queryGateAmount) + parseInt(queryAllPaidAmount));
        var client_net_pay=urlParams.get("Total_Amount");
        collect_amount=urlParams.get("Collect_Amount");
        setTotalItemAmount(parseInt(collect_amount || 0));
        setClientNetPay(client_net_pay);
        var diff_amount=collect_amount-client_net_pay;
        setDiffAmount(diff_amount);
        setShowData(true);
      }
    })
    .catch((err) => {
      if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
      alert(err.response.data);
    }
    });
};
export default Get_Collect_Assign_Order_Data;
