import { createTheme } from "@mui/material";

const theme =createTheme ({
    palette: {
        primary: {
          main: '#002984',
          superlight: '#e3f2fd'
        },
        secondary:{
          main:'#2656bf'
        },
        success:{
          main:'#2e7d32',
          dark:'#1b5e20',
          light:'#4caf50'
        }
      },
    viewPorts: {
      lg: "1200",
      md: "900"
    }
    // animation: {
    //   scale: 0.0,
    // },
    // fonts:{
    //     regular: {
    //         fontFamily: 'sans-serif',
    //         fontWeight: 'normal',

    //     }
    // }
  });
  export default theme;