import React from "react";
import { Typography, Box } from "@mui/material";

export default function FooterComponent() {
    return (
        <Box 
            component="footer" 
            sx={{
                position: 'fixed',
                left: 0,
                bottom: 0,
                width: '100%',
                backgroundColor: 'primary.main',
                color: 'white',
                textAlign: 'center',
                padding: 1,
            }}
        >
            <Typography variant="body2">
                &copy; {new Date().getFullYear()}. Provided by <a href="https://www.facebook.com/nksoftwareshouse" style={{ color: 'inherit', textDecoration: 'none' }}>NK Software House</a>
            </Typography>
        </Box>
    );
}
