import axios from "axios";
import API_Endpoint from "../../URL";
var business_name = localStorage.getItem("business_Name");
const Get_Return_Item_List=async(clientName,setTableData,setShowData,setWays,setTotalReturnItemAmount)=>{
  clientName==="All"? clientName="%":console.log("Client Name",clientName);
  await axios.get(API_Endpoint.URL+'accounting/returnitem?Client_Name='+clientName+'&Business_Name='+business_name,API_Endpoint.Config)
  .then((res)=>{
    console.log(res);
    if(res.status===200){
      console.log("Return Item List",res);
        setWays(res.data.length);
        setTableData(res.data);
        let total_return_item_amount=0;
        res.data.map(({Return_Item_Amount})=>{
          total_return_item_amount+=parseInt(Return_Item_Amount || 0);
        })
        setTotalReturnItemAmount(total_return_item_amount);
        setShowData(true);
    }
    else{
      setShowData(true);
      setWays(0);
      setTotalReturnItemAmount(0);
    }
  }).catch((err)=>{
    console.log('Error at getting return item list',err);
    alert(err.response.data);    
  })
}
export default Get_Return_Item_List;