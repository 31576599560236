import React,{useEffect} from "react";
import { ListItem,ListItemIcon,ListItemText,List,ThemeProvider,CssBaseline} from "@mui/material";
import {Dashboard,Group,FactCheck,Directions,Storage,AttachMoney,Inventory, Balance} from '@mui/icons-material';
import Theme from '../theme/Theme'
export default function SideMenuComponent({currentMenu}){
    const user_role=localStorage.getItem("user_Role");
    useEffect(() => {
        ChangeMenuColor();
      },[]);
    function ChangeMenuColor(){
        var lightColor=Theme.palette.primary.light;
        if(currentMenu==='Dashboard'){
            document.getElementsByClassName('dashboardMenu')[0].style.color=lightColor;
            document.getElementsByClassName('dashboardMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Account Management'){
            document.getElementsByClassName('accountMenu')[0].style.color=lightColor;
            document.getElementsByClassName('accountMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Data Management'){
            document.getElementsByClassName('dataMenu')[0].style.color=lightColor;
            document.getElementsByClassName('dataMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Order Management'){
            document.getElementsByClassName('orderMenu')[0].style.color=lightColor;
            document.getElementsByClassName('orderMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Deli Order Management'){
            document.getElementsByClassName('deliOrderMenu')[0].style.color=lightColor;
            document.getElementsByClassName('deliOrderMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='OS Order Management'){
            document.getElementsByClassName('orderMenu')[0].style.color=lightColor;
            document.getElementsByClassName('orderMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Way Management'){
            document.getElementsByClassName('wayMenu')[0].style.color=lightColor;
            document.getElementsByClassName('wayMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Accounting Management'){
            document.getElementsByClassName('accountingMenu')[0].style.color=lightColor;
            document.getElementsByClassName('accountingMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='InStock Management'){
            document.getElementsByClassName('inStockMenu')[0].style.color=lightColor;
            document.getElementsByClassName('inStockMenu')[1].style.color=lightColor;
        }
        else if(currentMenu==='Balance Sheet Management'){
            document.getElementsByClassName('balanceSheetMenu')[0].style.color=lightColor;
            document.getElementsByClassName('balanceSheetMenu')[1].style.color=lightColor;
        }
    }
    function MenuRedirectLink(reqPathName){
        var currentPath=window.location.pathname;
        currentPath!==reqPathName?window.location.href=reqPathName:window.location.href='#';
    }
    return(
        <ThemeProvider theme={Theme}>
        <CssBaseline/>
        {user_role==='Admin'?
        <List>
        <ListItem  button key="dashboard" onClick={()=>MenuRedirectLink("/admin/dashboard")}>
                <ListItemIcon  className="dashboardMenu">
                    <Dashboard/>
                </ListItemIcon >
                <ListItemText primary="Dashboard" className="dashboardMenu"/>
            </ListItem>
            <ListItem button key="account"  onClick={()=>MenuRedirectLink("/admin/manage/account")}>
                <ListItemIcon className="accountMenu">
                    <Group/>
                </ListItemIcon>
                <ListItemText primary="Account" className="accountMenu"/>
            </ListItem>
            <ListItem button key="data"  onClick={()=>MenuRedirectLink("/admin/manage/data")}>
                <ListItemIcon className="dataMenu">
                    <Storage/>
                </ListItemIcon>
                <ListItemText primary="Data" className="dataMenu"/>
            </ListItem>
            <ListItem button key="order"  onClick={()=>MenuRedirectLink("/admin/manage/order/client")}>
                <ListItemIcon className="orderMenu">
                    <FactCheck/>
                </ListItemIcon>
                <ListItemText primary="Order" className="orderMenu"/>
            </ListItem>
            <ListItem button key="way" onClick={()=>MenuRedirectLink("/admin/manage/way")}>
                <ListItemIcon className="wayMenu">
                    <Directions/>
                </ListItemIcon>
                <ListItemText primary="Way" className="wayMenu"/>
            </ListItem>
            <ListItem button key="accounting" onClick={()=>MenuRedirectLink("/admin/manage/accounting")}>
                <ListItemIcon className="accountingMenu">
                    <AttachMoney/>
                </ListItemIcon>
                <ListItemText primary="Accounting" className="accountingMenu"/>
            </ListItem>
            <ListItem button key="instock" onClick={()=>MenuRedirectLink("/admin/manage/instock")}>
                <ListItemIcon className="inStockMenu">
                    <Inventory/>
                </ListItemIcon>
                <ListItemText primary="InStock" className="inStockMenu"/>
            </ListItem>
        </List>:
        user_role==='Delivery'?
        <List>
            <ListItem  button key="deli_order" onClick={()=>MenuRedirectLink("/deli/manage/order")}>
                <ListItemIcon  className="deliOrderMenu">
                    <FactCheck/>
                </ListItemIcon >
                <ListItemText primary="Deli Order" className="deliOrderMenu"/>
            </ListItem>
        </List>
        :
        <ListItem button key="order" onClick={()=>MenuRedirectLink("/os/manage/order")}>
            <ListItemIcon className="orderMenu">
                <FactCheck/>
            </ListItemIcon>
            <ListItemText primary="Order" className="orderMenu"/>
        </ListItem>
        }
        </ThemeProvider>
    )
}