import axios from 'axios';
import API_EndPoint from '../URL';
const Get_User_Data=async(setTableData)=>{
var api_endpoint=API_EndPoint.URL;
var business_name=await localStorage.getItem("business_Name")
await axios.get(api_endpoint+'account?Business_Name='+business_name,API_EndPoint.Config).then((res)=>{
    if(res.status===200){
        setTableData(res.data);
    }
}).catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
        alert(err.response.data);
    }
})
}
export default Get_User_Data;