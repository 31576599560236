import axios from "axios";
import API_EndPoint from "../../URL";

const GetGExp = async (setTableData, setShowData, fromDate, toDate, setTotalExpene) => {
    const business_Name = localStorage.getItem("business_Name");
    //console.log(API_EndPoint.URL + "data/gexp?From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + business_Name);
    setShowData(false);
    await axios.get(API_EndPoint.URL + "data/gexp?From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + business_Name,API_EndPoint.Config)
    .then(({data}) => {
        let totalExpense = 0;
        data.map(expense => totalExpense += parseInt(expense.General_Expense_Amount || 0));
        setTotalExpene(totalExpense);
        setTableData(data);
        setShowData(true);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default GetGExp;