import axios from "axios";
import API_EndPoint from "../../URL";

const Create_Other_Income=async(postData, setLoading,setShowForm)=>{
    const api_endpoint=API_EndPoint.URL;
    await axios.post(api_endpoint+'manage/data/otherincome',postData,API_EndPoint.Config).then((res)=>{
        setLoading(true);
        if(res.status===201){
            setLoading(false);
            setShowForm(false);
            setShowForm(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })

}
export default Create_Other_Income;