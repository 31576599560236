import axios from "axios";
import API_EndPoint from "../URL";
const Update_Deli_Men_Ways=async(updateData,setLoading,setShowEditForm)=>{
    setLoading(true);
    var api_endpoint=API_EndPoint.URL;
    await axios.put(API_EndPoint.URL + "instock?CAO_ID=" + updateData.CAO_ID + "&Deli_Men=" + updateData.Deli_Men + "&Deli_Status=" + updateData.Deli_Status + "&Business_Name=" + updateData.Business_Name ,{},API_EndPoint.Config).then((res)=>{
        if(res.status===202){
            setLoading(false);
            setShowEditForm(false);
            window.location.reload();
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert("error");
        alert(err.response.data);
        }
        setLoading(false);
    })
}
export default Update_Deli_Men_Ways;