import axios from "axios";
import API_EndPoint from "../../URL";
import Get_City_Data from "./GetCityController";
const Create_City=async(postData,setLoading,setShowForm)=>{
var api_endpoint=API_EndPoint.URL;
setLoading(true);
await axios.post(api_endpoint+'data/city',postData,API_EndPoint.Config).then((res)=>{
    if(res.status===201){
        //getting city list and store in local
        alert(res.data);
        setLoading(false);
        setShowForm(false);
    }
}).catch((err)=>{
    setLoading(false);
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
})
}
export default Create_City;