import axios from "axios";
import API_EndPoint from "../URL";

const getInStockData = async (business_Name, setTotalItemAmount, deliStatus) => {
    if(deliStatus==="Rejected"){
        await axios.get(API_EndPoint.URL+"accounting/rejected?Client_Name=%&Rejected_Status=Pending&Business_Name=" + business_Name,API_EndPoint.Config).then(({data})=>{
            let totalItemPrice = 0;
            data.map(each => 
            parseInt(each.Return_Item_Amount || 0)===0?
            totalItemPrice += parseInt(each.Item_Price || 0):
            totalItemPrice += parseInt(each.Return_Item_Amount || 0))
            setTotalItemAmount(totalItemPrice);
        })
    }
    else{
        await axios.get(API_EndPoint.URL + "instock?Deli_Men=" + "%" + "&Deli_Status=" + deliStatus + "&Business_Name=" + business_Name,API_EndPoint.Config).then((res)=>{
            if(res.status===200){
                let totalItemPrice = 0;
                res.data.map(item => totalItemPrice += item.Item_Price || 0);
                setTotalItemAmount(totalItemPrice);
            }
        }).catch((err)=>{
            if(err.response.status===401){
                // Toastify("warning","Session Expired",setShowToast);
                alert("Session Expired");
                localStorage.setItem("Login_Status",false);
                window.location.replace("/");
            }
            else{
            alert(err.response.data);
            }
        })
    }

    }
export default getInStockData;