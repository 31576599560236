import axios from "axios";
import API_EndPoint from "../../URL";

const GetProfit = async (fromDate, toDate, setTableData, setShowData, setNetProfit, setDeliPrice, setDeliExpense, setGeneralExpense, setWays) => {
    const businessName = localStorage.getItem("business_Name");
    const deliList = {};
    setShowData(false);
    //console.log(API_EndPoint.URL + "accounting/profit?From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + businessName);
    await axios.get(API_EndPoint.URL + "accounting/profit?From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + businessName,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
                setTableData(res.data);
                //setting total Deli Price card and total Expense Fees card
                let totalDeliPrice = 0,totalDeliExpense = 0,totalGeneralExpense = 0,totalNetProfit = 0,totalWays = 0;
                res.data.map(({Total_Deli,Deli_Expense,Net_Profit,Ways})=>{
                    totalDeliPrice+=parseInt(Total_Deli || 0);
                    totalDeliExpense+=parseInt(Deli_Expense || 0);
                    totalNetProfit+=parseInt(Net_Profit || 0 );
                    totalWays+=parseInt(Ways || 0);
                })
                setDeliPrice(totalDeliPrice);
                setDeliExpense(totalDeliExpense);
                setWays(totalWays);
                //calling general expense total by date
                axios.get(API_EndPoint.URL + "data/gexp?From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + businessName,API_EndPoint.Config)
                .then((res) => {
                    if(res.status===200){
                        res.data.map(({General_Expense_Amount})=>{
                            totalGeneralExpense+=parseInt(General_Expense_Amount || 0);
                        })        
                        totalNetProfit=totalNetProfit-totalGeneralExpense;
                        setNetProfit(totalNetProfit);
                        setGeneralExpense(totalGeneralExpense);
                    }
                }).catch((err)=>{
                    alert(err.response.data);
                })
                setShowData(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    });

}

export default GetProfit;