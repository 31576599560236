import React,{useEffect} from "react";
import SideDrawerComponent from '../../../components/SideDrawer'

export default function Dashboard(){
    return(
        <>
           <SideDrawerComponent headerText="Dashboard"/>
        </>

    )
}