import Counter from "./Counter";
import {Card, Skeleton, Typography} from "@mui/material";
import palette from "../palette";

const NormalCard = ({card, loading, amount, label, color, children}) => {
    return (
        <Card className={card}>
            <>
            <div className="mainWrap">
                {loading ? (
                <Skeleton
                    animation="wave"
                    style={{ width: "80%", height: "1.7rem" }}
                />
                ) : (
                <Typography variant="p" className="main">
                    <Counter from={0} to={amount} />
                </Typography>
                )}
                <div
                className="iconWrap"
                style={{backgroundColor: palette[color]["light"]}}
                >
                {loading ? (
                    <Skeleton
                    animation="wave"
                    variant="circular"
                    className="icon"
                    />
                ) : (
                   children
                )}
                </div>
            </div>
            {loading ? (
                <Skeleton
                animation="wave"
                style={{ width: "40%", height: "1.2rem" }}
                />
            ) : (
                <Typography component="h2" className="title">
                    {label}
                </Typography>
            )}
            </>
        </Card>
    )
};

export default NormalCard;