import axios from 'axios';
import API_EndPoint from '../../URL';
const Create_Collect_Assign_Order=async(postData,setLoading,setShowForm)=>{
    setLoading(true);
var api_endpoint=API_EndPoint.URL;
console.log("Post Data",postData);
    await axios.post(api_endpoint+'order/collectassign',postData,API_EndPoint.Config).then((res)=>{
        if(res.status===201){
            setLoading(false);
            setShowForm(false);
            setShowForm(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
        setLoading(false);
    })
}
export default Create_Collect_Assign_Order;