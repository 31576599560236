import React,{useEffect} from "react";
import { Button,CssBaseline,ThemeProvider,Stack,IconButton } from "@mui/material";
import { AddCircle,Edit,Delete,KeyboardArrowDown,KeyboardArrowRight } from "@mui/icons-material";
import Theme from '../../../../theme/Theme';
import CreateFormComponent from "../../../../components/CreateForm";
import DataTableComponent from "../../../../components/DataTable";
import Get_User_Data from "../../../../api/Account/GetUserData";
import Delete_Account from "../../../../api/Account/DeleteAccountController";
import EditFormComponent from "../../../../components/EditForm";
// testing wrapper
// import ApiWrap from "../../../../api/ApiWrap";
// import getUserDataModified from "../../../../api/Account/GetUserDataModified";
// import deleteAccountModified from "../../../../api/Account/DeleteAccountModified";
// import registerAccountModified from "../../../../api/Account/RegisterAccountModified";
// import updateAccountModified from "../../../../api/Account/UpdateAccountModified";
// end
export default function AccountManagePage() {
    const[showForm,setShowForm]=React.useState(false);
    const[showEditForm,setShowEditForm]=React.useState(false);
    const[showData,setShowData]=React.useState(false);
    const[currentData,setCurrentData]=React.useState({});
    const[tableData,setTableData]=React.useState();
    const columns = ["SrNo","User_Name","Phone_Number","User_Role",{name:"Client_Name",label:"Client_Name",options:{display:"none"}},"C_Name",{name:"T_Name",lable:"T_Name",options:{display:"none"}},"Password","Email","Create_Date",
    {
      name: "User_ID",
      options: {
        display: false,
      }
    }, 
    {
      name: "\n\n\n\n\n\nACTION",
      options: {
          setCellProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 100
              }
            }),
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                background: "white",
                zIndex: 101
              }
            }),
          customBodyRenderLite: (dataIndex, value, tableMeta, updateValue) => {
              return (
                  
                  <Stack direction="row" spacing={1}>
                      <IconButton color="primary" aria-label="edit" onClick={() =>UpdateAccount(dataIndex)} >
                          <Edit />
                      </IconButton>
                      <IconButton color="error" aria-label="delete" onClick={() =>DeleteAccount(dataIndex)}>
                          <Delete />
                      </IconButton>
                  </Stack>
              )
          }
      }
  }
  ];
  const UpdateAccount=(dataIndex)=>{
      setCurrentData({
        User_ID:tableData[dataIndex]["User_ID"],
        User_Name:tableData[dataIndex]["User_Name"],
        Phone_Number:tableData[dataIndex]["Phone_Number"],
        Address:tableData[dataIndex]["Address"],
        UserRole:tableData[dataIndex]["User_Role"],
        ClientName:tableData[dataIndex]["Client_Name"],
        Email:tableData[dataIndex]["Email"],
        City:tableData[dataIndex]["C_Name"],
        Township:tableData[dataIndex]["T_Name"],
        Password:tableData[dataIndex]["Password"]
      })
      setShowEditForm(true);
    }
  const DeleteAccount=(dataIndex)=>{
      var user_id=tableData[dataIndex]["User_ID"];
      Delete_Account(user_id);
      // testing
      // ApiWrap(["account"], {"User_ID": user_id}, deleteAccountModified, {}, "delete");
      // end
    }  
  const handleTableData=()=>{
   if(showData===false){
     setShowData(true);
     Get_User_Data(setTableData);
    // testing
    // ApiWrap(["account"], {}, getUserDataModified, {setTableData}, "get");
    // end
   }
   else{
     setShowData(false);
   }
  }
  useEffect(() => {
    setShowData(true);
    Get_User_Data(setTableData);
  },[]);
  return (
    <>
    {/* <SideDrawerComponent headerText="Account Management"/> */}
    <ThemeProvider theme={Theme}>
        <CssBaseline/>
        <Button  variant="contained" startIcon={<AddCircle/>} onClick={()=>setShowForm(true)}>Add Account</Button><br/><br/><br/>
        {showData?[
        <Button   sx={{alignSelf:'flex-start',fontWeight:'bold'}} endIcon={<KeyboardArrowDown/>} onClick={()=>handleTableData()}>Hide Data</Button>,<br/>,<br/>,<br/>,
        <DataTableComponent title="Account List" data={tableData} columns={columns}/>]:
        <Button   sx={{alignSelf:'flex-start',fontWeight:'bold'}} endIcon={<KeyboardArrowRight/>} onClick={()=>handleTableData()}>Show Data</Button>}
        {showForm? <CreateFormComponent  headerText="Account Form" formType="Account" actionType="createAccount" buttonText="Create" setShowForm={setShowForm}/>:<></>}
        {showEditForm?<EditFormComponent actionType="editAccount" buttonText="Update" currentData={currentData} formType="Account" headerText="Edit Account" setShowEditForm={setShowEditForm}/>:<></>}
    </ThemeProvider>

    </>
  );

}
