import axios from "axios";
import API_EndPoint from "../../URL";

const UpdateOsPending = async (updateData, setLoading, setShowEditForm) => {
    // console.log(updateData[]);
    //console.log(API_EndPoint.URL + "accounting/ospending?" + "CAO_ID=" + updateData["CAO_ID"] + "&Deli_Men=" + updateData.Deli_Men + "&Deli_Status=" + updateData.Deli_Status + "&Business_Name=" + updateData.Business_Name);
    await axios.put(API_EndPoint.URL + "accounting/ospending?" + "CAO_ID=" + updateData["CAO_ID"] + "&Deli_Men=" + updateData.Deli_Men + "&Deli_Status=" + updateData.Deli_Status + "&Business_Name=" + updateData.Business_Name,{},API_EndPoint.Config)
    .then(() => {
        setLoading(false);
        setShowEditForm(false);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default UpdateOsPending;