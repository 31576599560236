import axios from "axios";
import API_EndPoint from "../../URL";
const Get_City_Data=async(setTableData,setShowData)=>{
var api_endpoint=API_EndPoint.URL;
var business_name=localStorage.getItem("business_Name");
await axios.get(api_endpoint+'data/city?Business_Name='+business_name,API_EndPoint.Config).then((res)=>{
    if(res.status===200){
        console.log(res.data);
        setTableData(res.data);
        setShowData(true);
    }
}).catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
})
}
export default Get_City_Data;