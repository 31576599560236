import React from "react";
import { ToastContainer } from "react-toastify";
export default function ToastContainerComponent(){
    return(
        <ToastContainer
      position="top-center"
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
    )
}