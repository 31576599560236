import axios from "axios";
import API_EndPoint from "../URL";
const Update_Deli_Men_Ways = async (updateData,setLoading,setShowEditForm, showBtn, hideBtn) => {
    setLoading(true);
var api_endpoint=API_EndPoint.URL;
await axios.put(api_endpoint+'delimen', updateData,API_EndPoint.Config).then((res) => {
    if(res.status===202){
        setLoading(false);
        setShowEditForm(false);
        hideBtn.current.click();
        setTimeout(() => {
            showBtn.current.click();
        }, 1000);
    }
}).catch((err) => {
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
    setLoading(false);
})
}
export default Update_Deli_Men_Ways;