import axios from "axios";
import API_EndPoint from "../URL";
const Accepted_Way_By_Deli_Men = async (selectedDeliUsername) => {
  if (window.confirm("Are you sure to accepted?")) {
    var api_endpoint = API_EndPoint.URL;
    var business_name = localStorage.getItem("business_Name");
    selectedDeliUsername = selectedDeliUsername === "All" ? "%" : selectedDeliUsername;
    // alert(api_endpoint + "manage/way/accept?Deli_Men="+selectedDeliUsername+"&Business_Name="+business_name);
    await axios
      .put(api_endpoint +"manage/way/accept?Deli_Men="+selectedDeliUsername+"&Business_Name="+business_name,{},API_EndPoint.Config)
      .then((res) => {
        if (res.status === 202) {
          window.location.reload();
        }
      })
      .catch((err) => {
        if(err.response.status===401){
          // Toastify("warning","Session Expired",setShowToast);
          alert("Session Expired");
          localStorage.setItem("Login_Status",false);
          window.location.replace("/");
      }
      else{
        alert(err.response.data);
      }
      });
  }
};
export default Accepted_Way_By_Deli_Men;
