import axios from 'axios';
import API_EndPoint from '../../URL';
const Create_Order=async(postData,setLoading,setShowForm)=>{
    setLoading(true);
var api_endpoint = API_EndPoint.URL;
    // console.log(postData);
    // console.log("Within crate api")
    await axios.post(api_endpoint+'order/client',postData,API_EndPoint.Config).then((res)=>{
        if(res.status===201){
            setLoading(false);
            window.location.reload();
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
        setLoading(false);
    })
}
export default Create_Order;