import {Card, Grid, Typography, Skeleton, Box, LinearProgress} from "@mui/material";
import Counter from "./Counter";
import palette from "../palette";

const LinearProgressWithLabel = ({ value, color, type }) => {
    value = isNaN(value) ? 0 : value;
    return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1, color: palette[color]["super"] }}>
            <LinearProgress
            variant="determinate"
            value={value}
            color="inherit"
            sx={{ backgroundColor: type == "solid" ? palette[color]["main"] : "#fff" }}
            />
        </Box>
        <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2" color={type === "solid" ? color == "amber" ? "#000" : "#fff" : "#000"}>{`${Math.round(
            value
            )}%`}</Typography>
        </Box>
        </Box>
    );
};

const ColorCard = ({card, loading, amount, label, color, percent, type}) => {
    return (
        <Card
            className={card}
            sx={{backgroundColor: type === "solid" ? palette[color]["main"] : palette[color]["light"], color: (type === "solid" ? color == "amber" ? "#000" : "#fff" : "#000")}}
        >
            {loading ? (
            <>
                <Grid container spacing={3} sx={{alignItems: "center"}}>
                <Grid item xs={9}>
                    <Skeleton
                        animation="wave"
                        style={{ height: "2.5rem" }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Skeleton
                        animation="wave"
                        style={{ height: "1rem" }}
                    />
                </Grid>
                </Grid>
                <Skeleton
                    animation="wave"
                    style={{ width: "100%", height: "1.2rem" }}
                />
            </>
            ) : (
            <>
                <Typography
                    variant="h4"
                    component="p"
                    className="cityText"
                >
                <Counter
                    from={0}
                    to={amount}
                />
                <sup style={{fontSize: "14px", marginLeft: "1rem"}}><em>{label}</em></sup>
                </Typography>
                <LinearProgressWithLabel
                    value = {percent}
                    color = {color}
                    type = {type}
                />
            </>
            )}
        </Card>
    )
};

export default ColorCard;