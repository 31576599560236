import axios from "axios";
import API_EndPoint from "../../URL";

const Get_Return_Data = async (
  clientName,
  selectedDebitType,
  paymentStatus,
  fromDate,
  toDate,
  setTableData,
  setTotalReturnItemAmount,
  setShowData,
  setShowOverall,
  setWays
) => {
  var api_endpoint = API_EndPoint.URL;
  var business_name = localStorage.getItem("business_Name");
  let dateQuery = "";
  if (paymentStatus == "Completed"){
    dateQuery = "&From_Date=" + fromDate + "&To_Date=" + toDate;
  }
  if (clientName == "All") {
    clientName = "%";
  }
  var return_amount=0;
  await axios
    .get(
      api_endpoint +
        "accounting/debit?Client_Name=" +
        clientName +
        "&Debit_Type=" +
        selectedDebitType +
        "&Payment_Status=" +
        paymentStatus +
        dateQuery +
        "&Business_Name=" +
        business_name,
        API_EndPoint.Config
    )
    .then((res) => {
      if(res.status===200){
        setTableData(res.data);
        setWays(parseInt(res.data.length || 0));
        res.data.map(({Return_Item_Amount})=>{
            return_amount+=parseInt(Return_Item_Amount || 0);
        })
      }
      setTotalReturnItemAmount(return_amount);
      setShowData(true);
      setShowOverall(true);
      })
    .catch((err) => {
      if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
      alert(err.response.data);
    }
    });
};
export default Get_Return_Data;
