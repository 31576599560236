import axios from "axios";
import API_EndPoint from "../../URL";

const GetOsReport = async (selectedClientName,fromDate,toDate,setTableData,setCollectItemAmount,setPendingItemAmount,setAssignItemAmount,setOnWayItemAmount,setCompleteItemAmount,setAcceptItemAmount,setInstockItemAmount,setRejectItemAmount,setShowData,setWays) => {
    const businessName = localStorage.getItem("business_Name");
    if(selectedClientName==='All'){
        selectedClientName="%";
    }
    await axios.get(API_EndPoint.URL + "accounting/osreport?" + "Client_Name=" + selectedClientName +"&From_Date="+fromDate+"&To_Date="+toDate+"&Business_Name=" + businessName,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            setTableData([]);
            setWays(res.data.length);
            var collect_item_amount=0;var pending_item_amount=0;var onway_item_amount=0;var assign_item_amount=0;var complete_item_amount=0;var accept_item_amount=0;var instock_item_amount=0;var reject_item_amount=0;
            res.data.map(({Item_Price,Deli_Status})=>{
                collect_item_amount+=parseInt(Item_Price || 0);
                if(Deli_Status==="Pending"){
                    pending_item_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Assigned"){
                    assign_item_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="OnWay"){
                    onway_item_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Completed"){
                    complete_item_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Accepted"){
                    accept_item_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Instock"){
                    instock_item_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Rejected"){
                    reject_item_amount+=parseInt(Item_Price || 0);
                }
            })     
            setCollectItemAmount(collect_item_amount);
            setPendingItemAmount(pending_item_amount);
            setAssignItemAmount(assign_item_amount);
            setOnWayItemAmount(onway_item_amount);
            setCompleteItemAmount(complete_item_amount);
            setAcceptItemAmount(accept_item_amount);
            setInstockItemAmount(instock_item_amount);
            setRejectItemAmount(reject_item_amount);       
            setTableData(res.data);
            setShowData(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert
        (err.response.data);
        }
    })
   
}

export default GetOsReport;