import axios from "axios";
import API_EndPoint from "../../URL";

const UpdateOtherIncome = async (postData, setShowEditForm, showButton, hideButton) => {
    await axios.put(API_EndPoint.URL + "manage/data/otherincome", postData,API_EndPoint.Config)
    .then(({status, data}) => {
        if (status === 202) {
            setShowEditForm(false);
            hideButton.current.click();
            setTimeout(() => {
                showButton.current.click();
            }, 1000);
        }
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default UpdateOtherIncome;