import axios from "axios";
import API_EndPoint from "../../URL";
import moment from "moment";

const GetOverall = async (setTotalOverall, clientName, setShowData, setShowOverall,setWays) => {

    const fromDate = moment(new Date).format("YYYY-MM-DD");
    const toDate = fromDate;
    setShowData(false);
    setShowOverall(false);
    const paymentStatus = "Pending";
    (clientName === null || clientName === "All") ? (clientName = "%") : console.log("");
    const business_Name = localStorage.getItem("business_Name");

    await axios.get(API_EndPoint.URL + 'accounting/debit?Client_Name=' + clientName + '&Debit_Type=' + "To Pay" + '&Payment_Status=' + paymentStatus + '&From_Date=' + fromDate + '&To_Date=' + toDate + '&Business_Name=' + business_Name,API_EndPoint.Config)
    .then(async ({data}) => {
        setWays(data.length);
        var totalToPay = 0;
        data.map(({Total_Amount, Gate_Amount, All_Paid_Amount}) => totalToPay += parseInt(Total_Amount || 0) - (parseInt(Gate_Amount || 0) + parseInt(All_Paid_Amount || 0)));
        await axios.get(API_EndPoint.URL + 'accounting/debit?Client_Name=' + clientName + '&Debit_Type=' + "To Get" + '&Payment_Status=' + paymentStatus + '&From_Date=' + fromDate + '&To_Date=' + toDate + '&Business_Name=' + business_Name,API_EndPoint.Config)
        .then(async ({data}) => {
            var totalToGet = 0;
            data.map(({Total_Amount})=> totalToGet += parseInt(Total_Amount || 0));
           await axios.get(API_EndPoint.URL + 'accounting/debit/overall/rejected?Client=' + clientName + '&Business_Name=' + business_Name,API_EndPoint.Config)
           .then(({data}) => {
            //    with rejected
            setTotalOverall({overall: (totalToPay - (totalToGet + parseInt(data[0].Rejected_Amount || 0))), toPay: totalToPay, toGet: totalToGet, rejectedAmount: parseInt(data[0].Rejected_Amount || 0)});
            setShowData(true);
            console.log(data);
            setShowOverall(true);
           })
           .catch((err)=>{
            if(err.response.status===401){
                // Toastify("warning","Session Expired",setShowToast);
                alert("Session Expired");
                localStorage.setItem("Login_Status",false);
                window.location.replace("/");
            }
            else{
                alert(err.response.data);
            }
           })
        })
        .catch((err)=>{
            if(err.response.status===401){
                // Toastify("warning","Session Expired",setShowToast);
                alert("Session Expired");
                localStorage.setItem("Login_Status",false);
                window.location.replace("/");
            }
            else{
                alert(err.response.data);
            }
        })

    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default GetOverall;