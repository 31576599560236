import React, {useState} from "react";
import { ThemeProvider } from "@mui/styles";
import theme from "../theme/Theme";
import { Switch } from "@mui/material";
import Update_To_Pay_Payment_Status from "../api/Accounting/Debit/UpdateToPayPaymentStatus";
import Update_To_Get_Payment_Status from "../api/Accounting/Debit/UpdateToGetPaymentStatus";
import Update_Rejected_Payment_Status from "../api/Accounting/Debit/UpdateRejectedController";
import Update_Return_Payment_Status from "../api/Accounting/Debit/UpdateReturnPaymentStatus";

const CustomSwitch = ({caoId, paymentStatus, clientDeliName, debitType}) => {
    console.log(caoId);
    const [current, setCurrent] = useState({checked: false, disabled: false});
    return (
        <ThemeProvider theme={theme}>
            <Switch color="success" disabled={paymentStatus === "Completed" || current.disabled} checked={current.checked} defaultChecked={paymentStatus === "Completed"} onChange={() => debitType === "To Pay" ? Update_To_Pay_Payment_Status(caoId,clientDeliName,setCurrent) : debitType === "Transfer"? Update_To_Get_Payment_Status(caoId,clientDeliName,setCurrent): debitType === "Return Item"? Update_Return_Payment_Status(caoId,clientDeliName,setCurrent)  : Update_Rejected_Payment_Status(caoId,paymentStatus,setCurrent)} /> 
        </ThemeProvider>
        
    )
}

export default CustomSwitch;