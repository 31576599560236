import axios from "axios";
import API_EndPoint from "../../URL";
const Update_Client_Order=async(updateData,setLoading,setShowEditForm)=>{
    setLoading(true);
var api_endpoint=API_EndPoint.URL;
await axios.put(api_endpoint+'order/client',updateData,API_EndPoint.Config).then((res)=>{
    if(res.status===202){
        setLoading(false);
        setShowEditForm(false);
        //window.location.reload();
    }
}).catch((err)=>{
    if(err.response.status===401){
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
    setLoading(false);
})
}
export default Update_Client_Order;