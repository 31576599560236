import axios from "axios";
import API_EndPoint from "../../URL";

const GetAllClients = async (setClientData, business_Name) => {
    await axios
          .get(API_EndPoint.URL + "data/client?Business_Name=" + business_Name,API_EndPoint.Config)
          .then(({data, status}) => {
            if (status === 200) {
                setClientData((clientData) => [...clientData, ...data.map((clientData) => clientData.Client_Name)]);
            }
          })
          .catch((err) => {
                if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
            alert("Error At Getting Client Name - ", err.response.data);
    }
          });
}

export default GetAllClients;