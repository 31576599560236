import axios from "axios";
import API_EndPoint from "../../URL";
const Get_Other_Income=async(setTableData,setShowData, setTotalOtherToPay)=>{
    const api_endpoint=API_EndPoint.URL;
    const business_name=localStorage.getItem("business_Name");
    await axios.get(api_endpoint+'manage/data/othertopay?Business_Name='+business_name,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            setTableData(res.data);
            let totalOtherToPay = 0;
            res.data.map(each => totalOtherToPay += parseInt(each.Other_ToPay_Amount || 0));
            setTotalOtherToPay(totalOtherToPay);
            setShowData(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })
}
export default Get_Other_Income;