import axios from "axios"
import API_EndPoint from "../URL";

const Get_All_Deli_Account = async (setDeliMen, business_Name) => {
    await axios.get(API_EndPoint.URL + "account/deli?Business_Name=" + business_Name,API_EndPoint.Config)
    .then(({data}) => {
        data.map(({User_Name})=>{
            setDeliMen(deliMen=>[...deliMen,User_Name]);
        })
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default Get_All_Deli_Account;