import React, { useEffect } from 'react';
import AppBarComponent from '../../components/AppBar';
import FooterComponent from '../../components/Footer';
import { CssBaseline, Container, Box, Typography, IconButton, ThemeProvider, InputAdornment, Avatar, TextField } from '@mui/material';
import { Lock, Phone, Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import LoginController from '../../api/Account/LoginController';
import Theme from '../../theme/Theme';
import authStyles from './authStyle';
import logo from '../../assets/logo.png';  // Imported logo

export default function ButtonAppBar({ history }) {
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [userPWD, setUserPWD] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [togglePassword, setTogglePassword] = React.useState('password');

    const handleClickShowPassword = () => {
        setTogglePassword(togglePassword === 'password' ? 'text' : 'password');
    };

    useEffect(() => {
        // Clear all localStorage
        localStorage.clear();
        // Checking if already logged in
    }, []);

    const Authentication = async () => {
        setLoading(true);
        const postData = {
            "Phone_Number": phoneNumber,
            "Password": userPWD
        };
        await LoginController(postData);
        setLoading(false);
    };

    return (
        <ThemeProvider theme={Theme}>
            <AppBarComponent headerText='Delivery System Login' showMenuIcon={false} showLogout={false} />
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box sx={{ flexGrow: 1 }}></Box>
                <Box sx={authStyles.container}>
                    {/* Use the imported logo directly */}
                    <Avatar src={logo}  sx={{ m: 1, bgcolor: "primary.main",width:70,height:70 }} />
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Box
                        component="form"
                        noValidate
                        sx={{ mt: 1, width: 'auto' }}
                    >
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="input-with-icon-textfield"
                            label="Phone Number"
                            name="text"
                            value={phoneNumber}
                            autoComplete="text"
                            autoFocus
                            variant="standard"
                            onChange={(event) => setPhoneNumber(event.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="Enter Phone Number"
                                        >
                                            <Phone />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            value={userPWD}
                            label="Password"
                            type={togglePassword}
                            id="password"
                            autoComplete="current-password"
                            variant="standard"
                            onChange={(event) => setUserPWD(event.target.value)}
                            sx={{ mb: 3 }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {togglePassword === 'password' ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <LoadingButton
                            loading={loading}
                            loadingIndicator={"Logging in..."}
                            fullWidth
                            variant="contained"
                            style={{
                                backgroundColor: Theme.palette.primary.main.dark,
                                borderRadius: 8,
                                color: loading ? "transparent" : "white",
                                fontWeight: "bold",
                            }}
                            onClick={() => Authentication()}
                        >
                            Log In
                        </LoadingButton>
                    </Box>
                </Box>
            </Container>
            <div style={{flex:1,display:'flex',marginTop:20,justifyContent:'center',alignItems:'center'}}>
            <FooterComponent />
            </div>
        </ThemeProvider>
    );
}
