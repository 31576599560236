import axios from "axios";
import API_EndPoint from "../../URL";
const Get_Other_Income=async(setTableData,setShowData, setTotalOtherIncome)=>{
    const api_endpoint=API_EndPoint.URL;
    const business_name=localStorage.getItem("business_Name");
    await axios.get(api_endpoint+'manage/data/otherincome?Business_Name=' + business_name,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            setTableData(res.data);
            let totalOtherIncome = 0;
            // console.log(res.data);
            res.data.map(each => totalOtherIncome += parseInt(each.Other_Income_Amount || 0));
            setTotalOtherIncome(totalOtherIncome);
            setShowData(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })
}
export default Get_Other_Income;