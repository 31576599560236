import axios from "axios";
import API_EndPoint from "../../URL";

const UpdateDebitToPay = async (updateData, setLoading, setShowEditForm) => {
    await axios.put(API_EndPoint.URL + "accounting/debit/topay" + "?CO_ID=" + updateData.CO_ID +"&To_Pay_Paid_Amount=" + updateData.To_Pay_Paid_Amount + "&Business_Name=" + updateData.Business_Name,{},API_EndPoint.Config)
    .then(() => {
        setLoading(false);
        setShowEditForm(false);
        updateData.setShowData(false);
        setTimeout(() => {
            updateData.setShowData(true);
        }, 1000);
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
    
};

export default UpdateDebitToPay;