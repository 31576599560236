import axios from 'axios';
import API_EndPoint from '../URL';
const Get_Deli_Account=async(C_Name,T_Name,business_name,setDeliUserName,deliUserName,setSelectedDeliUsername)=>{
var api_endpoint=API_EndPoint.URL;
await axios
//getting all delivery men name
.get(
  api_endpoint + "account/deli?C_Name="+C_Name+"&T_Name="+T_Name+"&Business_Name=" + business_name,API_EndPoint.Config
)
.then((res) => {
  if (res.status === 200) {
    //getting deli men list for way management
    if(C_Name===null && T_Name===null){
      console.log("CName TName is null");
      console.log("Deli List",res.data);
      res.data.map(({User_Name})=>{
      setDeliUserName(deliUserName=>[...deliUserName,User_Name]);
      })

    }
    else{
      res.data.map(({ User_Name,User_ID }) => {
        console.log("Selected CollectAssign Deli User ID",User_ID);
        setSelectedDeliUsername(User_Name);
      });
    }
  }
})
.catch((err) => {
  if(err.response.status===401){
    // Toastify("warning","Session Expired",setShowToast);
    alert("Session Expired");
    localStorage.setItem("Login_Status",false);
    window.location.replace("/");
}
else{
  alert("Error At Getting Deli Account Name-", err.response.data);
}
});
}
export default Get_Deli_Account;