import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Toastify(type, message,setShowToast) {
  setShowToast(true);
  console.log("Type", type, "Message", message);
  type === "info"
    ? toast(message)
    : type === "warning"
    ? toast.warning(message)
    : type === "success"
    ? toast.success(message)
    : toast.error(message);

  return (
    <ToastContainer
      position="top-center"
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      autoClose={true}
    />
  );
}
