import axios from "axios";
import API_EndPoint from "../URL";
const Get_Ways=async(setTotalWays,selectedDeliUsername,deliStatus,deliType,setTableData,setTotalReturnItemAmount,setTotalItemPrice,setTotalAmount,setTotalExpenseFees,setTotalDeli,setShowData,tableData,fromDate,toDate)=>{
var api_endpoint=API_EndPoint.URL;
var business_name=localStorage.getItem("business_Name");
selectedDeliUsername==="All"?selectedDeliUsername="%":console.log("");
deliStatus==="All"?deliStatus="%":console.log("");
deliType==="All"?deliType="%":console.log("");
//if deli status is not all then deli type must be null beacuse user want to check by delistatus not need to consider deli type
if(deliStatus!=="%"){
    deliType=null;
}
var pathUrl;
if(fromDate===null && toDate===null){
    pathUrl='manage/way?Deli_Men='+selectedDeliUsername+'&Deli_Status='+deliStatus+'&Deli_Type='+deliType+'&Business_Name='+business_name+'&From_Date&To_Date';
    await axios.get(api_endpoint+pathUrl,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            //calculate total and showing total item price and total item amount
            var total_expense_fees=0;
            var total_item_price=0;
            var return_item_amount=0;
            var total_amount=0;
            var total_deli=0;
            res.data.map(({Expense_Fees,Deli_Price,Item_Price,Return_Item_Amount,Total_Amount,Deli_Type})=>{
                console.log("Return Item Amount",Return_Item_Amount);
                total_expense_fees+=parseInt(Expense_Fees);
                total_deli+=parseInt(Deli_Price || 0);
                return_item_amount+=parseInt(Return_Item_Amount || 0);
                total_item_price+=parseInt(Item_Price);
                //Deli_Type!=="Transfer"?
                total_amount+=parseInt(Total_Amount)
                //console.log("");
            })
            setTotalWays(res.data.length);
            setTotalExpenseFees(total_expense_fees);
            setTotalDeli(total_deli);
            setTotalReturnItemAmount(return_item_amount);
            setTotalItemPrice(total_item_price);
            setTotalAmount(total_amount);
            //if deli status is completed then show column by group deli men
            var result = [];
            if(selectedDeliUsername==='%' && deliStatus=='Completed'){
                res.data.reduce(function(res, value) {
                    if (!res[value.Deli_Men]) {
                        res[value.Deli_Men] = { SrNo: value.SrNo,Deli_Men:value.Deli_Men,Total_Amount:0,Expense_Fees:0};
                        result.push(res[value.Deli_Men])
                    }
                    res[value.Deli_Men].Total_Amount += (parseInt(value.Total_Amount || 0)- parseInt(value.Expense_Fees || 0)  );
                    return res;
                    }, {});
                    setTableData(result);
            }
            else{
                console.log("Data Length",res.data.length);
                setTableData(res.data);
            }
        }
        setShowData(true);
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })
}
else{
    console.log("From Date",fromDate);
    console.log("To Date",toDate);
    pathUrl='manage/way?Deli_Men='+selectedDeliUsername+'&Deli_Status='+deliStatus+'&Business_Name='+business_name+'&From_Date='+fromDate+'&To_Date='+toDate;
    await axios.get(api_endpoint+'manage/way?Deli_Men='+selectedDeliUsername+'&Deli_Status='+deliStatus+'&Business_Name='+business_name+'&From_Date='+fromDate+'&To_Date='+toDate,API_EndPoint.Config).then((res)=>{
        console.log('Total Ways',res.data.length);
        if(res.status===200){
            //calculate total and showing total item price and total item amount
            var total_expense_fees=0;
            var total_item_price=0;
            var total_amount=0;
            var total_deli=0;
            res.data.map(({SrNo,Deli_Men,Deli_Price,Expense_Fees,Item_Price,Total_Amount,Deli_Type})=>{
                total_expense_fees+=parseInt(Expense_Fees);
                total_deli+=parseInt(Deli_Price || 0);
                total_item_price+=parseInt(Item_Price);
                //Deli_Type!=="Transfer"?
                total_amount+=parseInt(Total_Amount);
            })
            setTotalWays(res.data.length);
            setTotalExpenseFees(total_expense_fees);
            setTotalDeli(total_deli);
            setTotalItemPrice(total_item_price);
            setTotalAmount(total_amount);
            //grouping by deli men  
            var result = [];
            if(selectedDeliUsername==='%' && deliStatus=='Accepted'){
                var count=1;
                res.data.reduce(function(res, value) {
                    if (!res[value.Deli_Men]) {
                        res[value.Deli_Men] = { SrNo: count,Deli_Men:value.Deli_Men,Total_Amount:0,Expense_Fees:0};
                        result.push(res[value.Deli_Men])
                        count++;
                    }
                    res[value.Deli_Men].Total_Amount += (parseInt(value.Total_Amount || 0)- parseInt(value.Expense_Fees || 0)  );
                    return res;
                    }, {});
                    setTableData(result);
            }   
            else{
                setTableData(res.data);
            }                             
        }
        setShowData(true);
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })
}

}
export default Get_Ways;