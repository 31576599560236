import React, { useEffect, useRef } from "react";
import {
  Button,
  CssBaseline,
  ThemeProvider,
  Stack,
  IconButton,
  Tooltip,
  Grid,
  Box,
  Typography,
  Autocomplete,
  TextField,
} from "@mui/material";
import AccountingManagementStyle from "../admin/manage/accounting/AccountingManagementStyle";
import {
  Edit,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from "@mui/icons-material";
import Theme from "../../theme/Theme";
import moment from "moment";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import DataTableComponent from "../../components/DataTable";
import EditFormComponent from "../../components/EditForm";
import Get_Deli_Ways from "../../api/DeliMen/GetDeliWaysController";
import Get_AllPaid_Transfer from "../../api/Way/GetAllPaidTransfer";
export default function DeliOrderManagePage() {
  const [tableData, setTableData] = React.useState();
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [currentData, setCurrentData] = React.useState({});
  const [orderTotalAmount, setOrderTotalAmount] = React.useState(0);
  const [totalExpenseFees, setTotalExpenseFees] = React.useState(0);
  const [orderItemAmount, setOrderItemAmount] = React.useState(0);
  const [deliStatus, setDeliStatus] = React.useState("Assigned");
  const [deliType, setDeliType] = React.useState("All");
  const [fromDate, setFromDate] = React.useState(new Date());
  const [toDate, setToDate] = React.useState(new Date());
  const [assignFomDate, setAssignFromDate] = React.useState(null);
  const [assignToDate, setAssignToDate] = React.useState(null);
  const [totalAllPaidTransfer, setTotalAllPaidTransfer] = React.useState(0);
  const [showReturnItemHalfPaidAmount, setShowReturnItemHalfPaidAmount] =
    React.useState(false);
  const [ways, setWays] = React.useState();
  // button references
  const showBtn = useRef(null);
  const hideBtn = useRef(null);
  const columns = [
    {
      name: "CAO_ID",
      options: {
        display: false,
      },
    },
    "SrNo",
    "Client_Name",
    "Receiver_Name",
    "Receiver_Phone_Number",
    "Receiver_Address",
    "C_Name",
    "T_Name",
    "Deli_Type",
    {
      name: "Item_Price",
      options: {
        display: false,
        customBodyRender: function (value, tableMeta, updateValue) {
          return value;
        },
      },
    },
    { name: "G_Name", label: "Gate" },
    { name: "G_Price", label: "Gate_Price" },
    {
      name: "Expense_Fees",
      options: {
        display: false,
      },
    },
    {
      name: "Deli_Price",
      options: {
        display: false,
      },
    },
    {
      name: "Tan_Sar_Price",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          if (value === null) {
            return 0;
          } else {
            return value;
          }
          return value;
        },
      },
    },
    {
      name: "Return_Item_Amount",
      label: "Return_Item",
      options: {
        display: showReturnItemHalfPaidAmount,
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          if (value === null || value === "") {
            return 0;
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "Half_Paid_Amount",
      options: {
        //display:showReturnItemHalfPaidAmount,
        display: false,
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          if (value === null || value === "") {
            return 0;
          } else {
            return value;
          }
        },
      },
    },
    "Deli_Status",
    "Total_Amount",
    {
      name: "Assign_Remark",
      label: "Assign_Remark",
      options: {
        display: false,
      },
    },
    { name: "Deli_Men_Remark", label: "Deli_Remark" },
    {
      name: "Create_Date",
      label: "Assign_Date",
      options: {
        sortDirection: "desc",
        customBodyRender: function (value, tableMeta, updateValue) {          
          // return moment(value, 'M/D/YYYY h:mm:ssA').format('D-M-YYYY');
          return moment(value).format("DD-MM-YYYY");
        },
      },
    },
    {
      name: "\n\n\n\n\n\nACTION",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRenderLite: (dataIndex, value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={0}>
              <Tooltip title="Edit">
                {deliStatus === "Assigned" || deliStatus === "OnWay" ? (
                  <IconButton
                    tooltip="Edit"
                    color="primary"
                    aria-label="edit"
                    onClick={() => UpdateWay(dataIndex)}
                  >
                    <Edit />
                  </IconButton>
                ) : (
                  <></>
                )}
              </Tooltip>
              {/* <Tooltip title="Collect & Assign">
                        <IconButton color="info" aria-label="collect_assign" onClick={() =>DeleteAccount(dataIndex)}>
                            <PlaylistAdd />
                        </IconButton>
                        </Tooltip>
                        <Tooltip title="Check">
                        <IconButton color="success" aria-label="info" onClick={() =>CheckAmount(dataIndex)}>
                            <Info />
                        </IconButton>
                        </Tooltip> */}
            </Stack>
          );
        },
      },
    },
  ];
  const handleTableData = () => {
    if (showData === false) {
      Get_Deli_Ways(
        deliStatus,
        deliType,
        setTableData,
        setShowData,
        setOrderTotalAmount,
        setTotalExpenseFees,
        setOrderItemAmount,
        null,
        null,
        setWays
      );
    } else {
      setShowData(false);
    }
  };
  const UpdateWay = (dataIndex) => {
    setCurrentData({
      CAO_ID: tableData[dataIndex]["CAO_ID"],
      Deli_Type: tableData[dataIndex]["Deli_Type"],
      Deli_Status: tableData[dataIndex]["Deli_Status"],
      G_Name: tableData[dataIndex]["G_Name"],
      Show_Btn: showBtn,
      Hide_Btn: hideBtn,
    });
    setShowEditForm(true);
  };
  const handleApplyFilter = () => {
    var from_date = null;
    var to_date = null;
    setShowData(false);
    deliStatus === "Completed" || deliStatus === "Accepted"
      ? setShowReturnItemHalfPaidAmount(true)
      : setShowReturnItemHalfPaidAmount(false); // refresh the view if it is already open.
    if (
      deliStatus === "Assigned" &&
      assignFomDate !== null &&
      assignToDate !== null
    ) {
      console.log("Got it");
      from_date = moment(assignFomDate).format("YYYY-MM-DD");
      to_date = moment(assignToDate).format("YYYY-MM-DD");
    } else {
      from_date = moment(fromDate).format("YYYY-MM-DD");
      to_date = moment(toDate).format("YYYY-MM-DD");
    }
    //if selected deli status is completed then we have to call GetAllPaidTransfer API to show the value at Transfer Amount Card
    if (deliStatus === "Completed") {
      console.log("Calling GetAllPaidTransferApi");
      Get_AllPaid_Transfer(
        localStorage.getItem("user_Name"),
        setTotalAllPaidTransfer
      );
    }
    Get_Deli_Ways(
      deliStatus,
      deliType,
      setTableData,
      setShowData,
      setOrderTotalAmount,
      setTotalExpenseFees,
      setOrderItemAmount,
      from_date,
      to_date,
      setWays
    );
  };
  const handleAssignFromDateChange = (newValue) => {
    setAssignFromDate(newValue);
  };
  const handleAssignToDateChange = (newValue) => {
    setAssignToDate(newValue);
  };
  const handleFromDateChange = (newValue) => {
    setFromDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const handleToDateChange = (newValue) => {
    setToDate(moment(newValue).format("YYYY-MM-DD"));
  };
  useEffect(() => {
    handleTableData();
  }, []);

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Stack direction="row">
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Autocomplete
              disablePortal
              id="deliStatus"
              value={deliStatus}
              fullWidth
              options={[
                { label: "Completed" },
                { label: "OnWay" },
                { label: "Assigned" },
                { label: "Accepted" },
              ]}
              onChange={(event, value) => {
                setDeliStatus(value?.label || ""); // Update deliStatus with the selected label
                setShowData(false); // Set showData to false
              }}
              renderInput={(params) => (
                <TextField {...params} label="Deli Status" />
              )}
            />
          </Grid>

          {deliStatus === "Assigned" ? (
            <>
              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="From Date"
                    inputFormat="dd-MM-yyyy"
                    value={assignFomDate}
                    onChange={handleAssignFromDateChange}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="To Date"
                    inputFormat="dd-MM-yyyy"
                    value={assignToDate}
                    onChange={handleAssignToDateChange}
                    renderInput={(params) => (
                      <TextField fullWidth {...params} />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          ) : deliStatus === "Completed" ? (
            <Grid item xs={12} md={3}>
              <Autocomplete
                disablePortal
                id="deliType"
                value={deliType}
                fullWidth
                options={[
                  { label: "All" },
                  { label: "Transfer" },
                  { label: "Return Item" },
                ]}
                onSelect={(event) => [
                  setDeliType(event.target.value),
                  setShowData(false),
                ]}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Status" />
                )}
              />
            </Grid>
          ) : (
            <></>
          )}
          {deliStatus === "Accepted" ? (
            <>
              <Grid item xs={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="From Date"
                    inputFormat="MM-dd-yyyy"
                    value={fromDate}
                    onChange={handleFromDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} md={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="To Date"
                    inputFormat="MM-dd-yyyy"
                    value={toDate}
                    onChange={handleToDateChange}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Grid>
            </>
          ) : (
            <></>
          )}
          <Grid item xs={6} md={3}>
            <Button variant="contained" onClick={() => handleApplyFilter()}>
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <br />
      {showData ? (
        [
          <Button
            ref={hideBtn}
            sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
            endIcon={<KeyboardArrowDown />}
            onClick={() => handleTableData()}
          >
            Hide Data
          </Button>,
          <br />,
          <br />,
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="h5">{ways}</Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Ways
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="h5">
                    {orderTotalAmount.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Total Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="h5">
                    {totalExpenseFees.toLocaleString()}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Total Deli Fees
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            {deliStatus !== "Completed" ? (
              <Grid item xs={6} md={3}>
                <Box sx={AccountingManagementStyle.boxStyle}>
                  <Stack direction="column">
                    <Typography variant="h5">
                      {orderItemAmount.toLocaleString()}
                    </Typography>
                    <Typography
                      variant="h8"
                      sx={AccountingManagementStyle.subHeading}
                    >
                      Item Amount
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            ) : (
              <></>
            )}
            {deliStatus === "Completed" ? (
              <>
                <Grid item xs={6} md={3}>
                  <Box sx={AccountingManagementStyle.boxStyle}>
                    <Stack direction="column">
                      <Typography variant="h5">
                        {(orderTotalAmount - totalExpenseFees).toLocaleString()}{" "}
                      </Typography>
                      <Typography
                        variant="h8"
                        sx={AccountingManagementStyle.subHeading}
                      >
                        Pay Amount
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Box sx={AccountingManagementStyle.boxStyle}>
                    <Stack direction="column">
                      <Typography variant="h5">
                        {totalAllPaidTransfer.toLocaleString()}
                      </Typography>
                      <Typography
                        variant="h8"
                        sx={AccountingManagementStyle.subHeading}
                      >
                        Transfer Amount
                      </Typography>
                    </Stack>
                  </Box>
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>,
          <br />,
          <DataTableComponent
            title="Deli Ways"
            data={tableData}
            columns={columns}
          />,
        ]
      ) : (
        <Button
          ref={showBtn}
          sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
          endIcon={<KeyboardArrowRight />}
          onClick={() => handleTableData()}
        >
          Show Data
        </Button>
      )}
      {showEditForm ? (
        <EditFormComponent
          headerText="Edit Deli Ways ✏️"
          formType="Deli Men"
          buttonText="Update"
          currentData={currentData}
          setShowEditForm={setShowEditForm}
        />
      ) : (
        <></>
      )}
    </ThemeProvider>
  );
}
