import React, { useEffect } from "react";
import API_EndPoint from "../api/URL";
import axios from "axios";
import moment from "moment";
import {
  ThemeProvider,
  CssBaseline,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  Divider
} from "@mui/material";
import Theme from "../theme/Theme";
export default function ShowDataFormComponent(
    {
        formType,
        headerText,
        orderTotalAmount,
        collectTotalAmount,
        diffAmount,
        setShowCheckForm
    }
){
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Dialog open={true} onClose={() => setShowCheckForm(false)}>
        <DialogTitle>{headerText}</DialogTitle>
        <DialogContent>
        {formType==="Order"?
        <Stack spacing={2} direction="column">
        <Typography variant="h6">Order Amount : {orderTotalAmount.toLocaleString()} </Typography>
        <Typography variant="h6">Collect Amount : {collectTotalAmount.toLocaleString()} </Typography>
        <Divider/>
        <Typography variant="h6">Difference Amount : {(parseInt(collectTotalAmount)-parseInt(orderTotalAmount)).toLocaleString()} </Typography>
        </Stack>
        :<></>}
        </DialogContent>
        <DialogActions>
          <br />
          <br />
          <Button onClick={() => setShowCheckForm(false)}>OK</Button>
        </DialogActions>
        </Dialog>
    </ThemeProvider>
  );
};
