import axios from "axios";
import API_EndPoint from "../api/URL";
import DecryptDataComponent from "./AES/DecryptData";
import Toastify from "./Toastify";
export default function ValidateJWTComponent(setJwtStatus,setShowToast){
  // console.log("Config",API_EndPoint.Config);
    axios.get(API_EndPoint.URL+'jwt',API_EndPoint.Config).then((res)=>{
        setJwtStatus(res.status);
        localStorage.setItem("Login_Status",true);
    }).catch((err)=>{      
        Toastify("warning","Session Expired.Please Login Again.",setShowToast);  
        setJwtStatus(err.response.status);
        setTimeout(() => {
            setShowToast(false);
            localStorage.setItem("Login_Status",false);
            window.location.replace('/');
          }, 5000);
    })
}