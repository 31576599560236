import Theme from '../../theme/Theme'
const authStyles={
    container:{
        marginTop: 3,
        padding:6,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow:2,
        backgroundColor:'#fff'

    },
    paperCard:{
        width:'auto',
        height:'auto',
        marginBottom:10,
        paddingBottom:8,
        borderRadius:15,
        borderColor:Theme.palette.primary.main,
        borderWidth:'10px'
    },
    paperValue:{
        paddingBottom:10
    }
}
export default authStyles;