import axios from "axios";
import API_EndPoint from "../../URL";
const Delete_CollectAssign_Order = async (id) => {
  if (window.confirm("Are you sure to delete?")) {
    var api_endpoint = API_EndPoint.URL;
    var business_name = localStorage.getItem("business_Name");
    await axios
      .delete(api_endpoint + "order/collectassign?CAO_ID="+id+"&Business_Name="+business_name,API_EndPoint.Config)
      .then((res) => {
        if (res.status === 202) {
          window.location.reload();
        }
      })
      .catch((err) => {
            if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        window.location.replace("/");
    }
    else{
      if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
        alert(err.response.data);
    }
        }
      });
  }
};
export default Delete_CollectAssign_Order;
