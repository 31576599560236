import axios from 'axios';
import API_Endpoint from '../URL';
const Get_Other_Income=async(businessName,setOtherIncome)=>{
    const api_endpoint=API_Endpoint.URL;
    await axios.get(api_endpoint+'manage/data/otherincome?Business_Name='+businessName,API_Endpoint.Config).then((res)=>{
        if(res.status===200){
            var other_income_amount=0;
            res.data.map(({Other_Income_Amount})=>{
                other_income_amount+=parseInt(Other_Income_Amount || 0);
            })
            setOtherIncome(other_income_amount);
        }
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}
export default Get_Other_Income;