import axios from "axios";
import API_EndPoint from "../../URL";


const Get_Debit_Data = async (
  clientName,
  selectedDebitType,
  paymentStatus,
  fromDate,
  toDate,
  setTableData,
  setClientToAmount,
  setShowData,
  setShowOverall,
  setTotalWays,
  setTotalClientToGetAmount = null,
  setTotalItemPaidAmount = null,
  setTotalNoDeliAmount = null,
  setTotalReturnItemAmount,
  setTotalHalfPaidAmount = null,
  setTotalGateAmount = null,
  setTotalAllPaidAmount = null
) => {
  var api_endpoint = API_EndPoint.URL;
  var business_name = localStorage.getItem("business_Name");
  var total_ways = 0;
  let dateQuery = "";
  if (paymentStatus == "Completed"){
    dateQuery = "&From_Date=" + fromDate + "&To_Date=" + toDate;
  }
  //alert(dateQuery);
  //get overall data
  // await axios.get(api_endpoint+'accounting/debit/overall?Client_Name='+clientName+'&Deli_Type='+selectedPaymentStatus+'&Business_Name='+business_name).then((res)=>{
  //     if(res.status===200){
  //         res.data.map(({Total_Pay_Amount})=>{
  //             setOSPaidAmount(Total_Pay_Amount);
  //             // setTotalClientToGetAmount(Total_Client_To_Get__Amount);
  //             // setTotalItemPaidAmount(Total_Item_Paid_Amount);
  //             // setTotalNoDeliAmount(Total_No_Deli_Amount);
  //             // setTotalReturnItemAmount(Total_Return_Item_Amount);
  //             // setTotalHalfPaidAmount(Total_Half_Paid_Amount);
  //         })
  //     }
  // }).catch((err)=>{
  //     alert("Error at getting total pay amount "+err.response.data);
  // })
  //get table data & other's total amount
  if (clientName == "All") {
    clientName = "%";
  }
  await axios
    .get(
      api_endpoint +
        "accounting/debit?Client_Name=" +
        clientName +
        "&Debit_Type=" +
        selectedDebitType +
        "&Payment_Status=" +
        paymentStatus +
        dateQuery +
        "&Business_Name=" +
        business_name,
        API_EndPoint.Config
    )
    .then((res) => {
      if (res.status === 200) {
        setTableData(res.data);
        setTotalWays(res.data.length);
        //getting total gate amount
        var total_amount = 0;
        var tan_sar_price = 0;
        if (selectedDebitType === "To Get") {
          res.data.map(
            ({
              Deli_Type,
              Total_Amount,
              Tan_Sar_Price,
              Item_Price,
              Expense_Fees,
              Half_Paid_Amount,
              Return_Item_Amount,
            }) => {
              total_amount += parseInt(Total_Amount || 0);
              tan_sar_price += parseInt(Tan_Sar_Price || 0);
            }
          );
          console.log("Total Amount before adding tan sar price", total_amount);
          //tan sar price ko clien to get amount mrr paung p pya pay ya mae.
          total_amount = total_amount + tan_sar_price;
        } else if (selectedDebitType === "To Pay") {
          res.data.map(
            ({ Total_Amount, Gate_Amount, All_Paid_Amount }) =>
              (total_amount +=
                parseInt(Total_Amount) -
                (parseInt(Gate_Amount || 0) + parseInt(All_Paid_Amount || 0)))

          );
        }
        setClientToAmount(total_amount);
        //getting total all paid amount
        // var total_all_paid_amount=0;
        // res.data.map(({Total_Amount})=>{
        //     total_all_paid_amount+=Total_Amount;
        // })
        // setTotalAllPaidAmount(total_all_paid_amount);
      }
      setShowData(true);
      setShowOverall(true);
    })
    .catch((err) => {
      if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
      alert(err.response.data);
    }
    });
};
export default Get_Debit_Data;
