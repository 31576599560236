import axios from "axios";
import API_EndPoint from "../../URL";
const Get_Item_Amount_By_Deli_Status = async (
    co_id,
  setCollectTotalAmount,
  setPendingTotalAmount,
  setAssignTotalAmount,
  setOnWayTotalAmount,
  setCompleteTotalAmount,
  setAcceptTotalAmount,
  setInstockTotalAmount,
  setRejectTotalAmount,
  setTransferTotalAmount
)=>{
    var api_endpoint = API_EndPoint.URL;
    var business_name = localStorage.getItem("business_Name");
    await axios
      .get(
        api_endpoint +
          "order/collectassign?CO_ID=" +
          co_id +
          "&Business_Name=" +
          business_name,
          API_EndPoint.Config
      ).then((res)=>{
        if(res.status===200){
            var collect_total_amount = 0;
            var pending_total_amount = 0;
            var assign_total_amount = 0;
            var onway_total_amount = 0;
            var complete_total_amount = 0;
            var accept_total_amount = 0;
            var instock_total_amount = 0;
            var reject_total_amount = 0;
            var transfer_total_amount = 0;
            res.data.map(({Deli_Status,Deli_Type,Item_Price})=>{
                collect_total_amount+=parseInt(Item_Price || 0);
                //getting total transfer amount
                if(Deli_Type==="Transfer"){
                    transfer_total_amount+=parseInt(Item_Price || 0);
                }
                //getting total amount by deli status
                if(Deli_Status==="Pending"){
                    pending_total_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Assigned"){
                    assign_total_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="OnWay"){
                    onway_total_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Completed"){
                    complete_total_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Instock"){
                    instock_total_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Accepted"){
                    accept_total_amount+=parseInt(Item_Price || 0);
                }
                else if(Deli_Status==="Rejected"){
                    reject_total_amount+=parseInt(Item_Price || 0);
                }
            })
            setCollectTotalAmount(collect_total_amount);
            setPendingTotalAmount(pending_total_amount);
            setAssignTotalAmount(assign_total_amount);
            setOnWayTotalAmount(onway_total_amount);
            setCompleteTotalAmount(complete_total_amount);
            setAcceptTotalAmount(accept_total_amount);
            setInstockTotalAmount(instock_total_amount);
            setRejectTotalAmount(reject_total_amount);
            setTransferTotalAmount(transfer_total_amount);
        }
      }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
          alert(err.response.data);
        }
    })
}
export default Get_Item_Amount_By_Deli_Status;