import axios from 'axios';
import API_EndPoint from '../URL';
import EncryptDataComponent from "../../components/AES/EncryptData";
const LoginController=async(postData)=>{
    const api_endpoint=API_EndPoint.URL;
    await axios.post(api_endpoint+'account/login',postData).then((res)=>{
        if(res.status===202){
            console.log(res.data);
            res.data.map(({User_ID,User_Name,Phone_Number,Business_Name,User_Role,Client_Name,Token})=>{
                localStorage.setItem("user_ID",User_ID);
                localStorage.setItem("user_Name",User_Name);
                localStorage.setItem("phone_Number",Phone_Number);
                localStorage.setItem("business_Name",Business_Name);
                localStorage.setItem("user_Role",User_Role);
                localStorage.setItem("Login_Status",true);
                localStorage.setItem("client_Name",Client_Name);
                localStorage.setItem("Token",EncryptDataComponent(Token));

            })
            localStorage.getItem("user_Role")==='Admin'?
             window.location.replace('/admin/dashboard'):
             localStorage.getItem("user_Role")==='OS'?
             window.location.replace('/os/manage/order'):
             window.location.replace('/deli/manage/order')
        }
    }).catch((err)=>{
        if(err.response.status===401){
            alert(err.response.data);
        }
        else{
            alert(err.response.data);
        }
    })
}
export default LoginController;