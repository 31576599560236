import React from "react";
import { AppBar, Toolbar, IconButton, Button, Typography, ThemeProvider, Avatar } from "@mui/material";
import { LocalShipping, Menu, Logout } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Theme from "../theme/Theme";
import sevenDayLogo from '../assets/sevendaylogo.jpg';
import logo from '../assets/logo.png';

export default function AppBarComponent({ headerText, showMenuIcon, showLogout }) {
    const drawerWidth = 240;
    const [open, setOpen] = React.useState(false);
    
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    
    const handleDrawerClose = () => {
        setOpen(false);
    };
    
    const openedMixin = (theme) => ({
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
    });

    const closedMixin = (theme) => ({
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(7)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(${theme.spacing(9)} + 1px)`,
        },
    });

    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    return (
        <ThemeProvider theme={Theme}>
            <AppBar position="static" open={open}>
                <Toolbar color="primary">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                    >
                        {showMenuIcon ? <Menu /> : <img src={logo} alt="logo" style={{ width: 50, height: 50 }} />}
                    </IconButton>
                    {window.location.pathname !== '/' ?
                        <Avatar alt="Official Logo" src={sevenDayLogo} /> : null}
                    <Typography style={{ marginLeft: '2%' }} variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {headerText}
                    </Typography>
                    {showLogout && (
                        <>
                            <Button variant="contained" endIcon={<Logout />} color="inherit" style={{ color: Theme.palette.primary.main, fontWeight: 'bold' }}>Logout</Button>
                            <Button variant="contained" endIcon={<Logout />} color="inherit" style={{ color: Theme.palette.primary.main, fontWeight: 'bold', right: 100 }}>Logout</Button>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}
