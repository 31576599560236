import React from "react";
import axios from "axios";
import API_EndPoint from "../../URL"
const Get_Order_Data=async(clientName,fromDate,toDate,setTableData,setShowData,setTotalCollectQty,setTotalClientAmount,setTotalCollectAmount,setTotalDiffAmount)=>{
var api_endpoint=API_EndPoint.URL;
var business_name=localStorage.getItem("business_Name");
var pathUrl;
if(fromDate===null && toDate===null){
    pathUrl='order/client?Client_Name=%&From_Date&To_Date&Business_Name='+business_name;
}
else{
    clientName==="All"&& (clientName="%");
    pathUrl='order/client?Client_Name='+clientName+'&From_Date='+fromDate+'&To_Date='+toDate+'&Business_Name='+business_name;
}
await axios.get(api_endpoint+pathUrl,API_EndPoint.Config).then((res)=>{
    if(res.status===200){
        var totalCollectOrder=0;
        setTableData(res.data);
        //getting co_id from order list and pass each co_id and to sum the number of collect assign orders of each co_id.
        res.data.map(({CO_ID})=>{
           axios.get(api_endpoint+'order/overall?CO_ID='+CO_ID+'&Business_Name='+business_name,API_EndPoint.Config).then((res)=>{
                if(res.status===200){
                    res.data.map(({Total_Number_Of_Collect_Assign})=>{
                        totalCollectOrder+=parseInt(Total_Number_Of_Collect_Assign);
                    })
                }
                setTotalCollectQty(totalCollectOrder);
            })
        })
        //getting total client amount= total amount-(gate amount+all paid amount)
        var total_client_amount=0;
        res.data.map(({Total_Amount,Gate_Amount,All_Paid_Amount})=>{
            if(Total_Amount===null){
                Total_Amount=0;
            }
            if(Gate_Amount===null){
                Gate_Amount=0;
            }
            if(All_Paid_Amount===null){
                All_Paid_Amount=0;
            }
        total_client_amount+=(parseInt(Total_Amount)-(parseInt(Gate_Amount)+parseInt(All_Paid_Amount)));
        setTotalClientAmount(total_client_amount);
        })
        //getting total collect amount=Collect_Amount-(Gate_Amount+All_Paid_Amount)
        var total_collect_amount=0;
        res.data.map(({Collect_Amount,Gate_Amount,All_Paid_Amount})=>{
            if(Collect_Amount===null){
                Collect_Amount=0;
            }
            if(Gate_Amount===null){
                Gate_Amount=0;
            }
            if(All_Paid_Amount===null){
                All_Paid_Amount=0;
            }
            total_collect_amount+=(parseInt(Collect_Amount)-(parseInt(Gate_Amount)+parseInt(All_Paid_Amount)));
            setTotalCollectAmount(total_collect_amount);
            })
        //getting total diff amount=total collect amount-total client amount
        setTotalDiffAmount(parseInt(total_collect_amount)-parseInt(total_client_amount));
        setShowData(true);
        // console.log(res.data);
        // alert("")
    }
})
}
export default Get_Order_Data;