import axios from "axios";
import API_EndPoint from "../URL";

const GetItemAmount = async (setItemAmount, businessName) => {
    await axios.get(API_EndPoint.URL + "dashboard/itemamount?" + "Business_Name=" + businessName,API_EndPoint.Config)
    .then(({data}) => {
        Object.keys(data[0]).forEach(each => data[0][each] = parseInt(data[0][each] || 0));
        setItemAmount({...data[0], 
            cityTotal: parseInt(data[0]["City_Pending_Item_Amount"]) + parseInt(data[0]["City_Assigned_Item_Amount"]) + parseInt(data[0]["City_OnWay_Item_Amount"]) + parseInt(data[0]["City_Completed_Item_Amount"]),
            townshipTotal: parseInt(data[0]["Township_Pending_Item_Amount"] || 0) + parseInt(data[0]["Township_Assigned_Item_Amount"] || 0) + parseInt(data[0]["Township_OnWay_Item_Amount"] || 0) + parseInt(data[0]["Township_Completed_Item_Amount"] || 0),
        });
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default GetItemAmount;