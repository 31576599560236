import axios from "axios";
import API_EndPoint from "../../URL";
const Get_DeliExpense_Fees=async(city,township,setDeliFees,setExpenseFees,setOrderTotalAmount,orderItemAmount)=>{
    if(city===null || city==="" || city===undefined){
        city="%";
    }
    var api_endpoint=API_EndPoint.URL;
    var business_name=localStorage.getItem("business_Name");
    await axios.get(api_endpoint+'data/fees?C_Name='+city+'&T_Name='+township+'&Business_Name='+business_name,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            res.data.map(({Deli_Price,Expense_Fees})=>{
                setDeliFees(Deli_Price);
                setExpenseFees(Expense_Fees);
                /*Receiver Pay Type is default UnPaid , so we need to sum 
                orderItemAmount And Deli Fees which we have just get from api.*/
                setOrderTotalAmount(parseInt(orderItemAmount)+parseInt(Deli_Price));
            })
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert("Error At Getting DeliExpense Fees",err.response.data);
        }
    })
}
export default Get_DeliExpense_Fees;