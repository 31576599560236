import axios from 'axios';
import API_Endpoint from '../URL';
const Create_User_Account=async(postData,setLoading,setShowForm)=>{
    const api_endpoint=API_Endpoint.URL;
    setLoading(true);
await axios.post(api_endpoint+"account/register",postData,API_Endpoint.Config).then((res)=>{
    if(res.status===201){
        alert(res.data);
        setLoading(false);
        setShowForm(false);
    }
}).catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
    setLoading(false);
})
}
export default Create_User_Account;