import axios from "axios";
import API_EndPoint from "../../URL";

const GetWayCheckList = async (setDeliItemAmount, setTableData, setCollectItemAmount, selectedDeli, fromDate, toDate, setShowData) => {
    if (selectedDeli == 0)
    {
        alert("Please select delivery man");
        return;
    }
    const business_Name = localStorage.getItem("business_Name");
    const selectedDeliMan = selectedDeli === "All" ? "%" : selectedDeli;
    setShowData(false);
    await axios.get(API_EndPoint.URL + "accounting/waycheck?Deli_Men=" + selectedDeliMan + "&From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + business_Name,API_EndPoint.Config)
    .then(({data, status}) => {
        if (status === 200) {
            let totalItemAmount = 0;
            let totalCollectItemAmount = 0;
            data.map(each => {
                if (each.Deli_Men)
                {
                    totalItemAmount += each.Total_Item_Amount;
                }
                totalCollectItemAmount += each.Total_Item_Amount;
            });
            setDeliItemAmount(totalItemAmount);
            setCollectItemAmount(totalCollectItemAmount);
            setTableData(data);
            setShowData(true);
        }
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default GetWayCheckList;