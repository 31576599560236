import axios from "axios";
import moment from "moment";
import API_EndPoint from "../URL";
const Get_Today_Net_Profit=async(businessName,setNetProfit)=>{
    const api_endpoint=API_EndPoint.URL;
    const today=moment(new Date()).format("YYYY-MM-DD");
    await axios.get(api_endpoint+'accounting/profit?From_Date='+today+'&To_Date='+today+'&Business_Name='+businessName,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            var net_profit=0;
            res.data.map(({Net_Profit})=>{
                net_profit+=parseInt(Net_Profit || 0);
            })
            axios.get(api_endpoint+'data/gexp?From_Date='+today+'&To_Date='+today+'&Business_Name='+businessName,API_EndPoint.Config).then((res)=>{
                if(res.status===200){
                    var general_expense=0;
                    res.data.map(({General_Expense_Amount})=>{
                        general_expense+=parseInt(General_Expense_Amount || 0);
                    })
                    net_profit=net_profit-general_expense;
                }
                setNetProfit(net_profit);
                localStorage.setItem("net_Profit",net_profit);
            })
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}
export default Get_Today_Net_Profit;