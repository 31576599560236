import { grey } from "@mui/material/colors";
import { makeStyles, createStyles } from "@mui/styles";

// custom classes
const useStyles = makeStyles((theme) => 
 createStyles({
  wrap: {
    backgroundColor: "#F3F6F9",
    padding: "1rem",
    minHeight: "95vh",
    boxSizing: "border-box",
    margin: 0,
    position: "relative"
  },
  divider: {
    width: "80%",
    height: ".5rem",
    backgroundColor: grey,
    margin: ".5rem auto"
  },
  date: {
    position: "absolute",
    top: "1.5rem",
    right: "1.5rem",
    fontSize: "1rem",
    color: "#616161",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    transition: "all ease-in-out .6s",
    "& > *:first-child": {
      marginRight: "1rem"
    },
    "@media(max-width: 700px)": {
      display: "none"
    }
  },
  title: {
    fontSize: "1.3rem",
    color: "#000"
  },
  card: {
    padding: "1rem",
    position: "relative",
    borderRadius: "10px",
    "& > .title": {
      color: "#616161",
      fontSize: ".9rem",
      fontWeight: "light",
    },
    "& .unit": {
      fontSize: ".8rem",
      fontWeight: "light"
    },
    "& > .mainWrap": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center"
    },
    "& .main": {
      color: "#000",
      fontSize: "2rem",
      "@media(max-width: 1200px)": {
        fontSize: "1.5rem",
      },
      "@media(max-width: 900px)": {
        fontSize: "1rem"
      }
    },
    "& .iconWrap": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "2.5rem",
      height: "2.5rem",
      borderRadius: "50%",
    },
    "& .icon": {
      fontSize: "1.3rem"
    }
  },
  itemAmountSection: {
    "& .unit": {
      fontSize: ".8rem",
      fontWeight: "light"
    },
    "& .townshipText": {
      fontSize: "2.3rem",
      "@media(max-width: 1200px)": {
        fontSize: "1.8rem"
      },
      "@media(max-width: 900px)": {
        fontSize: "1.5rem"
      }
    },
    "& .cityText": {
      fontSize: "2rem",
      "@media(max-width: 1200px)": {
        fontSize: "1.5rem"
      },
      "@media(max-width: 900px)": {
        fontSize: "1.3rem"
      }
    },
  }
}));

export default useStyles;