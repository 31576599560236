import axios from "axios";
import API_EndPoint from "../../URL";

const UpdateOwnerExpense = async (updateData, setLoading, setShowEditForm) => {
    setLoading(true);
    //console.log("Owner Expense Update Data",updateData);
    await axios.put(API_EndPoint.URL + "data/oexp", updateData,API_EndPoint.Config)
    .then((res) => {
        if(res.status===202){
            setLoading(false);
            setShowEditForm(false);
        }

        // hideBtn.current.click();
        // setTimeout(() => {
        //     showBtn.current.click();
        // }, 1000);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default UpdateOwnerExpense;