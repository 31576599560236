import axios from "axios";
import API_EndPoint from "../../URL";

const Update_Payment_Status = async (id,clientName,setCurrent) => {
  //if (window.confirm("Are you sure to change payment status?")){
    var api_endpoint = API_EndPoint.URL;
    var business_name = localStorage.getItem("business_Name");
    clientName==="All"?clientName="%":console.log("");
    console.log("Config",API_EndPoint.Config);
    await axios
      .put(api_endpoint + "accounting/debit?ID="+id+"&Client_Name="+clientName+"&Debit_Type="+"To Get"+"&Business_Name="+business_name,{},API_EndPoint.Config)
      .then((res) => {
        if (res.status === 202) {
          setCurrent({disabled: true, checked: true});
        }
      })
      .catch((err) => {
        setCurrent({disabled: false, checked: false});
        if(err.response.status===401){
          // Toastify("warning","Session Expired",setShowToast);
          alert("Session Expired");
          localStorage.setItem("Login_Status",false);
          window.location.replace("/");
      }
      else{
        alert(err.response.data);
      }
      });
    // } else {
    //   setCurrent({disabled: false, checked: false});
    // }
};
export default Update_Payment_Status;
