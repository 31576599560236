import { CoPresent } from "@mui/icons-material";
import axios from "axios";
import API_EndPoint from "../URL";

const getInStockData = async (setTableData, business_Name, selectedDeli, selectedDeliStatus,setShowData,setTotalItemAmount,setWays) => {
    selectedDeli = selectedDeli === "All"? "%" : selectedDeli;
    await axios.get(API_EndPoint.URL + "instock?Deli_Men=" + selectedDeli + "&Deli_Status=" + selectedDeliStatus + "&Business_Name=" + business_Name,API_EndPoint.Config).then((res)=>{
            if(res.status===200){
                console.log("Res Data",res.data);
                setTableData(res.data);
                setWays(res.data.length);
                let totalItemPrice = 0;
                res.data.map(item => totalItemPrice += item.Item_Price || 0);
                setTotalItemAmount(totalItemPrice);
                setShowData(true);
            }
        }).catch((err)=>{
            console.log("Error",err);
            if(err.response.status===401){
                // Toastify("warning","Session Expired",setShowToast);
                alert("Session Expired");
                localStorage.setItem("Login_Status",false);
                window.location.replace("/");
            }
            else{
            alert(err.response.data);
            }
        })
    }
export default getInStockData;