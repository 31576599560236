import './App.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
//screens
import Login from './pages/auth/Login';
import Dashboard from './pages/admin/dashboard/Dashboard';
import NotFound from './pages/error/404NotFound';
import SideDrawerComponent from './components/SideDrawer';
//test
function App() {
  return (
    <div>
    <Router basename='/'>
      <Switch>
        <Route exact path='/' component={Login}></Route>
        <Route exact path='/admin/dashboard' component={Dashboard}></Route>
        <Route exact path='/admin/manage/account'><SideDrawerComponent headerText="Account Management"/></Route>
        <Route exact path='/admin/manage/data'><SideDrawerComponent headerText="Data Management"/></Route>
        <Route exact path='/admin/manage/order/client'><SideDrawerComponent headerText="Order Management"/></Route>
        <Route exact path='/deli/manage/order'><SideDrawerComponent headerText="Deli Order Management"/></Route>
        <Route exact path='/os/manage/order'><SideDrawerComponent headerText="OS Order Management"/></Route>
        <Route exact path="/admin/manage/order/collectassign"><SideDrawerComponent headerText="CA-Order Management"/></Route>
        <Route exact path="/os/manage/order/collectassign"><SideDrawerComponent headerText="OS CA-Order Management"/></Route>
        <Route exact path="/admin/manage/way"><SideDrawerComponent headerText="Way Management"/></Route>
        <Route exact path="/admin/manage/accounting"><SideDrawerComponent headerText="Accounting Management"/></Route>
        <Route exact path="/admin/manage/instock"><SideDrawerComponent headerText="InStock Management" /></Route>
        <Route exact path="/admin/manage/balancesheet"><SideDrawerComponent headerText="Balance Sheet Management" /></Route>
        <Route component={NotFound}/>
        {/* test */}
      </Switch>
    </Router>
    </div>
  );
}

export default App;
