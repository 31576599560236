import React, {useState, useEffect} from "react";
// mui
import {
  Card,
  Grid,
  Typography,
  ThemeProvider,
  Button,
  Skeleton,
  LinearProgress,
  Box,
  CircularProgress,
  circularProgressClasses,
  Divider,
} from "@mui/material";
import theme from "../theme/Theme";
import InventoryIcon from "@mui/icons-material/Inventory";
import CancelIcon from "@mui/icons-material/Cancel";
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
// time formatter
import moment from "moment";
// charts
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  CartesianGrid,
  BarChart,
  Bar,
  Pie,
  PieChart,
  Cell,
  Sector,
} from "recharts";
// api
import Get_Total_Instock from "../api/Dashboard/GetTotalInstockRejectedController";
import Get_Total_Debit from "../api/Dashboard/GetDebitTotalController";
import Get_Ways from "../api/Dashboard/GetWaysController";
import Get_Item_Amount from "../api/Dashboard/GetItemAmountController";
import Get_Today_Income from "../api/Dashboard/GetTodayIncomeController";
import Get_Today_Net_Profit from "../api/Dashboard/GetTodayNetProfitController";
import Get_Other_Income from "../api/Dashboard/GetOtherIncomeController";
import Get_Other_ToPay from "../api/Dashboard/GetOtherToPayController";
import Get_Owner_Expense from "../api/Accounting/Profit/GetOwnerExpenseController";
// colors
import palette from "./Dashboard/palette";
// custom classes
import useStyles from "./Dashboard/customClasses";
// custom components
import Counter from "./Dashboard/components/Counter";
import NormalCard from "./Dashboard/components/NormalCard";
import ColorCard from "./Dashboard/components/ColorCard";
import Get_Return_Amount from "../api/Dashboard/GetReturnAmountController";

const Dashboard = () => {
  const { wrap, card, title, date, itemAmountSection, divider } = useStyles();
  const currentDate = moment(new Date()).format("dddd, MMMM Do YYYY");
  const [inStockTotal, setInStockTotal] = useState(0);
  const [rejectedTotal, setRejectedTotal] = useState(0);
  const [returnAmount,setReturnAmount]=useState(0);
  const businessName = localStorage.getItem("business_Name");
  const [toPayTotal, setToPayTotal] = useState(0);
  const [toGetTotal, setToGetTotal] = useState(0);
  const [showData, setShowData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [wayData, setWayData] = useState([]);
  const [itemAmount, setItemAmount] = useState({});
  const [todayIncome, setTodayIncome] = React.useState(0);
  const [netProfit, setNetProfit] = React.useState(0);
  const [otherIncome, setOtherIncome] = React.useState(0);
  const [otherToPay, setOtherToPay] = React.useState(0);
  const[ownerExpense,setOwnerExpense]=React.useState(0);
  const [pieChartHeight, setPieChartHeight] = useState(500);
  // skeleton height
  const wayChartHeight = 500;
  // mobile breakpoint
  const breakpoint = 600;
  const [currentWidth, setCurrentWidth] = useState(0);

  const handleFetch = async () => {
    setShowData(true);
    setLoading(true);
    await Get_Total_Instock(businessName, setInStockTotal, "InStock");
    await Get_Total_Instock(businessName, setRejectedTotal, "Rejected");
    await Get_Return_Amount(setReturnAmount);
    await Get_Total_Debit(businessName, "To Pay", setToPayTotal);
    await Get_Total_Debit(businessName, "To Get", setToGetTotal);
    await Get_Today_Net_Profit(businessName, setNetProfit);
    await Get_Today_Income(businessName, setTodayIncome,setNetProfit,netProfit);
    await Get_Other_Income(businessName, setOtherIncome);
    await Get_Other_ToPay(businessName, setOtherToPay);
    const today=moment(new Date).format("YYYY-MM-DD");
    await Get_Owner_Expense(setOwnerExpense,today,today);
    // ways data
    await Get_Ways(setWayData, businessName);
    // item data
    await Get_Item_Amount(setItemAmount, businessName);
    setLoading(false);
  };

  // get superToGet and investment
  const superTotalGet =
    inStockTotal +
    rejectedTotal +
    otherIncome +
    toGetTotal +
    itemAmount["cityTotal"] +
    itemAmount["townshipTotal"]+
    parseInt(returnAmount);
  const totalInvest = superTotalGet - (toPayTotal + otherToPay);
  console.log("TOtal City Item AMount",itemAmount["cityTotal"]);
  console.log("TOtal Township Item AMount",itemAmount["townshipTotal"]);

  // screen resize handler
  const handleResize = () => [
    setCurrentWidth(window.innerWidth),
    setPieChartHeight(
      window.innerHeight / 1.5 > breakpoint ? wayChartHeight : window.innerHeight / 1.5
    ),
  ];

  useEffect(() => {
    setCurrentWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [0]);

  return (
    <ThemeProvider theme={theme}>
      <main className={wrap}>
        {/* today date */}
        {showData &&
          (loading ? (
            <Skeleton
              animation="wave"
              style={{ width: "20%", height: "1.2rem" }}
              className={date}
            />
          ) : (
            <Typography
              variant="h1"
              className={date}
              style={{ color: palette.teal.main }}
            >
              Today is {currentDate}
            </Typography>
          ))}
        {showData ? (
          <>
            <Button
              sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
              endIcon={<KeyboardArrowDown />}
              onClick={() => setShowData(false)}
            >
              Hide Data
            </Button>
            <br />
            <br />
            {/* cards */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={inStockTotal} label="In Stock" color="red" loading={loading}>
                  <InventoryIcon
                    className="icon"
                    style={{ color: palette.red.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={rejectedTotal} label="Rejected" color="red" loading={loading}>
                  <CancelIcon
                    className="icon"
                    style={{ color: palette.red.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={returnAmount} label="Return" color="red" loading={loading}>
                  <CancelIcon
                    className="icon"
                    style={{ color: palette.red.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={toGetTotal} label="Transfer" color="teal" loading={loading}>
                  <MonetizationOnIcon
                    className="icon"
                    style={{ color: palette.teal.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={otherIncome} label="Other Income" color="teal" loading={loading}>
                  <MonetizationOnIcon
                    className="icon"
                    style={{ color: palette.teal.main }}
                  />
                </NormalCard>
              </Grid>                            
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={otherToPay} label="Other To Pay" color="red" loading={loading}>
                  <CreditCardOffIcon
                    className="icon"
                    style={{ color: palette.red.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={todayIncome} label="Today Income" color="teal" loading={loading}>
                  <MonetizationOnIcon
                    className="icon"
                    style={{ color: palette.teal.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={netProfit} label="Today Net Profit" color="teal" loading={loading}>
                  <MonetizationOnIcon
                    className="icon"
                    style={{ color: palette.teal.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={superTotalGet} label="All To Get" color="teal" loading={loading}>
                  <MonetizationOnIcon
                    className="icon"
                    style={{ color: palette.teal.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={toPayTotal} label="Client To Pay" color="red" loading={loading}>
                  <CreditCardOffIcon
                    className="icon"
                    style={{ color: palette.red.main }}
                  />
                </NormalCard>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={2.4}>
                <NormalCard card={card} amount={totalInvest} label="Investment" color="teal" loading={loading}>
                  <MonetizationOnIcon
                    className="icon"
                    style={{ color: palette.teal.main }}
                  />
                </NormalCard>
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{marginTop: "0rem"}}>
              {/* item amount section */}
              <Grid item xs={12} lg={4} className={itemAmountSection}>
                <Grid container spacing={2}>
                  {/* township data */}
                  <Grid item xs={12}>
                    <Card className={card} sx={{ backgroundColor: "#fff" }}>
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          style={{ width: "30%", height: "1.2rem", marginBottom: "1rem" }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          component="h2"
                          sx={{ color: "#000", marginBottom: "1rem" }}
                        >
                          Township
                        </Typography>
                      )}
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="red" amount={itemAmount["Township_OnWay_Item_Amount"]} label="On Way" percent={(itemAmount["Township_OnWay_Item_Amount"]*100) / itemAmount["townshipTotal"]} type="solid" loading={loading} />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="amber" amount={itemAmount["Township_Pending_Item_Amount"]} label="Pending" percent={(itemAmount["Township_Pending_Item_Amount"]*100) / itemAmount["townshipTotal"]} type="solid" loading={loading} />
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="blue" amount={itemAmount["Township_Assigned_Item_Amount"]} label="Assigned" percent={(itemAmount["Township_Assigned_Item_Amount"]*100) / itemAmount["townshipTotal"]} type="solid" loading={loading} />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="teal" amount={itemAmount["Township_Completed_Item_Amount"]} label="Completed" percent={(itemAmount["Township_Completed_Item_Amount"]*100) / itemAmount["townshipTotal"]} type="solid" loading={loading} />
                              </Grid>
                            </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                  {/* city data */}
                  <Grid item xs={12}>
                    <Card className={card} sx={{ backgroundColor: "#fff" }}>
                      {loading ? (
                        <Skeleton
                          animation="wave"
                          style={{ width: "30%", height: "1.2rem", marginBottom: "1rem" }}
                        />
                      ) : (
                        <Typography
                          variant="body1"
                          component="h2"
                          sx={{ color: "#000", marginBottom: "1rem" }}
                        >
                          City
                        </Typography>
                      )}
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="red" amount={itemAmount["City_OnWay_Item_Amount"]} label="On Way" percent={(itemAmount["City_OnWay_Item_Amount"]*100) / itemAmount["cityTotal"]} type="light" loading={loading} />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="amber" amount={itemAmount["City_Pending_Item_Amount"]} label="Pending" percent={(itemAmount["City_Pending_Item_Amount"]*100) / itemAmount["cityTotal"]} type="light" loading={loading} />
                              </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="blue" amount={itemAmount["City_Assigned_Item_Amount"]} label="Assigned" percent={(itemAmount["City_Assigned_Item_Amount"]*100) / itemAmount["cityTotal"]} type="light" loading={loading} />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <ColorCard card={card} color="teal" amount={itemAmount["City_Completed_Item_Amount"]} label="Completed" percent={(itemAmount["City_Completed_Item_Amount"]*100) / itemAmount["cityTotal"]} type="light" loading={loading} />
                              </Grid>
                            </Grid>
                        </Grid>
                      </Grid>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
              {/* way chart */}
              <Grid item xs={12} lg={8}>
                <Card className={card}>
                  {loading ? (
                    <>
                      <Skeleton
                        animation="wave"
                        style={{ width: "30%", height: "1.2rem" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        animation="wave"
                        width="100%"
                        height={wayChartHeight}
                        sx={{ marginTop: "10px" }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="body1" component="h2">
                        Ways in this year
                      </Typography>
                      <ResponsiveContainer width="100%" height={wayChartHeight}>
                        <BarChart
                          data={wayData}
                          margin={{ top: 30 }}
                          layout={
                            currentWidth > breakpoint
                              ? "horizontal"
                              : "vertical"
                          }
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          {currentWidth > breakpoint ? (
                            <>
                              <XAxis type="category" dataKey="Month" />
                              <YAxis type="number" />
                            </>
                          ) : (
                            <>
                              <XAxis type="number" />
                              <YAxis type="category" dataKey="Month" />
                            </>
                          )}
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="Assigned" fill={palette.blue.main} />
                          <Bar dataKey="Rejected" fill={palette.pink.main} />
                          <Bar dataKey="Pending" fill={palette.amber.main} />
                          <Bar dataKey="Total_Ways" fill={palette.teal.main} />
                        </BarChart>
                      </ResponsiveContainer>
                    </>
                  )}
                </Card>
              </Grid>
            </Grid>
            <br />
          </>
        ) : (
          <>
            <Button
              sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
              endIcon={<KeyboardArrowRight />}
              onClick={handleFetch}
            >
              Show Data
            </Button>
            <br />
          </>
        )}
      </main>
    </ThemeProvider>
  );
};

export default Dashboard;