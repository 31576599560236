import { AltRoute } from "@mui/icons-material";
import axios from "axios";
import API_EndPoint from "../../URL";

const CreateOwnerExpense = async (postData, setLoading,setShowForm) => {
    setLoading(true);
    await axios.post(API_EndPoint.URL + "data/oexp", postData,API_EndPoint.Config)
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
    setLoading(false);
    setShowForm(false);
    setShowForm(true);
}

export default CreateOwnerExpense;