import React, { useEffect, useState } from "react";
import API_EndPoint from "../api/URL";
import axios from "axios";
import moment from "moment";
import {
  ThemeProvider,
  CssBaseline,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Container,
  Autocomplete,
  Stack,
  Typography,
} from "@mui/material";
import {
  LoadingButton,
  MobileDatePicker,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Theme from "../theme/Theme";
import Create_User_Account from "../api/Account/RegisterController";
import Create_Client from "../api/Data/Client/CreateClientController";
import Create_City from "../api/Data/City/CreateCityController";
import Create_Gate_Controller from "../api/Data/Gate/CreateGateController";
import Create_Price_Controller from "../api/Data/Price/CreatePriceController";
import Create_Order from "../api/Order/ClientOrder/CreateOrderController";
import Get_DeliExpense_Fees from "../api/Data/Price/GetDeliExpenseFeesController";
import Create_Collect_Assign_Order from "../api/Order/CollectAssignOrder/CreateCollectAssignOrderController";
import Get_Gate_Price_By_Gate_Name from "../api/Data/Gate/GetGatePriceByGateNameController";
import Get_Deli_Account from "../api/Account/GetDeliAccountController";
import Create_GExpense from "../api/Data/GExp/CreateGExpenseController";
import Get_All_Deli_Account from "../api/InStock/GetAllDeliAccountController";
import Get_Deli_By_Gate from "../api/Order/CollectAssignOrder/GetDeliByGateController";
import Create_Other_Income from "../api/Data/Other Income/CreateOtherIncomeController";
import Create_Other_ToPay from "../api/Data/OtherToPay/CreateOtherToPayController";
import Create_OwnerExpense from "../api/Data/OwnerExp/CreateOwnerExpenseController";
// testing
// import ApiWrap from "../api/ApiWrap";
// import registerAccountModified from "../api/Account/RegisterAccountModified";
// end

export default function CreateFormComponent({
  headerText,
  formType,
  buttonText,
  setShowForm,
}) {
  // const townshipData = [
  //   {
  //     label: "Pabedan",
  //   },
  //   {
  //     label: "Kyauktada",
  //   },
  //   {
  //     label: "Lanmadaw",
  //   },
  // ];
  const clientPayType = [{ label: "Prepaid" }, { label: "Debit" }];
  const receiverPayType = [
    { label: "UnPaid" },
    { label: "OS Paid" },
    { label: "Only Deli" },
    { label: "Deli Free" },
  ];
  //Account
  const business_Name = localStorage.getItem("business_Name");
  const [userName, setUserName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState(0);
  const [selectedCity, setSelectedCity] = React.useState("");
  const [selectedTownship, setSelectedTownship] = React.useState("");
  const [selectedWayTownship, setSelectedWayTownship] = React.useState();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [selectedRadio, setSelectedRadio] = React.useState("Delivery");
  //Client
  const [address, setAddress] = React.useState("");
  const [bankAccount, setBankAccount] = React.useState("");
  const [bankAccountOwner, setBankAccountOwner] = React.useState("");
  const [contactPerson, setContactPerson] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  //Township
  const [cityData, setCityData] = React.useState([]);
  const [townshipData, setTownshipData] = React.useState([]);
  const [townshipName, setTownshipName] = React.useState("");
  //Gate
  const [gateName, setGateName] = React.useState("");
  const [gatePrice, setGatePrice] = React.useState(0);
  //Deli Price
  const [deliPrice, setDeliPrice] = React.useState(0);
  const [expenseFees, setExpenseFees] = React.useState(0);
  //Order
  const [clientData, setClientData] = React.useState([]);
  const [clientName, setClientName] = React.useState("");
  const [deliUserName, setDeliUserName] = React.useState([]);
  const [selectedDeliUsername, setSelectedDeliUsername] = React.useState("");
  const [pickupDate, setPickUpDate] = React.useState(new Date());
  const [assignDate, setAssignDate] = React.useState(new Date());
  const [orderItemQuantity, setOrderItemQuantity] = React.useState(0);
  const [orderItemAmount, setOrderItemAmount] = React.useState();
  const [orderTotalAmount, setOrderTotalAmount] = React.useState(0);
  const [clientPayment, setClientPayment] = React.useState("Debit");
  const [receiverPayment, setReceiverPayment] = React.useState("UnPaid");
  const [orderRemark, setOrderRemark] = React.useState("");
  const [receiverName, setReceiverName] = React.useState("");
  const [receiverPhoneNumber, setReceiverPhoneNumber] = useState("");
  const [receiverAddress, setReceiverAddress] = useState("");
  const [itemName,setItemName]=useState("");
  const [deliFees, setDeliFees] = React.useState(0);
  const [gateList, setGateList] = React.useState([]);
  const [selectedGate, setSelectedGate] = React.useState("");
  const [gateAmount, setGateAmount] = React.useState();
  const [allPaidAmount, setAllPaidAmount] = React.useState();
  //collect&Assign
  const [queryClientName, setQueryClientName] = React.useState("");
  const [gateDeli, setGateDeli] = useState({});
  // general expense
  const [expenseName, setExpenseName] = useState("");
  const [expenseDetails, setExpenseDetails] = useState("");
  const [expenseAmount, setExpenseAmount] = useState();
  //other income
  const [otherIncomeName, setOtherIncomeName] = React.useState("");
  const [otherIncomeAmount, setOtherIncomeAmount] = React.useState();
  const [otherIncomeDetail, setOtherIncomeDetail] = React.useState("");
  //other topay
  const [otherToPayName, setOtherToPayName] = React.useState("");
  const [otherToPayAmount, setOtherToPayAmount] = React.useState();
  const [otherToPayDetail, setOtherToPayDetail] = React.useState("");
  // order form
  const checkStatusList = ["Check", "Uncheck"];
  const [checkStatus, setCheckStatus] = useState("Uncheck");

  const handlePickupDateChange = (newValue) => {
    setPickUpDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const handleAssignDateChange = (newValue) => {
    setAssignDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const handleReceiverPayType = (newValue) => {
    setReceiverPayment(newValue);
    var total_amount = 0;
    if (newValue === "UnPaid") {
      total_amount = parseInt(orderItemAmount) + parseInt(deliFees);
      setOrderTotalAmount(total_amount);
    } else if (newValue === "Only Deli") {
      total_amount = deliFees;
      setOrderTotalAmount(total_amount);
    } else if (newValue === "Deli Free") {
      total_amount = orderItemAmount;
      setOrderTotalAmount(total_amount);
    } else {
      setOrderTotalAmount(total_amount);
    }
  };

  useEffect(async () => {
    // fetching City Data
    if (
      formType === "Price" ||
      formType === "Order" ||
      formType === "Collect&Assign Order" ||
      formType === "Account"
    ) {
      var api_endpoint = API_EndPoint.URL;
      // var business_name = localStorage.getItem("business_Name");
      //getting client list for account management
      await axios
        .get(
          api_endpoint + "data/client?Business_Name=" + business_Name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            setClientData([]);
            res.data.map(({ Client_Name }) => {
              setClientData((clientData) => [...clientData, Client_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      await axios
        .get(
          api_endpoint + "data/city?Business_Name=" + business_Name,
          API_EndPoint.Config
        )
        .then((res) => {
          if (res.status === 200) {
            res.data.map(({ C_Name }) => {
              setCityData((cityData) => [...cityData, C_Name]);
            });
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status", false);
            window.location.replace("/");
          } else {
            alert(err.response.data);
          }
        });
      //getting township list for account
      if (formType === "Account") {
        setTownshipData([]);
        //above already get city list so concat to the townshipData Array.
        await axios
          .get(
            api_endpoint + "data/city?Business_Name=" + business_Name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.map(({ C_Name }) => {
                setTownshipData((townshipData) => [...townshipData, C_Name]);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
        //start calling township list from price table
        await axios
          .get(
            api_endpoint + "price/township?Business_Name=" + business_Name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.map(({ T_Name }) => {
                //start concating city data and township data for way of township.
                setTownshipData((townshipData) => [...townshipData, T_Name]);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
      } else {
        //getting only township list from price table
        await axios
          .get(
            api_endpoint + "price/township?Business_Name=" + business_Name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.map(({ T_Name }) => {
                setTownshipData((townshipData) => [...townshipData, T_Name]);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
      }
      //getting client name if it is order form
      // if (formType === "Order") {
      //   await axios
      //     .get(
      //       api_endpoint + "data/client?Business_Name=" + business_Name,
      //       API_EndPoint.Config
      //     )
      //     .then((res) => {
      //       if (res.status === 200) {
      //         res.data.map(({ Client_Name }) => {
      //           setClientData((clientData) => [...clientData, Client_Name]);
      //         });
      //       }
      //     })
      //     .catch((err) => {
      //       if (err.response.status === 401) {
      //         // Toastify("warning","Session Expired",setShowToast);
      //         alert("Session Expired");
      //         localStorage.setItem("Login_Status", false);
      //         window.location.replace("/");
      //       } else {
      //         alert(err.response.data);
      //       }
      //     });
      //   //getting deli account
      //   // await axios
      //   //   //getting specific deli name by Township
      //   //   .get(
      //   //     api_endpoint +
      //   //       "account/deli?T_Name=" +
      //   //       selectedTownship +
      //   //       "&Business_Name=" +
      //   //       business_name
      //   //   )
      //   //   .then((res) => {
      //   //     if (res.status === 200) {
      //   //       res.data.map(({ User_Name }) => {
      //   //         setSelectedDeliUsername(User_Name);
      //   //       });
      //   //     }
      //   //   })
      //   //   .catch((err) => {
      //   //     alert(
      //   //       "Error At Getting Deli Account Name by Township-",
      //   //       err.response.data
      //   //     );
      //   //   });
      // }
      if (formType === "Collect&Assign Order") {
        setAssignDate(moment(new Date()).format("YYYY-MM-DD"));
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setQueryClientName(urlParams.get("Client_Name"));
        await Get_Deli_By_Gate(setGateDeli);
        await axios
          .get(
            api_endpoint + "data/city/price?Business_Name=" + business_Name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              setCityData([]);
              res.data.map(({ C_Name }) => {
                setCityData((cityData) => [...cityData, C_Name]);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
        await axios
          .get(
            api_endpoint + "data/gate?Business_Name=" + business_Name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.map(({ G_Name }) => {
                setGateList((gateList) => [...gateList, G_Name]);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
        await axios
          //getting all delivery men name
          .get(
            api_endpoint +
              "account/deli?C_Name=&T_Name=&Business_Name=" +
              business_Name,
            API_EndPoint.Config
          )
          .then((res) => {
            if (res.status === 200) {
              res.data.map(({ User_Name }) => {
                setDeliUserName((deliUserName) => [...deliUserName, User_Name]);
              });
            }
          })
          .catch((err) => {
            if (err.response.status === 401) {
              // Toastify("warning","Session Expired",setShowToast);
              alert("Session Expired");
              localStorage.setItem("Login_Status", false);
              window.location.replace("/");
            } else {
              alert(err.response.data);
            }
          });
      }
      // for general expense
      // if(formType === "General Expense") {

      // }
    }
    if (formType === "Gate") {
      await Get_All_Deli_Account(setDeliUserName, business_Name);
      setShowForm(true);
    }
    // }
  }, []);
  const handleSubmitForm = () => {
    var postData;
    if (formType === "Account") {
      if (phoneNumber !== "" && password !== "") {
        postData = {
          User_Name: userName,
          Phone_Number: phoneNumber,
          Email: email,
          User_Role: selectedRadio,
          Client_Name: clientName,
          City: selectedCity,
          Township: selectedWayTownship,
          Password: password,
          Business_Name: business_Name,
        };
        Create_User_Account(postData, setLoading, setShowForm);
      } else {
        alert("Please enter phone number & password");
      }
      // testing
      // ApiWrap(["account", "register"], postData, registerAccountModified, {setLoading, setShowForm}, "post");
      // end
    } else if (formType === "Client") {
      postData = {
        Client_Name: userName,
        Phone_Number: phoneNumber,
        Address: address,
        Bank_Account: bankAccount,
        Bank_Account_Owner: bankAccountOwner,
        Contact_Person: contactPerson,
        Business_Name: business_Name,
      };
      Create_Client(postData, setLoading, setShowForm);
    } else if (formType === "City") {
      postData = {
        C_Name: selectedCity,
        Business_Name: business_Name,
      };
      Create_City(postData, setLoading, setShowForm);
    } else if (formType === "Price") {
      postData = {
        T_Name: townshipName,
        C_Name: selectedCity,
        Deli_Price: deliPrice,
        Expense_Fees: expenseFees,
        Business_Name: business_Name,
      };
      Create_Price_Controller(postData, setLoading, setShowForm);
    } else if (formType === "Gate") {
      if (gateName === "" || selectedDeliUsername === "") {
        alert("Please fill gate name and deliman");
        return;
      }
      postData = {
        G_Name: gateName,
        G_Price: gatePrice,
        Expense_Fees: expenseFees,
        Business_Name: business_Name,
        Deli_Men: selectedDeliUsername,
      };
      Create_Gate_Controller(postData, setLoading, setShowForm);
    } else if (formType === "General Expense") {
      if (
        expenseAmount == 0 ||
        expenseAmount === "" ||
        expenseAmount === undefined ||
        expenseDetails === ""
      ) {
        alert("Please fill details and amount");
        return;
      }
      postData = {
        General_Expense_Name: expenseName,
        General_Expense_Detail: expenseDetails,
        General_Expense_Amount: expenseAmount,
        Business_Name: business_Name,
      };
      Create_GExpense(postData, setLoading, setShowForm);
    } else if (formType === "Other Income") {
      postData = {
        Other_Income_Name: otherIncomeName,
        Other_Income_Amount: otherIncomeAmount,
        Other_Income_Detail: otherIncomeDetail,
        Business_Name: business_Name,
      };
      //console.log("Other Income POST Data",postData);
      if (otherIncomeName == "" || otherIncomeName == null) {
        alert("Please Fill other income name");
      } else if (otherIncomeAmount == "" || otherIncomeAmount == null) {
        alert("Please Fill other income amount");
      } else {
        Create_Other_Income(postData, setLoading, setShowForm);
      }
    } else if (formType === "Other ToPay") {
      postData = {
        Other_ToPay_Name: otherToPayName,
        Other_ToPay_Amount: otherToPayAmount,
        Other_ToPay_Detail: otherToPayDetail,
        Business_Name: business_Name,
      };
      //console.log("Other Income POST Data",postData);
      if (otherToPayName == "" || otherToPayName == null) {
        alert("Please Fill other ToPay name");
      } else if (otherToPayAmount == "" || otherToPayAmount == null) {
        alert("Please Fill other ToPay amount");
      } else {
        Create_Other_ToPay(postData, setLoading, setShowForm);
      }
    } else if (formType === "Owner Expense") {
      if (
        expenseAmount == 0 ||
        expenseAmount === "" ||
        expenseAmount === undefined ||
        expenseDetails === ""
      ) {
        alert("Please fill details and amount");
        return;
      }
      postData = {
        Owner_Expense_Detail: expenseDetails,
        Owner_Expense_Amount: expenseAmount,
        Business_Name: business_Name,
      };
      Create_OwnerExpense(postData, setLoading, setShowForm);
    } else if (formType === "Order") {
      postData = {
        Client_Name: clientName,
        Item_Quantity: orderItemQuantity,
        Pickup_Date: moment(pickupDate).format("YYYY-MM-DD"),
        Total_Amount: orderItemAmount,
        Gate_Amount: gateAmount,
        All_Paid_Amount: allPaidAmount,
        Client_Pay_Type: clientPayment,
        Remark: orderRemark,
        Business_Name: business_Name,
        Order_Check_Status: checkStatus,
      };
      Create_Order(postData, setLoading, setShowForm);
    } else if (formType === "Collect&Assign Order") {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (
        selectedDeliUsername &&
        (selectedCity || selectedTownship || selectedGate)
      ) {
        postData = {
          CO_ID: urlParams.get("CO_ID"),
          Receiver_Name: receiverName,
          Receiver_Phone_Number: receiverPhoneNumber,
          Receiver_Address: receiverAddress,
          Item_Name:itemName,
          //Assign_Date: moment(pickupDate).format("YYYY-MM-DD"),
          C_Name: selectedCity,
          T_Name: selectedTownship,
          G_Name: selectedGate,
          G_Price: gatePrice,
          Item_Price: orderItemAmount,
          Deli_Price: deliFees,
          Expense_Fees: expenseFees,
          Deli_Type: receiverPayment,
          Deli_Men: selectedDeliUsername,
          Total_Amount: orderTotalAmount,
          Assign_Date: assignDate,
          Assign_Remark: orderRemark,
          Business_Name: business_Name,
        };
        //console.log("Collect Assign Order Post Data",postData);
        Create_Collect_Assign_Order(postData, setLoading, setShowForm);
      } else {
        alert(
          `Please fill ${!orderItemAmount ? "*ItemPrice " : ""} ${
            !selectedDeliUsername ? "*DeliMen " : ""
          } ${
            !(selectedCity || selectedTownship || selectedGate)
              ? "*City or Township or Gate"
              : ""
          }`
        );
      }
    }
  };
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <Dialog
        open={true}
        onClose={(event, reason) =>
          reason !== "backdropClick" ? setShowForm(false) : ""
        }
      >
        <DialogTitle>{headerText}</DialogTitle>
        <DialogContent>
          {/* Account Create Form */}
          {formType == "Account" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                margin="dense"
                id="phone"
                label="Phone No"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                defaultValue="Delivery"
                style={{ display: "felx", flex: 1, flexDirection: "row" }}
                onChange={(event) => setSelectedRadio(event.target.value)}
              >
                <FormControlLabel
                  value="Delivery"
                  control={<Radio />}
                  label="Delivery"
                />
                <FormControlLabel
                  value="Admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel value="OS" control={<Radio />} label="OS" />
              </RadioGroup>
              {selectedRadio === "OS" ? (
                <Autocomplete
                  disablePortal
                  id="dataCategory"
                  options={clientData}
                  fullWidth
                  onSelect={(event) => setClientName(event.target.value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Choose Client" />
                  )}
                />
              ) : (
                <TextField
                  margin="dense"
                  id="name"
                  label="Username"
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(event) => setUserName(event.target.value)}
                />
              )}
              {selectedRadio === "Admin" ? (
                <TextField
                  autoFocus
                  margin="dense"
                  id="email"
                  label="Email"
                  type="email"
                  fullWidth
                  variant="standard"
                  onChange={(event) => [setEmail(event.target.value)]}
                />
              ) : selectedRadio === "Delivery" ? (
                [
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={cityData}
                    renderInput={(params) => (
                      <TextField {...params} label="City" />
                    )}
                    onChange={(event, value) => setSelectedCity(value)}
                  />,
                  ,
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={townshipData}
                    onChange={(event, value) => setSelectedWayTownship(value)}
                    // onChange={(event, value) =>[
                    //   setSelectedWayTownship(selectedWayTownship=>[...selectedWayTownship,value]),console.log("Selected Way Township",selectedWayTownship)]
                    // }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Way Township"
                        placeholder=""
                      />
                    )}
                  />,
                ]
              ) : (
                <></>
              )}
              <TextField
                margin="dense"
                id="name"
                label="Password"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setPassword(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Client Create Form */}
          {formType === "Client" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Username"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setUserName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="phone"
                label="Phone No"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setPhoneNumber(event.target.value)}
              />
              <TextField
                margin="dense"
                id="address"
                label="Address"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setAddress(event.target.value)}
              />
              <TextField
                margin="dense"
                id="bankAccount"
                label="Bank Account"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setBankAccount(event.target.value)}
              />
              <TextField
                margin="dense"
                id="bankAccountOwner"
                label="Bank Account Owner"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setBankAccountOwner(event.target.value)}
              />
              <TextField
                margin="dense"
                id="contactPerson"
                label="Contact Person"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setContactPerson(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* City Form */}
          {formType === "City" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                autoFocus
                margin="dense"
                id="cityName"
                label="City Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setSelectedCity(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Price Form */}
          {formType === "Price" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                disablePortal
                id="city-combo-box"
                options={cityData}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => [setSelectedCity(value)]}
              />
              <TextField
                required={true}
                autoFocus
                margin="dense"
                id="townshipName"
                label="Township Name"
                type="text"
                variant="standard"
                onChange={(event, value) => setTownshipName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="township"
                label="Deli Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliPrice(event.target.value)}
              />
              <TextField
                margin="dense"
                id="township"
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Gate Form */}
          {formType === "Gate" ? (
            <Stack direction="column" spacing={4}>
              <TextField
                required
                autoFocus
                margin="dense"
                id="gate"
                label="Gate Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setGateName(event.target.value)}
              />
              <TextField
                required
                margin="dense"
                id="gate"
                label="Gate Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setGatePrice(event.target.value)}
              />
              <TextField
                required
                margin="dense"
                id="expense_fees"
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
              />
              <Autocomplete
                disablePortal
                id="city-combo-box"
                options={deliUserName}
                renderInput={(params) => <TextField {...params} label="Deli" />}
                onChange={(event, value) => [setSelectedDeliUsername(value)]}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Deli Price Form */}
          {formType === "Deli Price" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                disablePortal
                id="city-combo-box"
                options={cityData}
                sx={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => setSelectedCity(value)}
              />

              <Autocomplete
                disablePortal
                id="townshipName"
                value={selectedTownship}
                options={townshipData}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Township" />
                )}
                onChange={(event, value) => setSelectedTownship(value)}
              />
              {/* <AutocompleteComponent
                margin="dense"
                labelName="Township"
                data={townshipData}
              />
                            <AutocompleteComponent
                margin="dense"
                labelName="Gate"
                data={gateData}
              /> */}
              <TextField
                margin="dense"
                id="township"
                label="Deli Price"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setDeliPrice(event.target.value)}
              />
              <TextField
                margin="dense"
                id="township"
                label="Expense Fees"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Client Order Form */}
          {formType === "Order" ? (
            <Stack direction="column" spacing={4}>
              <Autocomplete
                disablePortal
                id="clientName"
                options={clientData}
                renderInput={(params) => (
                  <TextField {...params} label="Client" />
                )}
                onChange={(event, value) => setClientName(value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Pickup Date"
                  inputFormat="dd/MM/yyyy"
                  value={pickupDate}
                  onChange={handlePickupDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              {/* <TextField
                margin="dense"
                id="receiverName"
                label="Receiver Name"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setReceiverName(event.target.value)}
              />
                <Autocomplete
                  disablePortal
                  id="townshipName"
                  options={townshipData}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Township" />
                  )}
                  onChange={(event, value) =>[setSelectedTownship(value),Get_DeliExpense_Fees(value,setDeliFees,setExpenseFees)]}
                /> 
                                 <TextField
                  margin="dense"
                  id="deliFees"
                  value={deliFees}
                  label="Deli Fees"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event)=>setDeliFees(event.target.value)}
                  inputProps={{ maxLength: 100 }}
                />
                                <TextField
                  margin="dense"
                  id="expenseFees"
                  value={expenseFees}
                  label="Expense Fees"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event)=>setExpenseFees(event.target.value)}
                  inputProps={{ maxLength: 100 }}
                /> */}
              <TextField
                margin="dense"
                id="itemQuantity"
                label="Item Quantity"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderItemQuantity(event.target.value)}
              />
              <TextField
                margin="dense"
                id="itemAmount"
                label="Item Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderItemAmount(event.target.value)}
              />{" "}
              <TextField
                margin="dense"
                id="itemAmount"
                label="Gate Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setGateAmount(event.target.value)}
              />{" "}
              <TextField
                margin="dense"
                id="itemAmount"
                label="All Paid Amount"
                type="number"
                fullWidth
                variant="standard"
                onChange={(event) => setAllPaidAmount(event.target.value)}
              />
              <Autocomplete
                disablePortal
                id="clientPayType"
                defaultValue="Debit"
                options={clientPayType}
                renderInput={(params) => (
                  <TextField {...params} label="Client Pay Type" />
                )}
                onChange={(event, value) => [
                  setClientPayment(value.label),
                  console.log("Client Pay Type", value.label),
                ]}
              />
              {/* check status */}
              <Autocomplete
                disablePortal
                id="checkStatus"
                value={checkStatus}
                options={checkStatusList}
                renderInput={(params) => (
                  <TextField {...params} label="Check Status" />
                )}
                onSelect={(event, value) => {
                  setCheckStatus(event.target.value);
                }}
              />
              {/* 
                 <Autocomplete
                  disablePortal
                  id="receiverPayType"
                  value={receiverPayment}
                  options={receiverPayType}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Receiver Pay Type" />
                  )}
                  onChange={(event, value) =>handleReceiverPayType(value.label)}
                />
                                <Autocomplete
                  disablePortal
                  id="deliName"
                  options={deliUserName}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Deli Name" />
                  )}
                  onChange={(event, value) => setSelectedDeliUsername(value)}
                />
                
                {(receiverPayment!=="" && receiverPayment!=null)?
                <>
                                  <TextField
                  margin="dense"
                  id="totalAmount"
                  value={orderTotalAmount}
                  label="Total Amount"
                  type="number"
                  fullWidth
                  variant="standard"
                  onChange={(event) => setOrderTotalAmount(event.target.value)}
                />
                </>:<></>
                } */}
              <TextField
                margin="dense"
                id="orderRemark"
                label="Order Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderRemark(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </Stack>
          ) : (
            <></>
          )}
          {/* Collect&Assign Order Form */}
          {formType === "Collect&Assign Order" ? (
            <Stack direction="column" spacing={4}>
              <Typography variant="p">
                Client Name -{queryClientName}
              </Typography>
              <TextField
                margin="dense"
                id="receiverName"
                label="Receiver Name"
                type="text"
                variant="standard"
                onChange={(event) => setReceiverName(event.target.value)}
              />
              <TextField
                margin="dense"
                id="receiverPhoneNumber"
                label="Phone Number"
                type="number"
                variant="standard"
                onChange={(event) => setReceiverPhoneNumber(event.target.value)}
              />
              <TextField
                margin="dense"
                id="receiverAddress"
                label="Receiver Address"
                type="text"
                variant="standard"
                onChange={(event) => setReceiverAddress(event.target.value)}
              />
              <TextField
                margin="dense"
                id="itemName"
                label="Item Name"
                type="text"
                variant="standard"
                onChange={(event) => setItemName(event.target.value)}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Assign Date"
                  inputFormat="dd/MM/yyyy"
                  value={assignDate}
                  onChange={handleAssignDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Autocomplete
                disablePortal
                id="city-combo-box"
                options={cityData}
                value={selectedCity}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(event, value) => [
                  setSelectedCity(value),
                  setSelectedTownship(""),
                  Get_DeliExpense_Fees(
                    value,
                    null,
                    setDeliFees,
                    setExpenseFees,
                    setOrderTotalAmount,
                    setOrderItemAmount
                  ),
                  Get_Deli_Account(
                    value,
                    null,
                    localStorage.getItem("business_Name"),
                    setDeliUserName,
                    deliUserName,
                    setSelectedDeliUsername
                  ),
                ]}
              />
              <Autocomplete
                disablePortal
                id="townshipName"
                value={selectedTownship}
                options={townshipData}
                renderInput={(params) => (
                  <TextField {...params} label="Township" />
                )}
                onChange={(event, value) => [
                  setSelectedTownship(value),
                  setSelectedCity(""),
                  Get_DeliExpense_Fees(
                    selectedCity,
                    value,
                    setDeliFees,
                    setExpenseFees,
                    setOrderTotalAmount,
                    orderItemAmount
                  ),
                  Get_Deli_Account(
                    null,
                    value,
                    localStorage.getItem("business_Name"),
                    setDeliUserName,
                    deliUserName,
                    setSelectedDeliUsername
                  ),
                ]}
              />
              <Autocomplete
                disablePortal
                id="gateName"
                options={gateList}
                renderInput={(params) => <TextField {...params} label="Gate" />}
                onChange={(event, value) => {
                  setSelectedGate(value);
                  Get_Gate_Price_By_Gate_Name(
                    value,
                    setGatePrice,
                    setExpenseFees
                  );
                  setSelectedDeliUsername(gateDeli[value]);
                }}
              />
              {/* {(selectedGate!=null)?
              <Autocomplete
              disablePortal
              id="gatePrice"
              value={gatePrice}
              options={[{label:500},{label:1000}]}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Gate Price" />
              )}
              onChange={(event, value) =>setGatePrice(value)}
            />:<></>   
              } */}
              {selectedGate != null && selectedGate != "" ? (
                <TextField
                  margin="dense"
                  id="gatePrice"
                  value={gatePrice}
                  label="Gate Price"
                  type="number"
                  min={0}
                  fullWidth
                  variant="standard"
                  inputProps={{ min: 0 }}
                  onChange={(event) => setGatePrice(event.target.value)}
                />
              ) : (
                <></>
              )}
              <Autocomplete
                disablePortal
                id="receiverPayType"
                value={receiverPayment}
                options={receiverPayType}
                renderInput={(params) => (
                  <TextField {...params} label="Receiver Pay Type" />
                )}
                onChange={(event, value) => handleReceiverPayType(value.label)}
              />
              {receiverPayment === "UnPaid" ||
              receiverPayment === "Deli Free" ? (
                <TextField
                  margin="dense"
                  id="itemPrice"
                  value={orderItemAmount}
                  label="Item Price"
                  type="number"
                  min={0}
                  fullWidth
                  inputProps={{ min: 0 }}
                  variant="standard"
                  onChange={(event) => [
                    setOrderItemAmount(event.target.value),
                    setOrderTotalAmount(
                      parseInt(event.target.value) + parseInt(deliFees)
                    ),
                  ]}
                />
              ) : (
                <></>
              )}
              <TextField
                margin="dense"
                id="deliFees"
                value={deliFees}
                label="Deli Price"
                type="number"
                fullWidth
                min={0}
                variant="standard"
                onChange={(event) => [
                  setDeliFees(event.target.value),
                  setOrderTotalAmount(
                    parseInt(orderItemAmount) + parseInt(event.target.value)
                  ),
                ]}
                inputProps={{ maxLength: 100, min: 0 }}
              />
              <TextField
                margin="dense"
                id="expenseFees"
                value={expenseFees}
                label="Expense Fees"
                type="number"
                fullWidth
                min={0}
                variant="standard"
                onChange={(event) => setExpenseFees(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
              <Autocomplete
                disablePortal
                id="deliName"
                value={selectedDeliUsername}
                options={deliUserName}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Name" />
                )}
                onChange={(event, value) => setSelectedDeliUsername(value)}
              />
              {receiverPayment !== "" && receiverPayment != null ? (
                <>
                  <TextField
                    margin="dense"
                    id="totalAmount"
                    value={orderTotalAmount}
                    label="Total Amount"
                    type="number"
                    fullWidth
                    min={0}
                    variant="standard"
                    inputProps={{ min: 0 }}
                    onChange={(event) =>
                      setOrderTotalAmount(event.target.value)
                    }
                  />
                </>
              ) : (
                <></>
              )}
              <TextField
                margin="dense"
                id="orderRemark"
                label="Remark"
                type="text"
                fullWidth
                variant="standard"
                onChange={(event) => setOrderRemark(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
            </Stack>
          ) : (
            <></>
          )}
          {formType === "General Expense" ? (
            <>
              <TextField
                margin="dense"
                id="expenseName"
                label="Expense Name"
                type="text"
                fullWidth
                variant="standard"
                value={expenseName}
                required
                onChange={(event) => setExpenseName(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseDetail"
                label="Details"
                type="text"
                fullWidth
                variant="standard"
                value={expenseDetails}
                required
                onChange={(event) => setExpenseDetails(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseAmount"
                label="Price"
                type="number"
                fullWidth
                variant="standard"
                value={expenseAmount}
                required
                onChange={(event) => setExpenseAmount(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
            </>
          ) : (
            <></>
          )}
          {/* Other Income */}
          {formType === "Other Income" ? (
            <>
              <TextField
                margin="dense"
                id="otherIncomeName"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={otherIncomeName}
                required
                onChange={(event) => setOtherIncomeName(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                margin="dense"
                id="otherIncomeAmount"
                label="Amount"
                type="number"
                fullWidth
                variant="standard"
                value={otherIncomeAmount}
                required
                onChange={(event) => setOtherIncomeAmount(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
              <TextField
                margin="dense"
                id="otherIncomeDetail"
                label="Detail"
                type="text"
                fullWidth
                variant="standard"
                value={otherIncomeDetail}
                required
                onChange={(event) => setOtherIncomeDetail(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
            </>
          ) : (
            <></>
          )}
          {formType === "Other ToPay" ? (
            <>
              <TextField
                margin="dense"
                id="otherIncomeName"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={otherToPayName}
                required
                onChange={(event) => setOtherToPayName(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
              <TextField
                margin="dense"
                id="otherIncomeAmount"
                label="Amount"
                type="number"
                fullWidth
                variant="standard"
                value={otherToPayAmount}
                required
                onChange={(event) => setOtherToPayAmount(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
              <TextField
                margin="dense"
                id="otherIncomeDetail"
                label="Detail"
                type="text"
                fullWidth
                variant="standard"
                value={otherToPayDetail}
                required
                onChange={(event) => setOtherToPayDetail(event.target.value)}
                inputProps={{ maxLength: 50 }}
              />
            </>
          ) : (
            <></>
          )}
          {formType === "Owner Expense" ? (
            <>
              <TextField
                margin="dense"
                id="expenseDetail"
                label="Details"
                type="text"
                fullWidth
                variant="standard"
                value={expenseDetails}
                required
                onChange={(event) => setExpenseDetails(event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <TextField
                margin="dense"
                id="expenseAmount"
                label="Price"
                type="number"
                fullWidth
                variant="standard"
                value={expenseAmount}
                required
                onChange={(event) => setExpenseAmount(event.target.value)}
                inputProps={{ maxLength: 100, min: 0 }}
              />
            </>
          ) : (
            <></>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowForm(false)}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={loading}
            loadingIndicator="Creating..."
            onClick={() => [handleSubmitForm()]}
          >
            {buttonText}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
