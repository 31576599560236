import axios from "axios";
import API_EndPoint from "../../URL";

const GetRejected = async (setTableData, setShowData, selectedClient, fromDate, toDate, paymentStatus, setShowOverall, setTotalRejectedItemPrice,setWays,tableData) => {
    const businessName = localStorage.getItem("business_Name");
    selectedClient = selectedClient === "All" ? "%" : selectedClient;
    // console.log(API_EndPoint.URL + "accounting/rejected?Client_Name=" + selectedClient + "&Rejected_Status=" + paymentStatus + "&Business_Name=" + businessName);
    setShowData(false);
    setShowOverall(false);
    await axios.get(API_EndPoint.URL + "accounting/rejected?Client_Name=" + selectedClient + "&Rejected_Status=" + paymentStatus + "&Business_Name=" + businessName + "&From_Date=" + fromDate + "&To_Date=" + toDate,API_EndPoint.Config)
    .then(({data}) => {
        // alert(API_EndPoint.URL + "accounting/rejected?Client_Name=" + selectedClient + "&Rejected_Status=" + paymentStatus + "&Business_Name=" + businessName + "&From_Date=" + fromDate + "&To_Date=" + toDate);
        setWays(data.length);
        setTableData([]);
        data.map(({SrNo,Client_Name,CAO_ID,Deli_Men,Receiver_Name,C_Name,T_Name,Deli_Type,Item_Price,G_Name,G_Price,Tan_Sar_Price,Expense_Fees,Return_Item_Amount,Half_Paid_Amount,Total_Amount,Deli_Status,Deli_Men_Remark,Instock_Date,Rejected_Complete_Date,Create_Date})=>{
            parseInt(Return_Item_Amount || 0)===0?
            setTableData(tableData=>[...tableData,{SrNo,Client_Name,CAO_ID,Deli_Men,Receiver_Name,C_Name,T_Name,Deli_Type,Item_Price,G_Name,G_Price,Tan_Sar_Price,Expense_Fees,Half_Paid_Amount,Total_Amount,Deli_Status,Deli_Men_Remark,Instock_Date,Rejected_Complete_Date,Create_Date}])
            :
            setTableData(tableData=>[...tableData,{SrNo,Client_Name,CAO_ID,Deli_Men,Receiver_Name,C_Name,T_Name,Deli_Type,G_Name,G_Price,Tan_Sar_Price,Expense_Fees,Return_Item_Amount,Half_Paid_Amount,Total_Amount,Deli_Status,Deli_Men_Remark,Instock_Date,Rejected_Complete_Date,Create_Date}]);
        })
        // data.map(({SrNo,Client_Name,CAO_ID,Deli_Men,Receiver_Name,C_Name,T_Name,Deli_Type,Item_Price,G_Name,G_Price,Tan_Sar_Price,Expense_Fees,0,Half_Paid_Amount,Total_Amount,Deli_Status,Deli_Men_Remark,Instock_Date,Create_Date})=>{
        //     console.log(SrNo);
        //     setTableData(tableData=>[...tableData,{SrNo,Client_Name,CAO_ID,Deli_Men,Receiver_Name,C_Name,T_Name,Deli_Type,0,G_Name,G_Price,Tan_Sar_Price,Expense_Fees,Return_Item_Amount,Half_Paid_Amount,Total_Amount,Deli_Status,Deli_Men_Remark,Instock_Date,Create_Date}]);
        // })
        //setTableData(data);
        let totalItemPrice = 0;
        var return_amount=0;
        data.map(each =>
        parseInt(each.Return_Item_Amount || 0)===0?
        totalItemPrice += parseInt(each.Item_Price || 0):
        totalItemPrice+=parseInt(each.Return_Item_Amount || 0))
        // return_amount+=parseInt(each.Return_Item_Amount || 0);
        // if(return_amount==0){
        //     //totalItemPrice += parseInt(each.Item_Price || 0) + parseInt(each.Return_Item_Amount || 0);
        // }    
        
        setTotalRejectedItemPrice(totalItemPrice);
        setShowData(true);
        setShowOverall(true);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })
}

export default GetRejected;