import {useEffect, memo, useRef} from "react";
import {animate} from "framer-motion";

// memo to cache 
const Counter = memo(({from, to}) => {
    const span = useRef(null);
    useEffect(() => {
      const currentSpan = span.current;

    //   set animation 
      const handleAnimate = animate(from, to, {
          duration: 1,
          onUpdate(val) {
              let currentVal = val.toFixed(0);
              if (val === to) {
                  currentVal = val.toLocaleString("en-US");
              }
              currentSpan.textContent = currentVal;
          }
      });

      return () => handleAnimate.stop();
    }, [from, to])
    

    return (
        <span ref={span} />
    )
});

export default Counter;