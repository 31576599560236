import axios from "axios";
import API_EndPoint from "../../URL";

const GetExpenseList = async (setTotalExpenseListCount, selectedDeli, setShowData, setTotalExpense, fromDate, toDate) => {
    if (selectedDeli == 0)
    {
        alert("Please select delivery man");
        return;
    }
    const business_Name = localStorage.getItem("business_Name");
    const selectedDeliMan = selectedDeli === "All" ? "%" : selectedDeli;
    setShowData(false);
    // console.log(API_EndPoint.URL + "accounting/expense?Deli_Men=" + selectedDeliMan + "&Expense_Status=" + "Paid" + "&Business_Name=" + business_Name + "&From_Date=" + fromDate + "&To_Date=" + toDate);
    await axios.get(API_EndPoint.URL + "accounting/expense?Deli_Men=" + selectedDeliMan + "&Expense_Status=" + "Paid" + "&From_Date=" + fromDate + "&To_Date=" + toDate + "&Business_Name=" + business_Name,API_EndPoint.Config)
    .then(({data, status}) => {
        if (status === 200) {
            setTotalExpenseListCount(data.length);
            setTotalExpense(data.reduce((pre, next) => pre + next.Expense_Fees, 0));
            setShowData(true);
        }
    }).catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default GetExpenseList;