import axios from "axios";
import React from "react";
import moment from "moment";
import API_EndPoint from "../URL";
import Get_Today_Net_Profit from "./GetTodayNetProfitController";
const Get_Today_Income=async(businessName,setTodayIncome,setNetProfit,netProfit)=>{
    const api_endpoint=API_EndPoint.URL;
    const today=moment(new Date()).format("YYYY-MM-DD");
    //first getting Net Profit
    var net_profit=localStorage.getItem("net_Profit");
await axios.get(api_endpoint+'manage/way?Deli_Men=%&Deli_Status=Accepted&Business_Name='+businessName+'&From_Date='+today+'&To_Date='+today,API_EndPoint.Config).then((res)=>{
    var today_income=0;
    var expense_fees=0;
    res.data.map(({Total_Amount,Expense_Fees})=>{
        expense_fees+=parseInt(Expense_Fees || 0);
        today_income+=(parseInt(Total_Amount || 0)-parseInt(Expense_Fees || 0));
    })    
    //alert(expense_fees);
    setTodayIncome(today_income-parseInt(net_profit || 0));
})
.catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
        alert(err.response.data);
    }
})
}
export default Get_Today_Income;
