import axios from "axios";
import API_EndPoint from "../URL";
const Get_AllPaid_Transfer=async(selectedDeliUsername,setTotalAllPaidTransfer)=>{
var api_endpoint=API_EndPoint.URL;
var business_name=localStorage.getItem("business_Name");
selectedDeliUsername==="All"?selectedDeliUsername="%":console.log("");
    var pathUrl='manage/way/allpaidtransfer?Deli_Men='+selectedDeliUsername+'&Business_Name='+business_name;
    await axios.get(api_endpoint+pathUrl,API_EndPoint.Config).then((res)=>{
        if(res.status===200){
            res.data.map(({Total_AllPaid_Transfer_Amount})=>{
                setTotalAllPaidTransfer(parseInt(Total_AllPaid_Transfer_Amount || 0)); 
            })
        }
    }).catch((err)=>{
        if(err.response.status===401){
            alert("Session Expired");
            window.location.replace("/");
        }
        else{
        alert(err.response.data);
        }
    })
}
export default Get_AllPaid_Transfer;