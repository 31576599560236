import React, { useEffect, useState } from "react";
import { Button,CssBaseline,ThemeProvider,Stack,IconButton,Tooltip,Grid,Box,Typography } from "@mui/material";
import { AddCircle,Edit,Delete,KeyboardArrowDown,KeyboardArrowRight,Done } from "@mui/icons-material";
import moment from "moment";
import Theme from "../../theme/Theme";
import AccountingManagementStyle from "../admin/manage/accounting/AccountingManagementStyle";
//import CreateFormComponent from "../../../../components/CreateForm";
import DataTableComponent from "../../components/DataTable";
//import Check_Order_And_Collect_Amount from "../../../../api/Order/ClientOrder/GetTotalCollectOrderAmountController";
import Get_Collect_Assign_Order_Data from "../../api/Order/CollectAssignOrder/GetCollectAssignOrderController";

export default function OSCollectAssignOrderManagePage() {
    const[showForm,setShowForm]=React.useState(false);
    const[showEditForm,setShowEditForm]=React.useState(false);
    const[showCheckForm,setShowCheckForm]=React.useState(false);
    const[orderTotalAmount,setOrderTotalAmount]=React.useState(0);
    const[collectTotalAmount,setCollectTotalAmount]=React.useState(0);
    const[showData,setShowData]=React.useState(false);
    const[tableData,setTableData]=React.useState();
    const[currentData,setCurrentData]=React.useState({});
    const[queryClientName,setQueryClientName]=React.useState();
    const[queryTotalAmount,setQueryTotalAmount]=React.useState();
    const[queryCollectAmount,setQueryCollectAmount]=React.useState();
    // const[collectAmount,setCollectAmount]=React.useState(0);
    // const[queryGateAmount,setQueryGateAmount]=React.useState();
    // const[queryAllPaidAmount,setQueryAllPaidAmount]=React.useState();
    const[totalItemAmount,setTotalItemAmount]=React.useState(0);
    const[totalDeliAmount,setTotalDeliAmount]=React.useState(0);
    const[totalGateDeliAmount,setTotalGateDeliAmount]=React.useState();
    const[totalAllPaidAmount,setTotalAllPaidAmount]=React.useState(0);
    const[clientNetPay,setClientNetPay]=React.useState(0);
    const[diffAmount,setDiffAmount]=React.useState(0);
    const[deliTotalAmount, setDeliTotalAmount] = useState(0);

    const columns = [
      {
        name: "CO_ID",
        options: {
          display: false,
        }
      },
      {
        name: "CAO_ID",
        options: {
          display: false,
        }
      },
    {name:"SrNo",options:{
      sortDirection:"desc"
      // customSort: (data, colIndex, order) => {
      //   return data.sort((a, b) => {
      //     return (
      //       (a.data[colIndex].length < b.data[colIndex].length ? -1 : 1) *
      //       (order === "desc" ? 1 : -1)
      //     );
      //   });
      // }
    }},"Receiver_Name","Deli_Men",{name:"C_Name",label:"City"},{name:"T_Name",label:"Township"},
    {
      name:"Item_Price",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          return value;
        }
      }
    },
    "Deli_Price","Expense_Fees",{name:"G_Name",label:"Gate"},{name:"G_Price",label:"Gate_Price"},"Deli_Type",
    {
      name:"Total_Amount",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          return value;
        }
      } 
      
    },
    {name:"Deli_Status", 
         options: {
      customBodyRender: (value) => {
        if (value === "Assigned") {
          return <Typography sx={{color:'blue'}}>{value}</Typography>
        } 
        else if(value==="Pending") {
          return <Typography sx={{color:'#424242'}}>{value}</Typography>
        }
        else if(value==="OnWay") {
          return <Typography sx={{color:'#ffc107'}}>{value}</Typography>
        }
        else if(value==="Completed") {
          return <Typography sx={{color:'#009688'}}>{value}</Typography>
        }
        else if(value==="Accepted") {
          return <Typography sx={{color:'#4caf50'}}>{value}</Typography>
        }
        else{
          return <Typography sx={{color:'red'}}>{value}</Typography>
        }
    },
  }}
    ,{name:"Expense_Status",options:{display:false}},{name:"Payment_Status",options:{display:false}},{name:"Assign_Remark",options:{display:false}},
    {
      name:"Create_Date",
      label:"Assign_Date",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value===null || value===""){
            return "";
          }
          else{
            var assign_date=moment(value).format("DD-MM-YYYY");
            return assign_date;
          }
        }
      } 
      
    },
    {
      name:"Accept_Date",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value===null || value===""){
            return "";
          }
          else{
            var accept_date=moment(value).format("DD-MM-YYYY");
            return accept_date;
          }
        }
      } 
    },
  //   {
  //     name: "\n\n\n\n\n\nACTION",
  //     options: {
  //         setCellProps: () => ({
  //             style: {
  //               whiteSpace: "nowrap",
  //               position: "sticky",
  //               right: "0",
  //               background: "white",
  //               zIndex: 100
  //             }
  //           }),
  //           setCellHeaderProps: () => ({
  //             style: {
  //               whiteSpace: "nowrap",
  //               position: "sticky",
  //               right: 0,
  //               background: "white",
  //               zIndex: 101
  //             }
  //           }),
  //         customBodyRenderLite: (dataIndex, value, tableMeta, updateValue) => {
  //             return (
                  
  //                 <Stack direction="row" spacing={0}>
  //                   <Tooltip title="Accepted">
  //                     <IconButton tooltip="Approved" color="primary" aria-label="edit" onClick={() =>AcceptedWay(dataIndex)} >
  //                         <Done />
  //                     </IconButton>
  //                     </Tooltip>
  //                    <Tooltip title="Edit">
  //                     <IconButton tooltip="Edit" color="primary" aria-label="edit" onClick={() =>UpdateCollectAssignOrder(dataIndex)} >
  //                         <Edit />
  //                     </IconButton>
  //                     </Tooltip>
  //                     <Tooltip title="Delete">
  //                     <IconButton color="error" aria-label="delete" onClick={() =>DeleteCollectAssignOrder(dataIndex)}>
  //                         <Delete />
  //                     </IconButton>
  //                     </Tooltip>
  //                     {/* <Tooltip title="Collect & Assign">
  //                     <IconButton color="info" aria-label="collect_assign" onClick={() =>DeleteAccount(dataIndex)}>
  //                         <PlaylistAdd />
  //                     </IconButton>
  //                     </Tooltip>
  //                     <Tooltip title="Check">
  //                     <IconButton color="success" aria-label="info" onClick={() =>CheckAmount(dataIndex)}>
  //                         <Info />
  //                     </IconButton>
  //                     </Tooltip> */}
  //                 </Stack>
  //             )
  //         }
  //     }
  // }
  ];
  const UpdateCollectAssignOrder=(dataIndex)=>{
    var assign_date=tableData[dataIndex]["Create_Date"];
    if(assign_date==="1900-01-01T00:00:00"){
      assign_date=moment(new Date()).format("MM-DD-YYYY");
    }
    else{
      assign_date=moment(tableData[dataIndex]["Create_Date"]).format("MM-DD-YYYY");
    }
    setCurrentData({
      CAO_ID:tableData[dataIndex]["CAO_ID"],
      Receiver_Name:tableData[dataIndex]["Receiver_Name"],
      Assign_Date:assign_date,
      Deli_Men:tableData[dataIndex]["Deli_Men"],
      C_Name:tableData[dataIndex]["C_Name"],
      T_Name:tableData[dataIndex]["T_Name"],
      Item_Price:tableData[dataIndex]["Item_Price"],
      Deli_Price:tableData[dataIndex]["Deli_Price"],
      Expense_Fees:tableData[dataIndex]["Expense_Fees"],
      G_Name:tableData[dataIndex]["G_Name"],
      G_Price:tableData[dataIndex]["G_Price"],
      Deli_Type:tableData[dataIndex]["Deli_Type"],
      Total_Amount:tableData[dataIndex]["Total_Amount"],
      Deli_Status:tableData[dataIndex]["Deli_Status"],
      Expense_Status:tableData[dataIndex]["Expense_Status"],
      Payment_Status:tableData[dataIndex]["Payment_Status"],
      Assign_Remark:tableData[dataIndex]["Assign_Remark"]
    })
      setShowEditForm(true);
    }
  // const DeleteCollectAssignOrder=(dataIndex)=>{
  //     Delete_CollectAssign_Order(tableData[dataIndex]["CAO_ID"]);
  //   }  
  //   const AcceptedWay=(dataIndex)=>{
  //     Accepted_Way(tableData[dataIndex]["CAO_ID"]);
  //   }
  // const CheckAmount=(dataIndex)=>{
  //   var co_id=tableData[dataIndex]["CO_ID"];
  //   setOrderTotalAmount(tableData[dataIndex]["Total_Amount"]);
  //   Check_Order_And_Collect_Amount(co_id,setCollectTotalAmount,setShowCheckForm);
  //   }  
  const handleTableData=()=>{
    //first getting gate amount and all paid amount from url param . 
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var gate_amount=urlParams.get('Gate_Amount');
    if(gate_amount=="undefined"){
      gate_amount=0;
    }
    var all_paid_amount=urlParams.get('All_Paid_Amount');
    if(all_paid_amount=="undefined"){
      all_paid_amount=0;
    }
    var order_total_amount=urlParams.get('Total_Amount');
    if(order_total_amount=="undefined"){
      order_total_amount=0;
    }
   if(showData===false){
     Get_Collect_Assign_Order_Data(setTableData,setTotalItemAmount,setDeliTotalAmount,setTotalGateDeliAmount,setTotalAllPaidAmount,order_total_amount,gate_amount,all_paid_amount,setDiffAmount,setClientNetPay,setTotalDeliAmount,setShowData);
   }
   else{
     setShowData(false);
   }
  }
  useEffect(() => {
    //getting client name ,total amount
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    setQueryClientName(urlParams.get('Client_Name'));
    setQueryTotalAmount(urlParams.get('Total_Amount'));
    //setQueryDiffAmount(urlParams.get('Diff_Amount'));
    setQueryCollectAmount(urlParams.get('Collect_Amount'));

    handleTableData();
    // setOrderTotalAmount(urlParams.get('Total_Amount'));
    // var collect_total_amount=urlParams.get('Collect_Amount');
    // var gate_amount=urlParams.get('Gate_Amount');
    // var all_paid_amount=urlParams.get('All_Paid_Amount');
    // // if(collect_total_amount==="null"){
    // //   collect_total_amount=0;
    // // }
    // if(all_paid_amount=="undefined"){
    //   all_paid_amount=0;
    // }
    // if(gate_amount=="undefined"){
    //   gate_amount=0;
    // }
  },[]);
  return (
    <>
    {/* <SideDrawerComponent headerText="Account Management"/> */}
    <ThemeProvider theme={Theme}>
        <CssBaseline/>
        {/* <Button  variant="contained" startIcon={<AddCircle/>} onClick={()=>setShowForm(true)}>Add Collect&Assign</Button><br/><br/> */}
        {showData?[<>
        <Button   sx={{alignSelf:'flex-start',fontWeight:'bold'}} endIcon={<KeyboardArrowDown/>} onClick={()=>handleTableData()}>Hide Data</Button>,<br/><br/>
        <Stack direction="row">
            <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {queryClientName}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Client Name
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {diffAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Difference Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {clientNetPay}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Client Net Pay
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {/* {(totalItemAmount-(totalGateDeliAmount+totalAllPaidAmount))} */}
                    {queryCollectAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Collect Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalAllPaidAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    OS Paid Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid> 
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalGateDeliAmount===0?0:totalGateDeliAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Gate Deli Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>               
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalDeliAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Total Deli
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            <Grid item xs={6} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalItemAmount + deliTotalAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Deli + Item Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            </Grid>
        </Stack><br/>
        <DataTableComponent title="Collect&Assign Order List" data={tableData} columns={columns}/></>]:
        <Button sx={{alignSelf:'flex-start',fontWeight:'bold'}} endIcon={<KeyboardArrowRight/>} onClick={()=>handleTableData()}>Show Data</Button>}
        {/* {showForm? <CreateFormComponent  headerText="Collect&Assign Order Form 📃" formType="Collect&Assign Order" actionType="createOrder" buttonText="Create" setShowForm={setShowForm}/>:<></>}
        {showCheckForm?<ShowCheckFormComponent formType="Order" orderTotalAmount={orderTotalAmount} collectTotalAmount={collectTotalAmount} setShowCheckForm={setShowCheckForm}/>:<></>}
        {showEditForm?
              <EditFormComponent headerText="Edit Collect&Assign Order ✏️" formType="Collect&Assign Order" buttonText="Update" currentData={currentData} setShowEditForm={setShowEditForm}/>
              :<></>} */}
    </ThemeProvider>

    </>
  );

}
