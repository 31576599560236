import axios from "axios";
import API_EndPoint from "../../URL";

const GetOsPending = async (selectedClientName, setTableData, setOsPendingTotals, setShowData,tableData) => {
    const businessName = localStorage.getItem("business_Name");
    if(selectedClientName==='All'){
        selectedClientName="%";
    }
    await axios.get(API_EndPoint.URL + "accounting/ospending?" + "Client_Name=" + selectedClientName + "&Business_Name=" + businessName,API_EndPoint.Config)
    .then(async ({data}) => { 
        let osPendingTotals = {};
        console.log(data[0]);
        data = data.map((each, i) => {
            osPendingTotals[each.Deli_Status] = osPendingTotals[each.Deli_Status] ? (parseInt(osPendingTotals[each.Deli_Status]) + parseInt(each.Item_Price)) : parseInt(each.Item_Price); 
            return {...each, "SrNo": i + 1};
        });
        console.log('OS Pending Total',osPendingTotals);
        // data.map((each,i)=>{
        //     //alert(each.Deli_Status);
        //     (each.Deli_Status!=='Rejected')?
        //     setTableData(tableData=>[...tableData,each]):console.log("");
        // })
        setTableData(data);
        await axios.get(API_EndPoint.URL+'accounting/debit?Client_Name='+selectedClientName+'&Debit_Type='+"To Get"+'&Payment_Status='+"Pending"+'&Business_Name='+businessName,API_EndPoint.Config)
        .then((res)=>{
            if(res.status===200){
                let total_amount = 0;
                res.data.map(({Total_Amount, Tan_Sar_Price})=>{
                    total_amount+=parseInt(Total_Amount || 0) + parseInt(Tan_Sar_Price || 0);
                });
                setOsPendingTotals({...osPendingTotals, "Transfer": total_amount || 0});
                setShowData(true);
            }
            })
            .catch((err)=>{
                if(err.response.status===401){
                    alert("Session Expired");
                    localStorage.setItem("Login_Status",false);
                    window.location.replace('/');
                }
                else{
                    alert(err.response.data);
                }
            })
    })
    .catch((err)=>{
        if(err.response.status===401){
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default GetOsPending;