import React, {useEffect} from "react";
import { useHistory } from "react-router-dom"
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Tooltip, Avatar } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Logout,Person } from "@mui/icons-material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Theme from "../theme/Theme";
import logo from "../assets/postexpressmyanmar.png";
import cklogo from "../assets/cklogo.png";
import nptlogo from "../assets/nptlogo.png";
import shwelogo from "../assets/shwelogo.png";
import { ThemeProvider } from "@mui/material";
import SideMenuComponent from "./SideMenu";
import DashboardComponent from "./Dashboard";
import AccountManagePage from "../pages/admin/manage/account/AccountManage";
import DataManagePage from "../pages/admin/manage/data/DataManage";
import OrderManagePage from "../pages/admin/manage/order/ClientOrderManage";
import DeliOrderManagePage from "../pages/delivery/OrderManage";
import CollectAssignOrderManagePage from "../pages/admin/manage/order/CollectAssignOrderManage";
import OSCollectAssignOrderManagePage from "../pages/os/OSCollectAssignOrderManage";
import EditFormComponent from "./EditForm";
import WayManagePage from "../pages/admin/manage/way/WayManage";
import AccountingManagePage from "../pages/admin/manage/accounting/AccountingManage";
import InstockManagePage from "../pages/admin/manage/instock/InStockManage";
import ValidateJWTComponent from "../components/ValidateJWTController";
import ToastContainerComponent from "./ToastContainer";
import OSOrderManagePage from "../pages/os/OSOrderManage";
const drawerWidth = 240;
const businessName=localStorage.getItem('business_Name');
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideDrawerComponent({ headerText }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const[showEditForm,setShowEditForm]=React.useState(false);
  const[accountData,setAccountData]=React.useState({});
  const[jwtStatus,setJwtStatus]=React.useState();
  const[showToast,setShowToast]=React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const history = useHistory()

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    //check authUs  er or not
    var user_id=localStorage.getItem('user_ID');
    if(user_id===null){
        history.push('/');
    }
    else{
      ValidateJWTComponent(setJwtStatus,setShowToast);
    }
  },[]);

  return (
    <ThemeProvider theme={Theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar color="primary">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            {/* {businessName==='PostExpressMyanmar'?
            <Avatar alt="PostExpressMyanmar Official Logo" src={logo} />:
            businessName==='CKDelivery'?
            <Avatar alt="CK Official Logo" src={cklogo} />:
            businessName==='NPTDelivery'?
            <Avatar alt="NPT Delivery Official Logo" src={nptlogo} />:
            businessName==='ShweDelivery'?
            <Avatar alt="Shwe Delivery Official Logo" src={shwelogo} />:
            <></>
            } */}
            <Avatar alt="Official Logo" src={"http://delivery.nksoftwarehouse.com/assets/"+businessName+".png"} />
            {!open || window.innerWidth > 720 ? (
              <Typography
                variant="h6"
                style={{ marginLeft: "2%" }}
                Wrap
                component="div"
              >
                {headerText}
              </Typography>
            ) : (
              <></>
            )}
            {/* <Tooltip title="Account">
              <IconButton
                color="inherit"
                onClick={() =>setShowEditForm(true)}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  position: "fixed",
                  right: 10,
                }}
              >
                <Person/>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Logout">
              <IconButton
                color="inherit"
                onClick={() => [localStorage.clear(),window.location.replace("/")]}
                style={{
                  fontWeight: "bold",
                  display: "flex",
                  position: "fixed",
                  right: 25,
                }}
              >
                <Logout />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <SideMenuComponent currentMenu={headerText} />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: headerText !== "Dashboard" ? 3 : 0 }}>
          <DrawerHeader />
          {headerText === "Dashboard" ? (
            <DashboardComponent />
          ) : headerText === "Account Management" ? (
            <AccountManagePage />
          ) : headerText === "Data Management" ? (
            <DataManagePage />
          ) : headerText === "Order Management" ? (
            <OrderManagePage />
          ) : (
            headerText==='Deli Order Management'?(
              <DeliOrderManagePage/>
            ):
            headerText==='OS Order Management'?(
              <OSOrderManagePage/>
            ):
            headerText==='CA-Order Management'?(
              <CollectAssignOrderManagePage/>
            ):
            headerText==='OS CA-Order Management'?(
              <OSCollectAssignOrderManagePage/>
            ):
            headerText==="Way Management"?(
              <WayManagePage/>
            ):
            headerText==="Accounting Management"?(
              <AccountingManagePage/>
            ):
            headerText==="InStock Management"?(
              <InstockManagePage/>
            ):
            <></>
          )}
        </Box>
      </Box>
      {showEditForm?
      <EditFormComponent headerText="Edit Account ✏️" formType="Account" buttonText="Update" currentData={""} setShowEditForm={setShowEditForm}/>
    :<></>}
    {showToast?<ToastContainerComponent/>:<></>}
    </ThemeProvider>
  );
}
