import React, { useState, useEffect } from "react";
import theme from "../../../../theme/Theme";
import {
  Button,
  Stack,
  Grid,
  Autocomplete,
  ThemeProvider,
  CssBaseline,
  TextField,
  Tooltip,
  IconButton,
  Box,
  Typography
} from "@mui/material";
import Get_All_Deli_Account from "../../../../api/InStock/GetAllDeliAccountController";
import Get_InStock_Data from "../../../../api/InStock/GetInStockData";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  Edit
} from "@mui/icons-material";
import DataTableComponent from "../../../../components/DataTable";
import moment from "moment";
import EditFormComponent from "../../../../components/EditForm";
import InStockStyled from "./InStockManagementStyled";

export default function InStockManage() {
  const [deliMen, setDeliMen] = useState(["All"]);
  const business_Name = localStorage.getItem("business_Name");
  const [showData, setShowData] = useState(false);
  const [selectedDeli, setSelectedDeli] = useState("All");
  const [selectedDeliStatus, setSelectedDeliStatus] = useState("Instock");
  const[ways,setWays]=React.useState(0);
  const [tableData, setTableData] = useState([]);
  const [showReturnItemHalfPaidAmount, setShowReturnItemHalfPaidAmount] =React.useState(false);
  const [showEditForm, setShowEditForm] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [totalItemAmount, setTotalItemAmount] = useState(0);
  // const [deliSelected, setDeliSelected] = useState(false);
  // const [wayStatusSelected, setWayStatusSelected] = useState(false);

  // fetch delimen
  useEffect(() => {
    (async function () {
      Get_All_Deli_Account(setDeliMen, business_Name);
      Get_InStock_Data(
        setTableData,
        business_Name,
        selectedDeli,
        selectedDeliStatus,
        setShowData,
        setTotalItemAmount,
        setWays
      );
    })();
  }, []);

  // show btn click handler
  // const handleShowBtn = async () => {
  //   if (showData === false) {
  //     setShowData(true);
  //     return;
  //   }
  //   setShowData(false);
  // };

  // deli select handler
  const handleDeliSelect = (e) => {
    setShowData(false);
    setSelectedDeli(e.target.value);
  };

  // deli status select handler
  const handleDeliStatusSelect = (e) => {
    setSelectedDeliStatus(e.target.value);
  };

  // custom css
  const btnCss = {
    margin: "1.5rem 0",
    fontWeight: "bold",
  };

  // handle filter
  const handleApplyFilter = () => {
    setShowData(false);
    if (!selectedDeli) {
      alert("Select Deli Man first!");
      return;
    }
    if (!selectedDeliStatus) {
      alert("Select Deli Status first!");
      return;
    }
    const selectedDeliMen = selectedDeli === "All" ? "%" : selectedDeli;
    Get_InStock_Data(
      setTableData,
      business_Name,
      selectedDeli,
      "Instock",
      setShowData,
      setTotalItemAmount,
      setWays
    );
  };

  // updateway function
  const UpdateWay = (dataIndex) => {
    setCurrentData({
      CAO_ID: tableData[dataIndex]["CAO_ID"],
      Deli_Status: tableData[dataIndex]["Deli_Status"],
      Business_Name: business_Name,
      Deli_Men: tableData[dataIndex]["Deli_Men"],
    });
    setShowEditForm(true);
  };

  // data table
  // columns
  const columns = [
    {
      name: "CAO_ID",
      options: {
        display: false,
      },
    },
    "SrNo",
    {
      name: "Deli_Men",
      options: {
        display: selectedDeli === "All" ? true : false,
      },
    },
    {
      name: "Client_Name",
      label: "Client"
    },
    {
      name: "Receiver_Name",
      label: "Receiver"
    },
    "C_Name",
    "T_Name",
    {name:"Deli_Type",options:{display:false}},
    {
      name: "Item_Price",
      options: {
        display: false,
        customBodyRender: function (value, tableMeta, updateValue) {
          return value;
        },
      },
    },
    { name: "G_Name", label: "Gate",options:{display:false} },
    { name: "G_Price", label: "Gate_Price",options:{display:false} },
    {
      name: "Expense_Fees",
      options: {
        display: false,
      },
    },
    {
      name: "Tan_Sar_Price",
      options: {
        display:false,
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          if (value === null) {
            return 0;
          } else {
            return value;
          }
          return value;
        },
      },
    },
    {
      name: "Return_Item_Amount",
      label: "Return_Amount",
      options: {
        display: showReturnItemHalfPaidAmount,
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          if (value === null || value === "") {
            return 0;
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "Half_Paid_Amount",
      options: {
        display: showReturnItemHalfPaidAmount,
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          if (value === null || value === "") {
            return 0;
          } else {
            return value;
          }
        },
      },
    },
    {
      name: "Total_Amount",
      label: "Total",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          // return value.toLocaleString();
          return value;
        },
      },
    },
    {
      name: "Deli_Men_Remark",
      label: "Remark"
    },
    { 
      name: "Instock_Date",
      options: {
        sortDirection: "desc",
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value==="1900-01-01T00:00:00" || value===null){
            return "";
          }
          else{
            return moment(value).format("DD-MM-YYYY");
          }
        },
      },
    },
    {
      name: "Rejected_Date",
      options: {
        display:false,
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value==="1900-01-01T00:00:00" || value===null){
            return "";
          }
          else{
            return moment(value).format("DD-MM-YYYY");
          }
        },
      },
    },
    {
      name: "\n\n\n\n\n\nACTION",
      options: {
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRenderLite: (dataIndex, value, tableMeta, updateValue) => {
          return (
            <Stack direction="row" spacing={0}>
              {selectedDeliStatus==="Instock"?
              <Tooltip title="Edit">
                <IconButton
                  tooltip="Edit"
                  color="primary"
                  aria-label="edit"
                  onClick={() => UpdateWay(dataIndex)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>:<></>}
            </Stack>
          );
        },
      },
    },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Autocomplete
              disablePortal
              fullWidth
              id="deli-man"
              value={selectedDeli}
              // options={
              //   deliMen !== []
              //     ? ["ALL", ...deliMen.map((man) => man.User_Name)]
              //     : []
              // }
              options={deliMen}
              onSelect={(e) => handleDeliSelect(e)}
              renderInput={(params) => (
                <TextField {...params} label="Deli Men" />
              )}
            />
          </Grid>

          {/* {selectedDeli ? (
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Autocomplete
                disablePortal
                fullWidth
                id="deli-status"
                options={["Instock", "Rejected"]}
                onSelect={(e) => handleDeliStatusSelect(e)}
                value={selectedDeliStatus}
                renderInput={(params) => (
                  <TextField {...params} label="Deli Status" />
                )}
              />
            </Grid>
          ) : (
            <></>
          )} */}

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <Button
              variant="contained"
              color="primary"
              sx={{mt:1}}
              onClick={handleApplyFilter}
            >
              apply filter
            </Button>
          </Grid>
        </Grid>
      {showData === false ? (
        <Button
          sx={btnCss}
          endIcon={<KeyboardArrowRight />}
          onClick={handleApplyFilter}
        >
          show data
        </Button>
      ) : (
        <>
          <Button
            sx={btnCss}
            endIcon={<KeyboardArrowDown />}
            onClick={() => setShowData(false)}
          >
            hide data
          </Button>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box sx={InStockStyled.boxStyle}>
                <Stack direction="column">
                  <Typography variant="h5">{totalItemAmount.toLocaleString("en-US")}</Typography>
                  <Typography
                    variant="h8"
                    sx={InStockStyled.subHeading}
                  >
                    Total Item Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <Box sx={InStockStyled.boxStyle}>
                <Stack direction="column">
                  <Typography variant="h5">{ways}</Typography>
                  <Typography
                    variant="h8"
                    sx={InStockStyled.subHeading}
                  >
                    Ways
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
          <br/>
          <DataTableComponent
            title="InStock List"
            data={tableData}
            columns={columns}
          />
          {showEditForm ? (
            <EditFormComponent
              headerText="Edit Deli Ways ✏️"
              formType="In Stock"
              buttonText="Update"
              currentData={currentData}
              setShowEditForm={setShowEditForm}
            />
          ) : (
            <></>
          )}
        </>
      )}
    </ThemeProvider>
  );
}
