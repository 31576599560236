import axios from "axios";
import API_EndPoint from "../../URL";

const GetDeliByGate = async (setGateDeli) => {
    const business_name = localStorage.getItem("business_Name");
    await axios.get(API_EndPoint.URL+'data/gate?Business_Name='+business_name,API_EndPoint.Config)
    .then(({data}) => {
        let gateDeli = {};
        data.map(data => gateDeli[data.G_Name] = data.Deli_Men);
        console.log(gateDeli);
        setGateDeli(gateDeli);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
}

export default GetDeliByGate;