import axios from "axios";
import API_EndPoint from "../../URL";

const GetCityTownship = async (category, deliStatus, setTableData, setShowData, setTotalWays, setTotalExpenseFees, setTotalItemPrice, setTotalAmount,from_date,to_date) => {
    const businessName = localStorage.getItem("business_Name");
    //console.log(API_EndPoint.URL + "way/citytownship?"+ "Category=" + category + "&Deli_Status=" + deliStatus + "&Business_Name=" + businessName);
    var params;
    if(from_date===null && to_date===null){
        params="Category=" + category + "&Deli_Status=" + deliStatus +"&From_Date=&To_Date=&Business_Name=" + businessName;
    }
    else{
        params="Category=" + category + "&Deli_Status=" + deliStatus +"&From_Date="+from_date+"&To_Date="+to_date +"&Business_Name=" + businessName;
    }
    await axios.get(API_EndPoint.URL + "way/citytownship?"+ params,API_EndPoint.Config)
    .then(({data}) => {
        //calculate total and showing total item price and total item amount
        let total_expense_fees = 0;
        let total_item_price = 0;
        let total_amount = 0;
        let totalWays = 0;
        data.map(({Expense_Fees,Item_Price,Total_Amount})=>{
            total_expense_fees+=parseInt(Expense_Fees || 0);
            total_item_price+=parseInt(Item_Price || 0);
            total_amount+=parseInt(Total_Amount || 0);
            totalWays++;
        })
        setTotalWays(totalWays);
        setTotalExpenseFees(total_expense_fees);
        setTotalItemPrice(total_item_price);
        setTotalAmount(total_amount);
        setTableData(data);
        setShowData(true);
    })
    .catch((err)=>{
        if(err.response.status===401){
            // Toastify("warning","Session Expired",setShowToast);
            alert("Session Expired");
            localStorage.setItem("Login_Status",false);
            window.location.replace("/");
        }
        else{
            alert(err.response.data);
        }
    })
};

export default GetCityTownship;