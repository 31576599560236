import axios from "axios";
import API_EndPoint from "../URL";

const Get_Debit_Total = async (businessName, debitType, setTotalAmount) => {

    const client = "%";
    const paymentStatus = "pending";

await axios.get(API_EndPoint.URL+'accounting/debit?Client_Name='+client+'&Debit_Type='+debitType+'&Payment_Status='+paymentStatus+'&Business_Name='+businessName,API_EndPoint.Config).then((res)=>{
    if(res.status===200){
        var total_amount = 0;
        if (debitType === "To Get") {
        res.data.map(
            ({Total_Amount, Tan_Sar_Price}) => total_amount += parseInt(Total_Amount || 0) + parseInt(Tan_Sar_Price || 0));
    } else if (debitType === "To Pay") {
         res.data.map(({Total_Amount, Gate_Amount, All_Paid_Amount}) => total_amount += parseInt(Total_Amount) - (parseInt(Gate_Amount || 0) + parseInt(All_Paid_Amount || 0)))
    }
        setTotalAmount(total_amount);
    }
}).catch((err)=>{
    if(err.response.status===401){
        // Toastify("warning","Session Expired",setShowToast);
        alert("Session Expired");
        localStorage.setItem("Login_Status",false);
        window.location.replace("/");
    }
    else{
    alert(err.response.data);
    }
})
}
export default Get_Debit_Total;