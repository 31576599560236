import { indigo, teal, purple, pink, blue, yellow, orange, red, deepOrange, grey, amber } from "@mui/material/colors";

// colors
const palette = {
  primary: {
    main: pink[400],
    superlight: '#e3f2fd'
  },
    pink: {
      main: pink[400],
      light: pink[50],
      bright: pink["A400"],
      semi: pink[300],
      super: pink[900]
    },
    indigo: {
      main: indigo[900],
      semiMain: indigo[600],
      light: indigo[50],
      bright: indigo["A400"]
    },
    teal: {
      main: teal[400],
      light: teal[50],
      semi: teal[500],
      super: teal[900],
      bright: teal["A400"]
    },
    purple: {
      main: purple[400],
      light: purple[50],
      semi: purple[500],
      super: purple[900]
    },
    blue: {
      light: blue[50],
      main: blue[400],
      semi: blue[300],
      super: blue[900]
    },
    yellow: {
      main: yellow[400],
      light: yellow[50],
      semi: yellow[300],
      super: yellow[900]
    },
    amber: {
      main: amber[400],
      light: amber[50],
      semi: amber[300],
      super: amber[900]
    },
    orange: {
      main: orange[400],
      light: orange[50]
    },
    deepOrange: {
      main: deepOrange[400],
      light: deepOrange[50]
    },
    red: {
      main: red[400],
      light: red[50],
      semi: red[300],
      super: red[900]
    },
    grey: {
      main: grey[400],
      light: grey[50]
    }
  }

  export default palette;