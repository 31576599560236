import React,{useEffect} from "react";
import {
  Button,
  CssBaseline,
  ThemeProvider,
  IconButton,
  Tooltip,
  TextField,
  Stack,
  Grid,
  Box,
  Typography
} from "@mui/material";
import {
  AddCircle,
  Edit,
  Delete,
  PlaylistAdd,
  Info,
  KeyboardArrowDown,
  KeyboardArrowRight,
  CheckCircle,
  Cancel,
  Article
} from "@mui/icons-material";
import theme from "../../theme/Theme";
import AccountingManagementStyle from "../admin/manage/accounting/AccountingManagementStyle";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import moment from 'moment';
import CreateFormComponent from "../../components/CreateForm";
import DataTableComponent from "../../components/DataTable";
import ShowCheckFormComponent from "../../components/ShowCheckForm";
import Get_OS_Order_Data from '../../api/OS/GetOSOrderController';
import ShowAmountByWay from "../../components/ShowAmountByWay";
import EditFormComponent from "../../components/EditForm";
import Delete_Client_Order from "../../api/Order/ClientOrder/DeleteClientOrderController";
import Get_Item_Amount_By_Deli_Status from "../../api/Order/ClientOrder/GetItemAmountByDeliStatus";
export default function OSOrderManagePage() {
  const [showForm, setShowForm] = React.useState(false);
  const [showEditForm, setShowEditForm] = React.useState(false);
  const [showCheckForm, setShowCheckForm] = React.useState(false);
  const [showAmountByWay, setShowAmountByWay] = React.useState(false);
  const [orderTotalAmount, setOrderTotalAmount] = React.useState(0);
  const [collectTotalAmount, setCollectTotalAmount] = React.useState(0);
  const [pendingTotalAmount, setPendingTotalAmount] = React.useState(0);
  const [assignTotalAmount, setAssignTotalAmount] = React.useState(0);
  const [onWayTotalAmount, setOnWayTotalAmount] = React.useState(0);
  const [completeTotalAmount, setCompleteTotalAmount] = React.useState(0);
  const [acceptTotalAmount, setAcceptTotalAmount] = React.useState(0);
  const [instockTotalAmount, setInstockTotalAmount] = React.useState(0);
  const [rejectTotalAmount, setRejectTotalAmount] = React.useState(0);
  const [transferTotalAmount, setTransferTotalAmount] = React.useState(0);
  const [showData, setShowData] = React.useState(false);
  const [tableData, setTableData] = React.useState();
  const [currentData, setCurrentData] = React.useState({});
  const [fromDate, setFromDate] = React.useState(moment(new Date()).format('MM-DD-YYYY'));
  const [toDate, setToDate] = React.useState(moment(new Date()).format('MM-DD-YYYY'));
  const[diffAmount,setDiffAmount]=React.useState(0);
  const[checkOrderTotalAmount,setCheckOrderTotalAmout]=React.useState(0);
  //overall
  const[totalCollectQty,setTotalCollectQty]=React.useState(0);
  const[totalClientAmount,setTotalClientAmount]=React.useState(0);
  const[totalCollectAmount,setTotalCollectAmount]=React.useState(0);
  const[totalDiffAmount,setTotalDiffAmount]=React.useState(0);
  //orderCreateDate
  const[orderCreateDate,setOrderCreateDate]=React.useState();
  const columns = [
    {
      name: "CO_ID",
      options: {
        display: false,
      },
    },
    "SrNo",
    {name:"Client_Name",options:{display:false}},
    "Item_Quantity",
    {name:"Pickup_Date",options:{display:false}},
    {
      name: "Total_Amount",
      label:"Order_Amount",
      options: {
        display:true,
        customBodyRender: function (value, tableMeta, updateValue) {
          return value;
        },
      },
    },
    {
      name: "Collect_Amount",
      options: {
        display: false,
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value==null){
            return 0;
          }
          else{
            return parseInt(value);
          }
          
        },
      },
    },
    {
      name: "Gate_Amount",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value==null){
            return 0;
          }
          else{
            return parseInt(value);
          }
          
        },
      },
    },
    {
      name: "All_Paid_Amount",
      options: {
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value==null){
            return 0;
          }
          else{
            return parseInt(value);
          }
        },
      },
    },
    {
      name: "Order_Check_Status",
      label: "Check_Status",
      options: {
        customBodyRender: (value) => {
          if (value === "Check") {
            return <CheckCircle color="success" />
          } else {
            return <Cancel color="warning" />
          }
      },
    }
    },
    {name:"Client_Pay_Type",label:"Pay_Type"},
    // {name:"Payment_Status",options:{display:false}},
    {name:"Remark",options:{display:false}},
    {
      name:"Create_Date",
      options: {
        sortDirection:"desc",
        customBodyRender: function (value, tableMeta, updateValue) {
          if(value===null || value===""){
            return "";
          }
          else{
            var create_date=moment(value).format("DD-MM-YYYY");
            return create_date;
          }
        }
      } 
      
    },
    {
      name: "\n\n\n\n\n\nACTION",
      options: {        
        setCellProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: "0",
            background: "white",
            zIndex: 100,
          },
        }),
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: "nowrap",
            position: "sticky",
            right: 0,
            background: "white",
            zIndex: 101,
          },
        }),
        customBodyRenderLite: (dataIndex, value, tableMeta, updateValue) => {
          return (
            <Stack direction="row">
              {/* <Tooltip title="Edit">
                <IconButton
                  tooltip="Edit"
                  color="primary"
                  aria-label="edit"
                  onClick={() => UpdateAccount(dataIndex)}
                >
                  <Edit />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  aria-label="delete"
                  onClick={() => DeleteAccount(dataIndex)}
                >
                  <Delete />
                </IconButton>
              </Tooltip> */}
              <Tooltip title="Collect & Assign">
                <IconButton
                  color="info"
                  aria-label="collect_assign"
                  onClick={() => CollAssignOrder(dataIndex)}
                >
                  <PlaylistAdd />
                </IconButton>
              </Tooltip>
              <Tooltip title="Check">
                <IconButton
                  color="success"
                  aria-label="info"
                  onClick={() => CheckAmount(dataIndex)}
                >
                  <Info />
                </IconButton>
              </Tooltip>
              <Tooltip title="Way Amount">
                <IconButton
                  color="info"
                  aria-label="info"
                  onClick={() => GetAmountByDeliStatus(dataIndex)}
                >
                  <Article />
                </IconButton>
              </Tooltip>
            </Stack>
          );
        },
      },
    },
  ];
  const handleFromDateChange = (newValue) => {
    setFromDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const handleToDateChange = (newValue) => {
    setToDate(moment(newValue).format("YYYY-MM-DD"));
  };
  const handleApplyDateFilter = () => { 
    setShowData(false);
    Get_OS_Order_Data(fromDate,toDate,setTableData,setShowData,setTotalCollectQty,setTotalClientAmount,setTotalCollectAmount,setTotalDiffAmount);
  };
  const UpdateAccount = (dataIndex) => {
    setCurrentData({
      CO_ID: tableData[dataIndex]["CO_ID"],
      Client_Name: tableData[dataIndex]["Client_Name"],
      No_Ways: tableData[dataIndex]["Item_Quantity"],
      Pickup_Date: tableData[dataIndex]["Pickup_Date"],
      Total_Amount: tableData[dataIndex]["Total_Amount"],
      Gate_Amount:tableData[dataIndex]["Gate_Amount"],
      All_Paid_Amount:tableData[dataIndex]["All_Paid_Amount"],
      Collect_Amount:tableData[dataIndex]["Collect_Amount"],
      Client_Pay_Type: tableData[dataIndex]["Client_Pay_Type"],
      Payment_Status: tableData[dataIndex]["Payment_Status"],
      Remark: tableData[dataIndex]["Remark"],
      Check_Status: tableData[dataIndex]["Order_Check_Status"]
    });
    setShowEditForm(true);
  };
  const DeleteAccount = (dataIndex) => {
    Delete_Client_Order(tableData[dataIndex]["CO_ID"]);
  };
  const CheckAmount = (dataIndex) => {
    // var co_id = tableData[dataIndex]["CO_ID"];
    // setOrderTotalAmount(tableData[dataIndex]["Total_Amount"]);
    // Check_Order_And_Collect_Amount(
    //   co_id,
    //   setCollectTotalAmount,
    //   setShowCheckForm
    // );
    var order_total_amount= tableData[dataIndex]["Total_Amount"];
    var collect_total_amount= tableData[dataIndex]["Collect_Amount"];
    setOrderTotalAmount(order_total_amount);
    var gate_amount= tableData[dataIndex]["Gate_Amount"];
    var all_paid_amount= tableData[dataIndex]["All_Paid_Amount"];
    if(gate_amount==null){
      gate_amount=0;
    }
    if(all_paid_amount==null){
      all_paid_amount=0;
    }
    var collect_amount=(parseInt(collect_total_amount)-(parseInt(gate_amount)+parseInt(all_paid_amount)))
    setCollectTotalAmount(collect_amount);
    //var diff_amount=(parseInt(collect_total_amount)-(parseInt(gate_amount)+parseInt(all_paid_amount)))-parseInt(order_total_amount)
    var diff_amount=parseInt(collect_amount)-parseInt(order_total_amount);
    setDiffAmount(diff_amount);
    var check_order_total_amount=(parseInt(order_total_amount)-(parseInt(gate_amount)+parseInt(all_paid_amount)));
    setCheckOrderTotalAmout(check_order_total_amount);
      setShowCheckForm(true);
  };
  const GetAmountByDeliStatus=(dataIndex)=>{
    var co_id=tableData[dataIndex]["CO_ID"];
    var orderCreateDate=tableData[dataIndex]["Create_Date"];
    setOrderCreateDate(orderCreateDate);
    Get_Item_Amount_By_Deli_Status(co_id,setCollectTotalAmount,setPendingTotalAmount,setAssignTotalAmount,setOnWayTotalAmount,setCompleteTotalAmount,setAcceptTotalAmount,setInstockTotalAmount,setRejectTotalAmount,setTransferTotalAmount);
    setShowAmountByWay(true);
  }
  const CollAssignOrder = (dataIndex) => {
    var co_id = tableData[dataIndex]["CO_ID"];
    var client_name=tableData[dataIndex]["Client_Name"];
    var order_total_amount=tableData[dataIndex]["Total_Amount"];
    var collect_total_amount=tableData[dataIndex]["Collect_Amount"];
    var gate_amount=tableData[dataIndex]["Gate_Amount"];
    var all_paid_amount=tableData[dataIndex]["All_Paid_Amount"];
    var collect_amount=(parseInt(collect_total_amount)-(parseInt(gate_amount)+parseInt(all_paid_amount)));
    //var diff_amount=(parseInt(collect_amount)-parseInt(order_total_amount));
    order_total_amount=parseInt(order_total_amount || 0)-(parseInt(gate_amount || 0)+parseInt(all_paid_amount || 0));
    window.location.href = "/os/manage/order/collectassign?CO_ID=" + co_id+"&Client_Name="+client_name+"&Total_Amount="+order_total_amount+"&Collect_Amount="+collect_amount+"&Gate_Amount="+gate_amount+"&All_Paid_Amount="+all_paid_amount;
  };
  const handleTableData = () => {
    if (showData === false) {
      handleApplyDateFilter();
      //Get_Order_Data(null,null,setTableData,setShowData);
    } else {
      setShowData(false);
    }
  };
  useEffect(() => {
    setShowData(true);
    handleTableData();
  },[]);
  return (
    <>
      {/* <SideDrawerComponent headerText="Account Management"/> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {/* <Button
          variant="contained"
          startIcon={<AddCircle />}
          onClick={() => setShowForm(true)}
        >
          Add Order
        </Button>
        <br />
        <br />
        <br /> */}
        {showData ? (
          [
            <>
            <Button
              sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
              endIcon={<KeyboardArrowDown />}
              onClick={() => handleTableData()}
            >
              Hide Data
            </Button>
            <br />
            <br />
            <br />
            <Stack direction={{ xs: "column", sm: "row"}} spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="From Date"
                  inputFormat="dd-MM-yyyy"
                  value={fromDate}
                  onChange={handleFromDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
                <MobileDatePicker
                  label="To Date"
                  inputFormat="dd-MM-yyyy"
                  value={toDate}
                  onChange={handleToDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                onClick={() => handleApplyDateFilter()}
              >
                Apply Filter
              </Button>
              </Stack><br/>
              <Stack direction="row">
                <Grid container spacing={1}>
                <Grid item xs={3} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalCollectQty}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Collect Qty
                  </Typography>
                </Stack>
              </Box>
            </Grid>    
            <Grid item xs={3} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalClientAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Client Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>   
            <Grid item xs={3} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalCollectAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Collect Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
            <Grid item xs={3} md={3}>
              <Box sx={AccountingManagementStyle.boxStyle}>
                <Stack direction="column">
                  <Typography variant="p">
                    {totalDiffAmount}
                  </Typography>
                  <Typography
                    variant="h8"
                    sx={AccountingManagementStyle.subHeading}
                  >
                    Diff Amount
                  </Typography>
                </Stack>
              </Box>
            </Grid>  
                </Grid>
              </Stack><br/>
              <DataTableComponent
                title="Order List"
                data={tableData}
                columns={columns}
              />
          </>]
        ) : (
          <Button
            sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
            endIcon={<KeyboardArrowRight />}
            onClick={() => handleTableData()}
          >
            Show Data
          </Button>
        )}
        {showForm ? (
          <CreateFormComponent
            headerText="Order Form 📃"
            formType="Order"
            actionType="createOrder"
            buttonText="Create"
            setShowForm={setShowForm}
          />
        ) : (
          <></>
        )}
        {showCheckForm ? (
          <ShowCheckFormComponent
            formType="Order"
            diffAmount={diffAmount}
            orderTotalAmount={checkOrderTotalAmount}
            collectTotalAmount={collectTotalAmount}
            setShowCheckForm={setShowCheckForm}
          />
        ) : (
          <></>
        )}
        {showAmountByWay ? (
          <ShowAmountByWay
            formType="ShowAmountByWay"
            collectTotalAmount={collectTotalAmount}
            pendingTotalAmount={pendingTotalAmount}
            assignTotalAmount={assignTotalAmount}
            onWayTotalAmount={onWayTotalAmount}
            completeTotalAmount={completeTotalAmount}
            acceptTotalAmount={acceptTotalAmount}
            instockTotalAmount={instockTotalAmount}
            rejectTotalAmount={rejectTotalAmount}
            transferTotalAmount={transferTotalAmount}
            setShowAmountByWay={setShowAmountByWay}
            orderCreateDate={orderCreateDate}
          />
        ) : (
          <></>
        )}
        {showEditForm ? (
          <EditFormComponent
            headerText="Edit Client Order ✏️"
            formType="Client Order"
            buttonText="Update"
            currentData={currentData}
            setShowEditForm={setShowEditForm}
          />
        ) : (
          <></>
        )}
      </ThemeProvider>
    </>
  );
}
