import axios from "axios";
import API_EndPoint from "../URL";
const Get_Return_Amount=async(setReturnAmount)=>{
    const api_endpoint=API_EndPoint.URL;
    const business_Name=localStorage.getItem('business_Name');
    var return_amount=0;
    await axios
    .get(
      api_endpoint +
        "accounting/debit?Client_Name=%&Debit_Type=Return Item&Payment_Status=Pending&Business_Name="+business_Name,API_EndPoint.Config
    )
    .then((res)=>{
        if(res.status===200){
            res.data.map(({Return_Item_Amount})=>{
                return_amount+=parseInt(Return_Item_Amount || 0);
            })
        }
        setReturnAmount(return_amount);
    })
    .catch((err) => {
        if(err.response.status===401){
          // Toastify("warning","Session Expired",setShowToast);
          alert("Session Expired");
          localStorage.setItem("Login_Status",false);
          window.location.replace("/");
      }
      else{
        alert(err.response.data);
      }
      });
}
export default Get_Return_Amount;
